import React, { useEffect } from 'react';
import styled from 'styled-components';
import { AppBar, Toolbar, Button, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useQuiz } from '../../context/QuizContext';
import { ScreenTypes } from '../../types';

import QuestionScreen from '../QuestionScreen';
import QuizDetailsScreen from '../QuizDetailsScreen';
import QuizTopicsScreen from '../QuizTopicsScreen/gnr'; ///AQUI PARA REPLICAR
import ResultScreen from '../ResultScreen';
import SplashScreen from '../SplashScreen';
import LogoutButton from '../LogoutButton';
import ToggleTheme from '../ui/ToggleTheme';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
`;

const ContentContainer = styled.div`
  margin-top: 50px; /* Espaçamento abaixo da barra de navegação */
  width: 100%;
`;

const MenuTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #00509E;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin: 10px auto;
  width: 90%;
  background-color: #e6f7ff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-sizing: border-box;

  &:hover {
    background-color: #d1efff;
    transform: scale(1.02);
  }

  &::after {
    content: '+';
    font-size: 24px;
    font-weight: bold;
    color: #00509E;
    transition: transform 0.3s ease;
  }

  &.expanded::after {
    content: '-';
    transform: rotate(180deg);
  }

  @media (max-width: 768px) {
    font-size: 20px;
    padding: 8px 16px;
  }
`;

interface MainProps {
  currentTheme: string;
  toggleTheme: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Main: React.FC<MainProps> = ({ currentTheme, toggleTheme }) => {
  const { currentScreen, setCurrentScreen } = useQuiz();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    setTimeout(() => {
      setCurrentScreen(ScreenTypes.QuizTopicsScreen);
    }, 1000);
  }, [setCurrentScreen]);

  const handlePurchaseNavigation = () => {
    navigate('/purchase');
  };

  const screenComponents = {
    [ScreenTypes.SplashScreen]: <SplashScreen />,
    [ScreenTypes.QuizTopicsScreen]: <QuizTopicsScreen />,
    [ScreenTypes.QuizDetailsScreen]: <QuizDetailsScreen />,
    [ScreenTypes.QuestionScreen]: <QuestionScreen />,
    [ScreenTypes.ResultScreen]: <ResultScreen />,
  };

  const ComponentToRender = screenComponents[currentScreen] || <SplashScreen />;

  return (
    <MainContainer>
      <ContentContainer>
        {ComponentToRender}
      </ContentContainer>
    </MainContainer>
  );
}

export default Main;
