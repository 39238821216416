import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Typography,
  Divider,
  Collapse,
  Menu,
  MenuItem
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StarIcon from '@mui/icons-material/Star';
import SupportIcon from '@mui/icons-material/Support';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SugestaoIcon from '@mui/icons-material/HistoryEdu';
import SecurityIcon from '@mui/icons-material/Security';

import LogoutButton from './LogoutButton';
import { auth, db } from '../firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

interface NavBarProps {
  toggleTheme: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currentTheme: string;
  handlePurchaseNavigation?: () => void;
}

const NavBar: React.FC<NavBarProps> = ({ toggleTheme, currentTheme, handlePurchaseNavigation }) => {
  const [hasPurchased, setHasPurchased] = useState<boolean | null>(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState<{ [key: string]: boolean }>({});
  const [menuAnchor, setMenuAnchor] = useState<{ [key: string]: HTMLElement | null }>({});
  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleSubMenu = (key: string, event?: React.MouseEvent<HTMLElement>) => {
    if (event) {
      setMenuAnchor((prev) => ({ ...prev, [key]: event.currentTarget }));
    }
    setOpenSubMenu((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const closeSubMenu = (key: string) => {
    setOpenSubMenu((prev) => ({ ...prev, [key]: false }));
    setMenuAnchor((prev) => ({ ...prev, [key]: null }));
  };

  const fetchPurchaseStatus = async () => {
    let purchased = false;
    try {
      const userId = auth.currentUser?.uid;
      if (userId) {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          purchased = userDoc.data().hasPurchased;
        }
      }
    } catch (error) {
      console.error('Error fetching purchase status: ', error);
    }
    setHasPurchased(purchased);
  };

  useEffect(() => {
    fetchPurchaseStatus();
  }, []);

  useEffect(() => {
    setMobileOpen(false);
  }, [location.pathname]);

  const openSupport = () => {
    const whatsappUrl = `https://wa.me/351913450846`;
    window.open(whatsappUrl, '_blank');
  };

  const desktopMenu = (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Button
        color="inherit"
        onClick={() => navigate('/')}
        sx={{
          borderRadius: '20px',
          color: location.pathname === '/' ? '#FFA726' : '#00509E',
          fontWeight: location.pathname === '/' ? 'bold' : 'normal'
        }}
      >
        Home
      </Button>
      <Button
        color="inherit"
        onClick={(event) => toggleSubMenu('provas', event)}
        sx={{
          borderRadius: '20px',
          color: location.pathname.includes('/provas') ? '#FFA726' : '#00509E',
          fontWeight: location.pathname.includes('/provas') ? 'bold' : 'normal'
        }}
        endIcon={openSubMenu['provas'] ? <ExpandLess /> : <ExpandMore />}
      >
        Provas
      </Button>
      <Menu
        anchorEl={menuAnchor['provas']}
        open={!!openSubMenu['provas']}
        onClose={() => closeSubMenu('provas')}
        PaperProps={{ style: { minWidth: '200px' } }}
      >
        <MenuItem
          onClick={() => {
            navigate('/provas/psp');
            closeSubMenu('provas'); // Fecha o submenu após o clique
          }}
        >
          PSP
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            navigate('/provas/gnr');
            closeSubMenu('provas'); // Fecha o submenu após o clique
          }}
        >
          GNR
        </MenuItem>
      </Menu>
      <Button
        color="inherit"
        onClick={(event) => toggleSubMenu('profile', event)}
        sx={{
          borderRadius: '20px',
          color: location.pathname.includes('/profile') ? '#FFA726' : '#00509E',
          fontWeight: location.pathname.includes('/profile') ? 'bold' : 'normal'
        }}
        endIcon={openSubMenu['profile'] ? <ExpandLess /> : <ExpandMore />}
      >
        Minha Conta
      </Button>
      <Menu
        anchorEl={menuAnchor['profile']}
        open={!!openSubMenu['profile']}
        onClose={() => closeSubMenu('profile')}
        PaperProps={{ style: { minWidth: '200px' } }}
      > 
        <MenuItem
          onClick={() => {
            navigate('/profile');
            closeSubMenu('profile'); // Fecha o submenu após o clique
          }}
        >
          Perfil
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/profile/estatisticas');
            closeSubMenu('profile'); // Fecha o submenu após o clique
          }}
        >
          Estatísticas
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            auth.signOut();
            navigate('/');
            closeSubMenu('profile'); // Fecha o submenu após o clique
          }}
        >
          Logout
        </MenuItem>
      </Menu>
      {/* <Button
        color="inherit"
        onClick={() => navigate('/provas')}
        sx={{
          borderRadius: '20px',
          color: location.pathname === '/provas' ? '#FFA726' : '#00509E',
          fontWeight: location.pathname === '/provas' ? 'bold' : 'normal'
        }}
      >
        Provas
      </Button> */}
      <Button
        color="inherit"
        onClick={() => navigate('/sugestoes')}
        sx={{
          borderRadius: '20px',
          color: location.pathname === '/sugestoes' ? '#FFA726' : '#00509E',
          fontWeight: location.pathname === '/sugestoes' ? 'bold' : 'normal'
        }}
      >
        Sugestões
      </Button>
      
      
      {hasPurchased ? (
        <Button
          color="inherit"
          onClick={openSupport}
          sx={{ color: '#FFA726', fontWeight: 'bold', borderRadius: '20px' }}
        >
          Suporte
        </Button>
      ) : (
        <Button
          color="inherit"
          onClick={handlePurchaseNavigation || (() => navigate('/purchase'))}
          sx={{ color: '#FFA726', fontWeight: 'bold', borderRadius: '20px' }}
        >
          *Premium*
        </Button>
      )}
      <Button
        color="inherit"
        onClick={(event) => toggleSubMenu('extras', event)}
        sx={{
          borderRadius: '20px',
          color: location.pathname.includes('/extras') ? '#FFA726' : '#00509E',
          fontWeight: location.pathname.includes('/extras') ? 'bold' : 'normal'
        }}
        endIcon={openSubMenu['extras'] ? <ExpandLess /> : <ExpandMore />}
      >
        Extras
      </Button>
      <Menu
        anchorEl={menuAnchor['extras']}
        open={!!openSubMenu['extras']}
        onClose={() => closeSubMenu('extras')}
        PaperProps={{ style: { minWidth: '200px' } }}
      >
        <MenuItem
          onClick={() => {
            navigate('/download');
            closeSubMenu('extras'); // Fecha o submenu após o clique
          }}
        >
          Download
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/changelog');
            closeSubMenu('extras'); // Fecha o submenu após o clique
          }}
        >
          Changelog
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            navigate('/gnr');
            closeSubMenu('extras'); // Fecha o submenu após o clique
          }}
        >
          GNR
        </MenuItem> */}
      </Menu>
    </Box>
  );

  const mobileDrawer = (
    <Box sx={{ width: 240, bgcolor: '#F9F9F9', height: '100%' }}>
      <Box sx={{ padding: 2, textAlign: 'center', bgcolor: '#00509E', color: 'white' }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => {
            setMobileOpen(false);
            navigate('/');
          }}
        >
          EuQueroEntrar
        </Typography>
      </Box>

      <List>
        <ListItem button onClick={() => navigate('/')} selected={location.pathname === '/'}>
          <ListItemIcon>
            <HomeIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Home" sx={{ color: '#00509E' }} />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => toggleSubMenu('mobileProfile')} selected={location.pathname.includes('/profile')}>
          <ListItemIcon>
            <PersonIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Minha Conta" sx={{ color: '#00509E' }} />
          {openSubMenu['mobileProfile'] ? <ExpandLess /> : <ExpandMore />}
          
        </ListItem>

        <Collapse in={openSubMenu['mobileProfile']} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/profile/settings')} selected={location.pathname === '/profile/settings'}>
              <ListItemText primary="Configurações" />
            </ListItem> */}
            <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/profile')} selected={location.pathname === '/profile'}>
              <ListItemText primary="Perfil" />
            </ListItem>
            <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/profile/estatisticas')} selected={location.pathname === '/profile/estatisticas'}>
              <ListItemText primary="Estatísticas" />
            </ListItem>
          </List>
        </Collapse>
        <Divider />

        <ListItem button onClick={() => toggleSubMenu('mobileProvas')} selected={location.pathname.includes('/provas')}>
          <ListItemIcon>
            <PlayArrowIcon  color="primary" />
          </ListItemIcon>
          <ListItemText primary="Provas" sx={{ color: '#00509E' }} />
          {openSubMenu['mobileProvas'] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={openSubMenu['mobileProvas']} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/profile/settings')} selected={location.pathname === '/profile/settings'}>
              <ListItemText primary="Configurações" />
            </ListItem> */}
            <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/provas/psp')} selected={location.pathname === '/provas/psp'}>
              <ListItemText primary="PSP" />
            </ListItem>
            <Divider />
            <ListItem button sx={{ pl: 4 }} onClick={() => navigate('/gnr')} selected={location.pathname === '/gnr'}>
              <ListItemText primary="GNR" />
            </ListItem>
          </List>
        </Collapse>
        
        
        {/* <ListItem button onClick={() => navigate('/provas')} selected={location.pathname === '/provas'}>
          <ListItemIcon>
            <PlayArrowIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Provas" sx={{ color: '#00509E' }} />
        </ListItem> */}
        <Divider />
        <ListItem button onClick={() => navigate('/sugestoes')} selected={location.pathname === '/sugestoes'}>
          <ListItemIcon>
            <SugestaoIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Sugestões" sx={{ color: '#00509E' }} />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => navigate('/download')} selected={location.pathname === '/download'}>
          <ListItemIcon>
            <DownloadIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Download" sx={{ color: '#00509E' }} />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => navigate('/changelog')} selected={location.pathname === '/changelog'}>
          <ListItemIcon>
            <DescriptionIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Changelog" sx={{ color: '#00509E' }} />
        </ListItem>
        <Divider />
        {!hasPurchased && (
          <ListItem button onClick={() => navigate('/purchase')} selected={location.pathname === '/purchase'}>
            <ListItemIcon>
              <StarIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="*Premium*" sx={{ color: '#FFA726', fontWeight: 'bold' }} />
          </ListItem>
        )}
        {hasPurchased && (
          <ListItem button onClick={openSupport}>
            <ListItemIcon>
              <SupportIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Suporte" sx={{ color: '#FFA726', fontWeight: 'bold' }} />
          </ListItem>
        )}
        <ListItem>
          <Divider />
          <LogoutButton />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          background: '#F9F9F9',
          color: '#00509E',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)'
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ flexGrow: 1, fontWeight: 'bold', cursor: 'pointer', color: '#00509E' }}
            onClick={() => navigate('/')}
          >
            EuQueroEntrar
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>{desktopMenu}</Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 240,
            background: '#F9F9F9',
            color: '#00509E'
          }
        }}
      >
        {mobileDrawer}
      </Drawer>
    </>
  );
};

export default NavBar;
