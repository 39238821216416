import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { auth, db } from '../../firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, Checkbox, FormControlLabel } from "@mui/material";


const QuizTitle = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 20px !important;
  color: #00509E !important;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: 100vh;
  background-color: #00509E;

`;

const AudioPlayer = styled.audio`
  width: 100%;
  max-width: 500px;
  outline: none;
`;

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: 20px 0;
`;

const Audios_psp = () => {
  const [hasPurchased, setHasPurchased] = useState<boolean | null>(null);
  const navigate = useNavigate();

  const fetchPurchaseStatus = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (userId) {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          setHasPurchased(userDoc.data().hasPurchased); // Verifica o campo correto 'hasPurchased'
        } else {
          setHasPurchased(false); // Se o documento do usuário não existir
        }
      } else {
        setHasPurchased(false); // Se o usuário não estiver logado
      }
    } catch (error) {
      console.error('Erro ao buscar o status de compra: ', error);
      setHasPurchased(false); // Em caso de erro, considera que não tem compra
    }
  };

  useEffect(() => {
    fetchPurchaseStatus();
  }, []);

  useEffect(() => {
    if (hasPurchased === false) {
      navigate('/purchase'); // Redireciona para a página de compra se não tiver realizado a compra
    }
  }, [hasPurchased, navigate]);

  if (hasPurchased === null) {
    return <div>Carregando...</div>; // Mostra carregando enquanto o status de compra é verificado
  }

  return (
    <MainContainer>
      <PlayerContainer>
        <QuizTitle variant="h3">PSP</QuizTitle>
        <QuizTitle variant="h4">Matéria em áudio</QuizTitle>
        <br />
        <QuizTitle variant="h6">Estatutos</QuizTitle>
        <AudioPlayer controls controlsList="nodownload">
          <source src="https://euqueroentrar.pt/audio/Estatutos_PSP.mp3" type="audio/mpeg" />
          Seu navegador não suporta a reprodução de áudio.
        </AudioPlayer>
        <br />
        <br />
        <br />
        <QuizTitle variant="h6">Lei Orgânica</QuizTitle>
        <AudioPlayer controls controlsList="nodownload">
          <source src="https://euqueroentrar.pt/audio/LeiOrganica_PSP.mp3" type="audio/mpeg" />
          Seu navegador não suporta a reprodução de áudio.
        </AudioPlayer>

        <br />
        <p>* Áudio gerado por AI</p>
      </PlayerContainer>
    </MainContainer>
  );
};

export default Audios_psp;
