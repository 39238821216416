// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'

export const cg: Topic = {
  topic: 'Cultura Geral',
  level: 'Advanced',
  totalQuestions: 132,
  totalScore: 1320,
  totalTime: 360,
  questions: [
    {
      "question": "O menor e o maior país do mundo, em extensão territorial, são, respetivamente:",
      "choices": [
        "Malta e Austrália",
        "Mónaco e Índia",
        "Suíça e Estados Unidos da América",
        "Vaticano e Rússia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Vaticano e Rússia"
      ],
      "score": 10
    },
    {
      "question": "O órgão da União Europeia cuja principal função é gerir o euro, manter a estabilidade dos preços e conduzir a política económica e monetária da UE, é:",
      "choices": [
        "O Banco Central Europeu",
        "O Tribunal Europeu",
        "A Comissão Europeia",
        "O Banco Central Europeu"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Banco Central Europeu"
      ],
      "score": 10
    },
    {
      "question": "O espaço sem fronteiras internas no interior do qual os cidadãos europeus e muitos nacionais de países que não pertencem à União Europeia podem circular livremente, em turismo ou por motivos de trabalho, sem serem sujeitos a controlos fronteiriços, é designado de:",
      "choices": [
        "Espaço Schengen",
        "Espaço de Livre Circulação",
        "Espaço Euro Livre",
        "Espaço Central Europeu"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Espaço Schengen"
      ],
      "score": 10
    },
    {
      "question": "O vencedor da volta à França em bicicleta 2018, também designada de Tour, que decorreu de 7 a 29 de julho de 2018, foi:",
      "choices": [
        "O britânico Geraint Thomas, da equipa SKY",
        "O britânico Chris Froome, da equipa SKY",
        "O britânico Chris Froome, da equipa Movistar",
        "O britânico Geraint Thomas, da equipa Movistar"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O britânico Geraint Thomas, da equipa SKY"
      ],
      "score": 10
    },
    {
      "question": "O país da América do Sul que faz fronteira com o Brasil e tem como capital Assunção é:",
      "choices": [
        "A Bolívia",
        "O Paraguai",
        "O Panamá",
        "O Chile"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Paraguai"
      ],
      "score": 10
    },
    {
      "question": "O Prémio Nobel da Medicina ou Fisiologia de 2018 foi atribuído a dois investigadores pelas descobertas relacionadas com a capacidade do sistema imunitário na luta contra o cancro. Esses investigadores são:",
      "choices": [
        "James P. Allison e Tasuku Honjo",
        "Jeffrey C. Hall e Michael Rosbash",
        "Yoshinori Ohsumi e Chingi Xicori",
        "Don Henley e Don Felder"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "James P. Allison e Tasuku Honjo"
      ],
      "score": 10
    },
    {
      "question": "Se há algumas décadas as cidades cresciam na horizontal, hoje crescem na vertical. Os edifícios estão cada vez mais altos, os países competem entre si e a cada ano surge um edifício mais alto que o anterior. Atualmente o edifício mais alto do mundo é:",
      "choices": [
        "A Torre de Shangai, na China",
        "O Burj Khalifa, no Dubai",
        "A One World Trade Center, nos Estados Unidos",
        "A Petronas Twin Towers, na Malásia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Burj Khalifa, no Dubai"
      ],
      "score": 10
    },
    {
      "question": "A via navegável artificial que permite que navios viajem entre o Mar Mediterrâneo e o Mar Vermelho, reduzindo assim a distância da viagem marítima entre o continente europeu e a Índia em cerca de 7 mil quilómetros, é:",
      "choices": [
        "O Canal da Mancha",
        "O Canal do Suez",
        "O Canal do Panamá",
        "O Canal do Ruez"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Canal do Suez"
      ],
      "score": 10
    },
    {
      "question": "O acrónimo ICNF significa:",
      "choices": [
        "Instituto da Conservação Natural das Florestas",
        "Instituto de Conservação Natural e Florestal",
        "Instituto Conservatório da Natureza Florestal",
        "Instituto da Conservação da Natureza e das Florestas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Instituto da Conservação da Natureza e das Florestas"
      ],
      "score": 10
    },
    {
      "question": "Os órgãos de soberania da República Portuguesa, de acordo com a Constituição da República Portuguesa, são:",
      "choices": [
        "O Presidente da República, as Assembleias Municipais, o Governo e os Tribunais",
        "O Presidente da República, a Assembleia da República, o Governo e as Autarquias",
        "O Presidente da República, as Assembleias Municipais, o Governo e os Partidos Políticos",
        "O Presidente da República, a Assembleia da República, o Governo e os Tribunais"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Presidente da República, a Assembleia da República, o Governo e os Tribunais"
      ],
      "score": 10
    },
    {
      "question": "O atual Comandante-Geral da Guarda Nacional Republicana é:",
      "choices": [
        "O Tenente-General Manuel Mateus Couto",
        "O Major-General Mourato Nunes",
        "O Tenente-General Luís Francisco Botelho Miguel",
        "O Tenente-General Newton Parreira"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Tenente-General Luís Francisco Botelho Miguel"
      ],
      "score": 10
    },
    {
      "question": "O Presidente da República Portuguesa é o responsável pela condução da política geral do país e o órgão superior da administração pública.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "O filme 'Os Vingadores: Guerra do Infinito' é o maior êxito de bilheteira de sempre e já arrecadou mais de 500 milhões de euros.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "O Reino Unido é constituído por três países: Inglaterra, Escócia e Irlanda do Norte.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "A Assembleia da República pode votar moções de censura ao Governo sobre a execução do seu programa ou assunto relevante de interesse nacional, por iniciativa de um quarto dos Deputados em efetividade de funções ou de qualquer grupo parlamentar.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Qual o maior rio europeu?",
      "choices": [
        "Danúbio",
        "Nilo",
        "Volga",
        "Reno"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Volga"
      ],
      "score": 10
    },
    {
      "question": "Onde se situa o Banco Central Europeu?",
      "choices": [
        "Bruxelas",
        "Estrasburgo",
        "Genebra",
        "Frankfurt"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Frankfurt"
      ],
      "score": 10
    },
    {
      "question": "Em que país se situa a sede da ONU?",
      "choices": [
        "Bruxelas",
        "Genebra",
        "Paris",
        "Nova Iorque"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Nova Iorque"
      ],
      "score": 10
    },
    {
      "question": "Em que país se situa a sede da NATO?",
      "choices": [
        "Bruxelas",
        "Genebra",
        "Paris",
        "Nova Iorque"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Bruxelas"
      ],
      "score": 10
    },
    {
      "question": "Quem faz parte do grupo dos cinco?",
      "choices": [
        "Brasil, EUA, China, Japão, África do Sul",
        "Brasil, México, China, Índia, África do Sul",
        "Brasil, EUA, China, Japão, Rússia",
        "Brasil, EUA, China, Índia, Rússia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Brasil, México, China, Índia, África do Sul"
      ],
      "score": 10
    },

    // cg_2

    {
      "question": "Qual a capital do Canadá?",
      "choices": [
        "Toronto",
        "Ottawa",
        "Montreal",
        "Vancouver"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ottawa"
      ],
      "score": 10
    },
    {
      "question": "Gronelândia pertence a que país?",
      "choices": [
        "Dinamarca",
        "Noruega",
        "Canadá",
        "Islândia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Dinamarca"
      ],
      "score": 10
    },
    {
      "question": "As ilhas selvagens pertencem a que país?",
      "choices": [
        "Espanha",
        "Portugal",
        "Marrocos",
        "Senegal"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Portugal"
      ],
      "score": 10
    },
    {
      "question": "Quantos deputados tem o Parlamento Europeu?",
      "choices": [
        "751",
        "732",
        "705",
        "700"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "705"
      ],
      "score": 10
    },
    {
      "question": "Qual a capital dos EUA?",
      "choices": [
        "Nova Iorque",
        "Washington DC",
        "Miami",
        "Las Vegas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Washington DC"
      ],
      "score": 10
    },
    {
      "question": "Qual a capital da Austrália?",
      "choices": [
        "Sidney",
        "Melbourne",
        "Camberra",
        "Brisbane"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Camberra"
      ],
      "score": 10
    },
    {
      "question": "Onde se iniciou o surto de Covid?",
      "choices": [
        "Pequim",
        "Xangai",
        "Wuhan",
        "Tianjin"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Wuhan"
      ],
      "score": 10
    },
    {
      "question": "Em que ano Portugal recebeu o festival da Euro-canção?",
      "choices": [
        "2016",
        "2017",
        "2018",
        "2019"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "2018"
      ],
      "score": 10
    },
    {
      "question": "Quem escreveu o livro 'O Crime do Padre Amaro'?",
      "choices": [
        "José Saramago",
        "Eça de Queirós",
        "Fernando Pessoa",
        "Miguel Torga"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Eça de Queirós"
      ],
      "score": 10
    },
    {
      "question": "Em que ano foram detonadas as bombas de Nagasaki e Hiroshima?",
      "choices": [
        "1943",
        "1944",
        "1945",
        "1946"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "1945"
      ],
      "score": 10
    },
    {
      "question": "Quantas ilhas compõem o arquipélago dos Açores?",
      "choices": [
        "8",
        "9",
        "10",
        "11"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "9"
      ],
      "score": 10
    },
    {
      "question": "Faixa de Gaza faz fronteira com que país?",
      "choices": [
        "Afeganistão",
        "Líbano",
        "Israel",
        "Iraque"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Israel"
      ],
      "score": 10
    },
    {
      "question": "Qual o país mais pequeno do mundo?",
      "choices": [
        "São Marino",
        "Vaticano",
        "Liechtenstein",
        "Mónaco"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Vaticano"
      ],
      "score": 10
    },
    {
      "question": "Quantos distritos compõem Portugal Continental?",
      "choices": [
        "16",
        "17",
        "18",
        "19"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "18"
      ],
      "score": 10
    },
    {
      "question": "Onde decorreu o mundial de 2022 de Futebol?",
      "choices": [
        "EUA",
        "Qatar",
        "Arábia Saudita",
        "Emirados Árabes Unidos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Qatar"
      ],
      "score": 10
    },
    {
      "question": "Qual o estreito que limita o Continente Europeu do Asiático na Turquia?",
      "choices": [
        "Estreito de Dardanelos",
        "Estreito de Bósforo",
        "Estreito de Messina",
        "Estreito de Gibraltar"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Estreito de Bósforo"
      ],
      "score": 10
    },
    {
      "question": "Onde se situa Auschwitz?",
      "choices": [
        "Áustria",
        "Alemanha",
        "Polónia",
        "Eslováquia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Polónia"
      ],
      "score": 10
    },
    {
      "question": "Onde nasceu Adolf Hitler?",
      "choices": [
        "Alemanha",
        "Áustria",
        "Polónia",
        "Eslováquia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Áustria"
      ],
      "score": 10
    },
    {
      "question": "Em que Ilha Açoriana se situa a Base das Lages?",
      "choices": [
        "Ilha Terceira",
        "Ilha do Pico",
        "Ilha do Corvo",
        "Ilha São Miguel"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ilha Terceira"
      ],
      "score": 10
    },
    {
      "question": "Em que ano foi assinado o Tratado de Lisboa?",
      "choices": [
        "2005",
        "2006",
        "2007",
        "2008"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "2007"
      ],
      "score": 10
    },

    // cg_3

    {
      "question": "Que nome tem o tratado que criou oficialmente a União Europeia?",
      "choices": [
        "Tratado de Roma",
        "Tratado de Estrasburgo",
        "Tratado de Maastricht",
        "Tratado de Lisboa"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tratado de Maastricht"
      ],
      "score": 10
    },
    {
      "question": "Por quantas estrelas é composta a bandeira da União Europeia?",
      "choices": [
        "8",
        "9",
        "10",
        "12"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "12"
      ],
      "score": 10
    },
    {
      "question": "Qual destes países não compõe o Reino Unido?",
      "choices": [
        "Inglaterra",
        "Escócia",
        "País de Gales",
        "República da Irlanda"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "República da Irlanda"
      ],
      "score": 10
    },
    {
      "question": "Qual é o maior planeta do sistema solar?",
      "choices": [
        "Mercúrio",
        "Saturno",
        "Júpiter",
        "Úrano"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Júpiter"
      ],
      "score": 10
    },
    {
      "question": "Qual destes países não é fundador da União Europeia?",
      "choices": [
        "Alemanha",
        "Itália",
        "Luxemburgo",
        "Espanha"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Espanha"
      ],
      "score": 10
    },
    {
      "question": "O tema do famoso discurso 'Eu Tenho um Sonho', de Martin Luther King é:",
      "choices": [
        "Igualdade das raças.",
        "Justiça para os menos favorecidos.",
        "Intolerância religiosa.",
        "Prémio Nobel da Paz."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Igualdade das raças."
      ],
      "score": 10
    },
    {
      "question": "O tratado que estabeleceu a criação do Euro como moeda única europeia denomina-se como:",
      "choices": [
        "Tratado de Maastricht",
        "Tratado de Nice.",
        "Tratado de Schengen.",
        "Tratado de Lisboa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tratado de Maastricht"
      ],
      "score": 10
    },
    {
      "question": "A autoria da obra 'Felizmente há Luar!' pertence a:",
      "choices": [
        "Luís Vaz de Camões.",
        "Almeida Garrett.",
        "Fernando Pessoa.",
        "Luís de Sttau Monteiro."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Luís de Sttau Monteiro."
      ],
      "score": 10
    },
    {
      "question": "Os principais cromossomos que determinam o sexo masculino são:",
      "choices": [
        "Os V.",
        "Os X.",
        "Os Y.",
        "Os W."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os Y."
      ],
      "score": 10
    },
    {
      "question": "O nome dado a um campo de castanheiros é:",
      "choices": [
        "Castanhal.",
        "Pinhal",
        "Souto.",
        "Pomar."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Souto."
      ],
      "score": 10
    },
    {
      "question": "As casas decimais do número pi são:",
      "choices": [
        "Centenas",
        "Infinitas",
        "Cem",
        "Milhares"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Infinitas"
      ],
      "score": 10
    },
    {
      "question": "O autor de 'O Príncipe' é:",
      "choices": [
        "Maquiavel",
        "Antoine de Saint-Exupéry",
        "Montesquieu",
        "Rousseau"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Maquiavel"
      ],
      "score": 10
    },
    {
      "question": "A data em que foi assinado o Tratado de Zamora, que estabeleceu a independência de Portugal, é:",
      "choices": [
        "10 de outubro de 1125",
        "5 de outubro de 1143",
        "10 de junho de 1143",
        "24 de maio de 1125"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "5 de outubro de 1143"
      ],
      "score": 10
    },
    {
      "question": "A obra 'A Arte da Guerra' foi escrita por:",
      "choices": [
        "Confúcio",
        "Gandhi",
        "Sun Tzu",
        "Mao Tsé Tung"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sun Tzu"
      ],
      "score": 10
    },
    {
      "question": "A cordilheira de montanhas mais extensa do mundo é:",
      "choices": [
        "Cordilheira dos Himalaias.",
        "Cordilheira dos Montes Urais",
        "Cordilheira dos Andes.",
        "Cordilheira do Cáucaso."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Cordilheira dos Andes."
      ],
      "score": 10
    },
    {
      "question": "Em que região de Portugal Continental se encontra a zona mais ocidental da Europa:",
      "choices": [
        "Algarve",
        "Alentejo",
        "Açores",
        "Estremadura"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Estremadura"
      ],
      "score": 10
    },
    {
      "question": "A capital da Hungria é:",
      "choices": [
        "Bucareste",
        "Praga",
        "Budapeste",
        "Varsóvia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Budapeste"
      ],
      "score": 10
    },
    {
      "question": "Relativo à Segunda Guerra Mundial, o Dia da Vitória na Europa é celebrado a:",
      "choices": [
        "8 de maio",
        "13 de maio",
        "6 de junho",
        "13 de junho"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "8 de maio"
      ],
      "score": 10
    },
    {
      "question": "Relativamente a um vulcão, o canal que liga a câmara magmática ao exterior chama-se:",
      "choices": [
        "Cratera vulcânica",
        "Chaminé vulcânica",
        "Cone vulcânico",
        "Fumarola"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Chaminé vulcânica"
      ],
      "score": 10
    },
    {
      "question": "A Escala Mercalli mede:",
      "choices": [
        "A intensidade dos ventos",
        "A intensidade sísmica de acordo com os danos provocados",
        "A resistência dos minerais",
        "A magnitude de um terremoto"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A intensidade sísmica de acordo com os danos provocados"
      ],
      "score": 10
    },

    // cg_4

    {
      "question": "'A Odisseia', de 2016, é um filme de Jérôme Salle cuja narrativa se centra em:",
      "choices": [
        "Stephen Hawking",
        "Jacques-Yves Cousteau",
        "Neil Armstrong",
        "John Nash"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Jacques-Yves Cousteau"
      ],
      "score": 10
    },
    {
      "question": "Os diamantes são constituídos por:",
      "choices": [
        "Grafite",
        "Berílio",
        "Óxido de alumínio",
        "Carbono"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Carbono"
      ],
      "score": 10
    },
    {
      "question": "Em 2021, o vencedor português do Prémio da União Europeia para a Literatura foi:",
      "choices": [
        "João Pinto Coelho",
        "Ana Pinto Magalhães",
        "Frederico Pedreira",
        "Elísio Amaral"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Frederico Pedreira"
      ],
      "score": 10
    },
    {
      "question": "Que matemático e geógrafo grego calculou o perímetro da Terra no ano 240 a.C.?",
      "choices": [
        "Descartes",
        "Sócrates",
        "Sófocles",
        "Eratóstenes"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Eratóstenes"
      ],
      "score": 10
    },
    {
      "question": "Segundo o tratado assinado em 1971, que região do planeta ficou interdita a armas nucleares?",
      "choices": [
        "Sara",
        "África",
        "Antártida",
        "Bahamas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Antártida"
      ],
      "score": 10
    },
    {
      "question": "Por que nome se tornou mais conhecido o escritor norte-americano Samuel Langhorne Clemens?",
      "choices": [
        "Paul Auster",
        "Mark Twain",
        "Paulo Coelho",
        "Nicolau Gogol"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Mark Twain"
      ],
      "score": 10
    },
    {
      "question": "Que expressão alemã significa 'guerra-relâmpago'?",
      "choices": [
        "Sauerkraut",
        "Glasnost",
        "Reichstag",
        "Blitzkrieg"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Blitzkrieg"
      ],
      "score": 10
    },
    {
      "question": "Como se chamou o Sporting Club de Portugal entre 1904 e 1906?",
      "choices": [
        "Sporting de Portugal",
        "Não teve nome",
        "Leões da Estrela",
        "Campo Grande Football Club"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Campo Grande Football Club"
      ],
      "score": 10
    },
    {
      "question": "Junto a que cidade do Novo México se julga ter caído um OVNI em 1947?",
      "choices": [
        "Tijuana",
        "Las Vegas",
        "Roswell",
        "Chihuahua"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Roswell"
      ],
      "score": 10
    },
    {
      "question": "Em Portugal, a que cidadãos foi retirado o direito de voto em 1913?",
      "choices": [
        "Mulheres",
        "Analfabetos",
        "Emigrantes",
        "Idosos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Analfabetos"
      ],
      "score": 10
    },
    {
      "question": "Qual é o mais famoso detetive criado por Raymond Chandler?",
      "choices": [
        "Hercule Poirot",
        "Sherlock Holmes",
        "Philip Marlowe",
        "Agatha Christie"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Philip Marlowe"
      ],
      "score": 10
    },
    {
      "question": "Em que atividade se destacou Le Corbusier?",
      "choices": [
        "Cinema",
        "Teatro",
        "Literatura",
        "Arquitetura"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Arquitetura"
      ],
      "score": 10
    },
    {
      "question": "Que parque natural na região de Bragança foi criado em 1979?",
      "choices": [
        "Peneda-Gerês",
        "Alto Douro",
        "Montesinho",
        "Arrábida"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Montesinho"
      ],
      "score": 10
    },
    {
      "question": "Como se chama a personagem principal do filme Touro Enraivecido?",
      "choices": [
        "Sugar Ray Robinson",
        "Belarmino",
        "Muhamad Ali",
        "Jake La Motta"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Jake La Motta"
      ],
      "score": 10
    },
    {
      "question": "Quanto tempo demora a Lua a dar a volta à Terra (período orbital)?",
      "choices": [
        "27 dias e 8 horas",
        "25 dias",
        "30 dias",
        "29 dias e 12 horas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "27 dias e 8 horas"
      ],
      "score": 10
    },
    {
      "question": "Quem recebeu um prémio Nobel devido ao desenvolvimento da encefalografia arterial?",
      "choices": [
        "Júlio de Matos",
        "Egas Moniz",
        "Michael Faraday",
        "Sousa Martins"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Egas Moniz"
      ],
      "score": 10
    },
    {
      "question": "Qual o eletrodoméstico que foi criado por Murray Spangler em 1907 e que ainda hoje é vendido praticamente inalterado?",
      "choices": [
        "Esquentador",
        "Torradeira",
        "Micro-ondas",
        "Aspirador"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Aspirador"
      ],
      "score": 10
    },
    {
      "question": "Qual modalidade desportiva foi introduzida em Portugal Continental em 1888 por Guilherme Pinto Basto?",
      "choices": [
        "Futebol",
        "Corfebol",
        "Andebol",
        "Ténis"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Futebol"
      ],
      "score": 10
    },
    {
      "question": "Qual é o objeto de estudo do puericultor?",
      "choices": [
        "Crianças",
        "Coelhos",
        "Abelhas",
        "Galinhas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Crianças"
      ],
      "score": 10
    },
    {
      "question": "Qual país sul-americano é o maior produtor de vinho?",
      "choices": [
        "Brasil",
        "Venezuela",
        "Canadá",
        "Argentina"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Argentina"
      ],
      "score": 10
    },

    // cg_5

    {
      "question": "Quem comandou a segunda invasão napoleónica em Portugal?",
      "choices": [
        "Napoleão",
        "Junot",
        "Wellington",
        "Soult"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Soult"
      ],
      "score": 10
    },
    {
      "question": "Qual é a corrente filosófica defendida por Martin Heidegger?",
      "choices": [
        "Positivismo",
        "Niilismo",
        "Abstracionismo",
        "Existencialismo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Existencialismo"
      ],
      "score": 10
    },
    {
      "question": "Que corpo militar de elite foi criado em França por Luís XIII como guarda pessoal no século XVII existiu até 1815?",
      "choices": [
        "Guarda Suíça",
        "Rangers",
        "Escuteiros",
        "Mosqueteiros"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Mosqueteiros"
      ],
      "score": 10
    },
    {
      "question": "O que mudou na personagem Lucky Luke em 1983?",
      "choices": [
        "Começou a usar óculos",
        "Tornou-se vegetariano",
        "Deixou de fumar",
        "Deixou de usar armas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Deixou de fumar"
      ],
      "score": 10
    },
    {
      "question": "Qual foi a primeira moeda a ser usada em toda a Europa Ocidental?",
      "choices": [
        "ECU",
        "Euro",
        "Sestércio",
        "Dracma"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sestércio"
      ],
      "score": 10
    },
    {
      "question": "Quem escreveu A Arte da Guerra?",
      "choices": [
        "Confúcio",
        "Gandhi",
        "Sun Tzu",
        "Mao Tsé Tung"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sun Tzu"
      ],
      "score": 10
    },
    {
      "question": "Que expressão náutica foi sugerida por Alexander Bell para ser usada quando se atende o telefone?",
      "choices": [
        "Ahoy",
        "Over",
        "Hi",
        "All aboard"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ahoy"
      ],
      "score": 10
    },
    {
      "question": "Que palavra de origem inuíte significa casa?",
      "choices": [
        "Quiosque",
        "Caiaque",
        "Anoraque",
        "Iglu"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Iglu"
      ],
      "score": 10
    },
    {
      "question": "Qual é o símbolo químico da prata?",
      "choices": [
        "PT",
        "PA",
        "AG",
        "PG"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "AG"
      ],
      "score": 10
    },
    {
      "question": "Quantos jogadores formam uma equipa de pólo aquático?",
      "choices": [
        "6",
        "7",
        "8",
        "5"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "7"
      ],
      "score": 10
    },
    {
      "question": "Quantos corações tem um polvo?",
      "choices": [
        "1",
        "2",
        "3",
        "4"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "3"
      ],
      "score": 10
    },
    {
      "question": "Onde está exposto o quadro 'Mona Lisa' de Leonardo DaVinci?",
      "choices": [
        "Museu do Prado",
        "Museu do Louvre",
        "Capela Sistina",
        "Vaticano"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Museu do Louvre"
      ],
      "score": 10
    },
    {
      "question": "Onde podemos visitar o monumento Stonehenge?",
      "choices": [
        "EUA",
        "Reino Unido",
        "Canadá",
        "Brasil"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Reino Unido"
      ],
      "score": 10
    },
    {
      "question": "Em que ano terminou a 2ª Guerra Mundial?",
      "choices": [
        "1945",
        "1946",
        "1954",
        "1942"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "1945"
      ],
      "score": 10
    },
    {
      "question": "De que poeta é a frase: 'Tudo vale a pena se a alma não é pequena'?",
      "choices": [
        "Fernando Pessoa",
        "Bocage",
        "Eça de Queiroz",
        "Luís de Camões"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Fernando Pessoa"
      ],
      "score": 10
    },
    {
      "question": "Quem é o autor de 'Um estranho em Goa'?",
      "choices": [
        "José Eduardo Agualusa",
        "José Eduardo Abreu",
        "José-Augusto França",
        "José Eduardo França"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "José Eduardo Agualusa"
      ],
      "score": 10
    },
    {
      "question": "Mozart era?",
      "choices": [
        "Húngaro",
        "Alemão",
        "Austriaco",
        "Russo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Austriaco"
      ],
      "score": 10
    },
    {
      "question": "Que guerra está retratada em 'Guernica' de Picasso?",
      "choices": [
        "Guerra Civil Espanhola",
        "Primeira Guerra Mundial",
        "Segunda Guerra Mundial",
        "Guerra dos 100 anos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Guerra Civil Espanhola"
      ],
      "score": 10
    },
    {
      "question": "Quem a autora de 'O Princípio da Incerteza'?",
      "choices": [
        "Agustina Bessa Luis",
        "Clara Pinto Correia",
        "Maria Velho da Costa",
        "Josefa Almeida"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Agustina Bessa Luis"
      ],
      "score": 10
    },
    {
      "question": "Craveirinha foi um conceituado Poeta de:",
      "choices": [
        "Moçambique",
        "Angola",
        "Portugal",
        "Guiné"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Moçambique"
      ],
      "score": 10
    },

    // cg_6

    {
      "question": "Fez furor a adaptação ao cinema do herói 'Homem-Aranha'; quem foi o criador da personagem, inicialmente para B.D.:",
      "choices": [
        "Peter Parker",
        "Stan Lee",
        "Tim Burton",
        "Jackson Perez"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Stan Lee"
      ],
      "score": 10
    },
    {
      "question": "'Pasion' é um álbum da autoria de:",
      "choices": [
        "Rodrigo Leão",
        "Madredeus",
        "Ciganos d'Ouro",
        "Xutos e Pontapés"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Rodrigo Leão"
      ],
      "score": 10
    },
    {
      "question": "Qual era a cor que Piet Mondrian nunca usava nos seus quadros?",
      "choices": [
        "Vermelho",
        "Amarelo",
        "Verde",
        "Preto"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verde"
      ],
      "score": 10
    },
    {
      "question": "Qual dos seguintes existencialistas recusou o prémio Nobel da Literatura em 1964?",
      "choices": [
        "Saint-John Perse",
        "Jean-Paul Sartre",
        "Saul Bellow",
        "Albert Einstein"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Jean-Paul Sartre"
      ],
      "score": 10
    },
    {
      "question": "Quantos filmes de Charlie Chaplin ganharam o OSCAR para melhor filme?",
      "choices": [
        "6",
        "2",
        "4",
        "0"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "0"
      ],
      "score": 10
    },
    {
      "question": "Quem foi o primeiro vocalista dos Pink Floyd?",
      "choices": [
        "Roger Waters",
        "Syd Barret",
        "Nick Mason",
        "Robert Peaze"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Syd Barret"
      ],
      "score": 10
    },
    {
      "question": "Qual é a empresa cinematográfica de mais longa duração na história do cinema norte-americano?",
      "choices": [
        "Paramount Studios",
        "Universal Studios",
        "20th Century Fox",
        "Polyscope Company"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Paramount Studios"
      ],
      "score": 10
    },
    {
      "question": "Quantos livros publicou em vida Fernando Pessoa?",
      "choices": [
        "0",
        "2",
        "12",
        "7"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "0"
      ],
      "score": 10
    },
    {
      "question": "'Os Cus de Judas', 'Memória de Elefante' e 'Exortação aos Crocodilos' são romances da autoria de:",
      "choices": [
        "José Saramago",
        "Fernando Pessoa",
        "António Lobo Antunes",
        "David Mourão-Ferreira"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "António Lobo Antunes"
      ],
      "score": 10
    },
    {
      "question": "A trilogia cinematográfica 'O Senhor dos Anéis' baseia-se na igual trilogia literária de que autor?",
      "choices": [
        "J. R. R. Tolkien",
        "Sir John Paul III",
        "Geoffrey Chaucer",
        "J. K. Rowling"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "J. R. R. Tolkien"
      ],
      "score": 10
    },
    {
      "question": "Normalmente, quantos litros de sangue uma pessoa tem?",
      "choices": [
        "Tem entre 2 a 4 litros",
        "Tem entre 4 a 6 litros",
        "Tem 10 litros",
        "Tem 7 litros"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tem entre 4 a 6 litros"
      ],
      "score": 10
    },
    {
      "question": "De quem é a famosa frase 'Penso, logo existo'?",
      "choices": [
        "Platão",
        "Galileu Galilei",
        "Descartes",
        "Sócrates"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Descartes"
      ],
      "score": 10
    },
    {
      "question": "Em que país foi inventado o chuveiro elétrico?",
      "choices": [
        "França",
        "Inglaterra",
        "Brasil",
        "Austrália"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Brasil"
      ],
      "score": 10
    },
    {
      "question": "Quais o menor e o maior país do mundo?",
      "choices": [
        "Vaticano e Rússia",
        "Nauru e China",
        "Mónaco e Canadá",
        "Malta e Estados Unidos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Vaticano e Rússia"
      ],
      "score": 10
    },
    {
      "question": "Qual o livro mais vendido no mundo a seguir à Bíblia?",
      "choices": [
        "O Senhor dos Anéis",
        "Dom Quixote",
        "O Pequeno Príncipe",
        "Ela, a Feiticeira"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Dom Quixote"
      ],
      "score": 10
    },
    {
      "question": "Atualmente, quantos elementos químicos a tabela periódica possui?",
      "choices": [
        "113",
        "109",
        "118",
        "92"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "118"
      ],
      "score": 10
    },
    {
      "question": "Quem pintou 'Guernica'?",
      "choices": [
        "Paul Cézanne",
        "Pablo Picasso",
        "Diego Rivera",
        "Salvador Dalí"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pablo Picasso"
      ],
      "score": 10
    },
    {
      "question": "Quanto tempo a luz do Sol demora para chegar à Terra?",
      "choices": [
        "12 minutos",
        "1 dia",
        "12 horas",
        "8 minutos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "8 minutos"
      ],
      "score": 10
    },
    {
      "question": "Qual a nacionalidade de Che Guevara?",
      "choices": [
        "Cubana",
        "Peruana",
        "Boliviana",
        "Argentina"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Argentina"
      ],
      "score": 10
    },
    {
      "question": "Em que período da pré-história o fogo foi descoberto?",
      "choices": [
        "Neolítico",
        "Paleolítico",
        "Período da Pedra Polida",
        "Idade Média"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Paleolítico"
      ],
      "score": 10
    },

      // cg_7

    {
      "question": "Em qual local da Ásia o português é língua oficial?",
      "choices": [
        "Índia",
        "Filipinas",
        "Moçambique",
        "Macau"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Macau"
      ],
      "score": 10
    },
    {
      "question": "'It is six twenty' ou 'twenty past six'. Que horas são em inglês?",
      "choices": [
        "12:06",
        "6:20",
        "6:02",
        "12:20"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "6:20"
      ],
      "score": 10
    },
    {
      "question": "Quais destas construções famosas ficam nos Estados Unidos?",
      "choices": [
        "Estátua da Liberdade, Golden Gate Bridge e Empire State Building",
        "Estátua da Liberdade, Big Ben e The High Line",
        "Angkor Wat, Taj Mahal e Skywalk no Grand Canyon",
        "Lincoln Memorial, Sidney Opera House e Burj Khalifa"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Estátua da Liberdade, Golden Gate Bridge e Empire State Building"
      ],
      "score": 10
    },
    {
      "question": "Quais destas doenças são sexualmente transmissíveis?",
      "choices": [
        "Aids, tricomoníase e ebola",
        "Gonorreia, clamídia e sífilis",
        "Hepatite B, febre tifoide e hanseníase",
        "Botulismo, cistite e gonorreia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Gonorreia, clamídia e sífilis"
      ],
      "score": 10
    },
    {
      "question": "Qual destes países é transcontinental?",
      "choices": [
        "Rússia",
        "Filipinas",
        "Gronelândia",
        "Tanzânia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Rússia"
      ],
      "score": 10
    },
    {
      "question": "Qual foi o recurso utilizado inicialmente pelo homem para explicar a origem das coisas?",
      "choices": [
        "A Filosofia",
        "A Biologia",
        "A Astronomia",
        "A Mitologia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A Mitologia"
      ],
      "score": 10
    },
    {
      "question": "Quais são os Deus Gregos correspondentes a Júpiter e Plutão?",
      "choices": [
        "Ares e Hermes",
        "Cronos e Apolo",
        "Zeus e Hades",
        "Zeus e Ares"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Zeus e Hades"
      ],
      "score": 10
    },
    {
      "question": "Qual o maior animal terrestre?",
      "choices": [
        "Baleia Azul",
        "Dinossauro",
        "Elefante africano",
        "Tubarão Branco"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Elefante africano"
      ],
      "score": 10
    },
    {
      "question": "Qual líder mundial ficou conhecida como 'Dama de Ferro'?",
      "choices": [
        "Dilma Rousseff",
        "Angela Merkel",
        "Margaret Thatcher",
        "Hillary Clinton"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Margaret Thatcher"
      ],
      "score": 10
    }
  ],
}
