

import { Topic } from '..'

export const psp_leiOrganica_1: Topic = {
  topic: 'Lei Orgânica - PSP (1)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    //2017
    {
      "question": "A PSP é:",
      "choices": [
        "Força de segurança uniformizada e armada",
        "De natureza de serviço público",
        "Dotada de autonomia administrativa",
        "Todas as respostas estão corretas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as respostas estão corretas"
      ],
      "score": 10
    },
    {
      "question": "Ao longo dos anos a lei que define a orgânica e funcionamento da PSP tem sofrido algumas alterações para acompanhar a evolução da sociedade portuguesa. Das afirmações que se seguem indique quanto à natureza a evolução cronológica da mesma. ",
      "choices": [
        "(1) a PSP é uma força policial armada e uniformizada, (2) a PSP constitui um organismo militarizado, (3) a PSP é uma força de segurança, (4) a PSP é uma força de segurança, uniformizada e armada.",
        "(1) a PSP é uma força de segurança, uniformizada e armada, (2) a PSP constitui um organismo militarizado, (3) a PSP é uma força policial armada e uniformizada, (4) a PSP é uma força de segurança.",
        "(1) a PSP é uma força policial armada e uniformizada, (2) a PSP constitui um organismo militarizado, (3) a PSP é uma força de segurança, (4) a PSP é uma força de segurança, uniformizada e armada.",
        "(1) a PSP constitui um organismo militarizado, (2) a PSP é uma força policial armada e uniformizada, (3) a PSP é uma força de segurança, (4) a PSP é uma força de segurança, uniformizada e armada."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "(1) a PSP constitui um organismo militarizado, (2) a PSP é uma força policial armada e uniformizada, (3) a PSP é uma força de segurança, (4) a PSP é uma força de segurança, uniformizada e armada."
      ],
      "score": 10
    },
    {
      "question": "Ao longo dos anos a organização da PSP sofreu algumas alterações com o intuito de acompanhar a evolução da sociedade portuguesa. Das afirmações que se seguem indique qual corresponde à atual organização da PSP: ",
      "choices": [
        "A PSP compreende um comando geral, os comandos metropolitanos, os comandos regionais, os comandos de polícia, o comando das forças especiais e os estabelecimentos de ensino.",
        "A PSP compreende o comando geral e as polícias distritais.",
        "A PSP compreende a direção nacional, as unidades de polícia e os estabelecimentos de ensino policial.",
        "A PSP compreende direção nacional, os comandos metropolitanos, regionais e de polícia, o corpo de intervenção, o grupo de operações especiais, o corpo de segurança pessoal, o instituto superior de ciências policiais e segurança interna e escola pratica de policia."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A PSP compreende a direção nacional, as unidades de polícia e os estabelecimentos de ensino policial."
      ],
      "score": 10
    },
    {
      "question": "A PSP depende do membro do governo responsável pela:",
      "choices": [
        "Área da administração interna e a sua organização é única para todo o território nacional.",
        "Área da administração interna conjuntamente com a área da justiça e a sua organização é única para todo o território nacional.",
        "Área da justiça e a sua organização é única para todo o território nacional.",
        "Área da administração interna e a sua organização é dividida em duas áreas territoriais- urbana e rural."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Área da administração interna e a sua organização é única para todo o território nacional."
      ],
      "score": 10
    },
    {
      "question": "Em situações de normalidade institucional, as atribuições da PSP são as decorrentes da legislação de segurança interna. Constituem atribuições da PSP:",
      "choices": [
        "Garantir a ordem e a tranquilidade públicas e a segurança e os interesses particulares das pessoas e dos bens.",
        "Prevenir a criminalidade em geral, coordenando todas as demais forças e serviços de segurança.",
        "Garantir as condições de segurança que permitam o exercício dos direitos e liberdades e o respeito pelas garantias dos cidadãos, bem como o pleno funcionamento das instituições democráticas no respeito pela legalidade e pelos princípios do estado de direito.",
        "Desenvolver todas as ações de investigação criminal e contra-ordenacional que lhe sejam denunciadas, em total autonomia. "
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Garantir as condições de segurança que permitam o exercício dos direitos e liberdades e o respeito pelas garantias dos cidadãos, bem como o pleno funcionamento das instituições democráticas no respeito pela legalidade e pelos princípios do estado de direito."
      ],
      "score": 10
    },
    {
      "question": "A direção nacional compreende",
      "choices": [
        "O diretor nacional, os diretores nacionais adjuntos, o conselho superior de polícia, o conselho de deontologia e disciplina a junta superior de saúde e a inspeção.",
        "O diretor nacional, os diretores nacionais adjuntos, a inspeção, as unidades orgânicas de operações e segurança, de recursos humanos e de logística e finanças.",
        "O diretor nacional, os diretores nacionais adjuntos, o conselho superior de policia, o conselho de deontologia e disciplina, a junta superior de saúde, a inspeção, as unidades orgânicas de operações e segurança, de recursos humanos e de logística e finanças.",
        "O diretor nacional, os diretores nacionais adjuntos, o conselho superior de policia, o conselho de deontologia e disciplina, a inspeção, as unidades orgânicas de operações e segurança, de recursos humanos e de logística e finanças."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O diretor nacional, os diretores nacionais adjuntos, o conselho superior de policia, o conselho de deontologia e disciplina, a junta superior de saúde, a inspeção, as unidades orgânicas de operações e segurança, de recursos humanos e de logística e finanças."
      ],
      "score": 10
    },
    {
      "question": "O diretor nacional da PSP é coadjuvado por",
      "choices": [
        "4 diretores nacionais adjuntos, que dirigem respetivamente, as unidades orgânicas de operações e segurança, de recursos humanos, de logística e finanças e a inspeção.",
        "3 diretores nacionais adjuntos, que dirigem respetivamente, as unidades orgânicas de operações e segurança, de recursos humanos e de logística e finanças.",
        "2 diretores nacionais adjuntos, que dirigem respetivamente, as unidades orgânicas de operações e segurança e de recursos humanos.",
        "3 diretores nacionais adjuntos, que dirigem respetivamente, o conselho superior de policia, o conselho de deontologia e disciplina e a junta superior de saúde."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "3 diretores nacionais adjuntos, que dirigem respetivamente, as unidades orgânicas de operações e segurança, de recursos humanos e de logística e finanças."
      ],
      "score": 10
    },
    {
      "question": "O diretor da PSP é substituído nas suas faltas ou impedimentos",
      "choices": [
        "Pelo diretor nacional adjunto que dirige a unidade orgânica de operações e segurança",
        "Pelo diretor nacional adjunto que dirige a unidade orgânica de recursos humanos",
        "Pelo diretor nacional adjunto que dirige a unidade orgânica de logística e finanças",
        "Pelo diretor nacional adjunto que dirige o conselho superior de policia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pelo diretor nacional adjunto que dirige a unidade orgânica de operações e segurança"
      ],
      "score": 10
    },
    {
      "question": "A escola pratica de policia é um estabelecimento de ensino policial, na dependência do diretor nacional, que tem por missão",
      "choices": [
        "Formar oficiais de policia, promover o seu aperfeiçoamento permanente e realizar, coordenar ou colaborar em projetos de investigação do domínio das ciências policiais.",
        "Ministrar cursos e estágios de formação, aperfeiçoamento e atualização de agentes, chefes e oficiais, e de especialização para todo o pessoal da PSP.",
        "Formar oficiais de policia, ministrar cursos e estágios de formação, aperfeiçoamento e atualização de agentes e chefes. Ministrar cursos e estágios de formação, aperfeiçoamento e atualização de agentes e chefes, e de especialização para todo o pessoal da PSP.",
        "Ministrar cursos e estágios de formação aperfeiçoamento e atualização de agentes e chefes, e de especialização para todo o pessoal da PSP."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ministrar cursos e estágios de formação aperfeiçoamento e atualização de agentes e chefes, e de especialização para todo o pessoal da PSP."
      ],
      "score": 10
    },
    {
      "question": "A unidade especial de policia (UEP) compreende as seguintes subunidades operacionais:",
      "choices": [
        "O corpo de intervenção, o grupo de operações especiais, o corpo de segurança pessoal, o grupo de intervenção de proteção e socorro e o grupo operacional cinotécnico.",
        "O corpo de intervenção, o grupo de operações especiais, o corpo de segurança pessoal, o centro de inativação de explosivos e segurança em subsolo e o grupo operacional cinotécnico.",
        "O corpo de intervenção, o grupo de operações especiais, o serviço de estrangeiros e fronteiras, o centro de inativação de explosivos e segurança em subsolo e o grupo operacional cinotécnico.",
        "O corpo de intervenção, o grupo de operações especiais, o serviço de estrangeiros e fronteiras, o centro de inativação de explosivos e segurança em subsolo e a unidade de controlo costeiro."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O corpo de intervenção, o grupo de operações especiais, o corpo de segurança pessoal, o centro de inativação de explosivos e segurança em subsolo e o grupo operacional cinotécnico."
      ],
      "score": 10
    },
    {
      "question": "O corpo de intervenção (CI) constitui uma força reservada à ordem do diretor nacional, especialmente preparada e destinada a ser utilizada",
      "choices": [
        "Em ações de manutenção e reposição da ordem publica.",
        "Para a segurança pessoal de altas entidades, membros de órgãos de soberania, proteção policial de testemunhas ou outros cidadãos sujeitos a ameaça.",
        "Para combater situações de violência declarada cuja resolução ultrapasse os meios normais de atuação.",
        "Especialmente na aplicação de canídeos no quadro de competências da PSP."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Em ações de manutenção e reposição da ordem publica."
      ],
      "score": 10
    },
    //
    {
      "question": "Os elementos da PSP com funções policiais são considerados agentes da força pública e de autoridade quando lhes não deva ser atribuído qualidade superior. São consideradas autoridades de polícia:",
      "choices": [
        "O comandante-geral, o 2.o comandante-geral, o inspetor nacional, os comandantes dos Comandos Operacionais da PSP, outros oficiais da PSP, quando no exercício de funções de comando ou chefia operacional.",
        "O diretor nacional, os diretores nacionais adjuntos, o inspetor nacional, o comandante da Unidade Especial de Polícia, os comandantes das unidades e subunidades até ao nível de esquadra, todos os oficiais da PSP.",
        "O diretor nacional, os diretores nacionais adjuntos, o inspetor nacional, o comandante da Unidade Especial de Polícia, os comandantes das unidades e subunidades até ao nível de esquadra, outros oficiais da PSP, quando no exercício de funções de comando ou chefia operacional.",
        "O comandante-geral, o 2.o comandante-geral, o inspetor nacional, o comandante da Unidade Especial de Polícia, os comandantes das unidades e subunidades até ao nível de esquadra, todos os oficiais da PSP no exercício de funções de comando ou chefia operacional."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O diretor nacional, os diretores nacionais adjuntos, o inspetor nacional, o comandante da Unidade Especial de Polícia, os comandantes das unidades e subunidades até ao nível de esquadra, outros oficiais da PSP, quando no exercício de funções de comando ou chefia operacional."
      ],
      "score": 10
    },
    {
      "question": "Os elementos da PSP no exercício do comando de forças têm a categoria de comandantes de força pública. Considera-se força pública:",
      "choices": [
        "O efetivo mínimo de dois agentes em missão de serviço.",
        "O efetivo mínimo de seis agentes em missão de serviço.",
        "O efetivo mínimo de seis agentes, chefiados por um oficial, em missão de serviço.",
        "O efetivo mínimo de dois agentes, chefiados por um chefe, em missão de serviço."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O efetivo mínimo de dois agentes em missão de serviço."
      ],
      "score": 10
    },
    {
      "question": "A Lei n.° 53/2007 de 31 de Agosto aprova a orgânica da Polícia de Segurança Pública e define órgãos de polícia criminal como:",
      "choices": [
        "Os Oficiais e Chefes da PSP com funções policiais incumbidos de realizar quaisquer atos ordenados por autoridade judiciária ou determinados pelo Código de Processo Penal.",
        "Todos os elementos da PSP com funções policiais incumbidos de realizar quaisquer atos ordenados por autoridade judiciária ou determinados pelo Código de Processo Penal.",
        "Todos os elementos da PSP com funções policiais incumbidos de realizar quaisquer atos ordenados por autoridade judiciária ou determinados pelo Código de Processo Administrativo.",
        "Todos os elementos da PSP com funções policiais e com o curso de investigação criminal, incumbidos de realizar quaisquer atos ordenados por autoridade judiciária ou determinados pelo Código de Processo Administrativo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todos os elementos da PSP com funções policiais incumbidos de realizar quaisquer atos ordenados por autoridade judiciária ou determinados pelo Código de Processo Penal."
      ],
      "score": 10
    },
    {
      "question": "A Direção Nacional compreende:",
      "choices": [
        "O Comandante-geral, o 2.o Comandante-geral, o Comando Operacional, o Comando da Administração dos Recursos Internos e o Comando da Doutrina e Formação, a Inspeção e as unidades orgânicas de operações e segurança, de recursos humanos e de logística e finanças.",
        "O diretor nacional, os diretores nacionais adjuntos, o Conselho Superior de Polícia, o Conselho de Deontologia e Disciplina e a Junta Superior de Saúde, a Inspeção e as unidades orgânicas de operações e segurança, de recursos humanos e de logística e finanças.",
        "O diretor nacional, os diretores nacionais adjuntos, o Conselho Superior de Polícia, o Conselho de Deontologia e Disciplina e a Junta Superior de Saúde, a Inspeção Geral da Administração Interna.",
        "O diretor nacional, os diretores nacionais adjuntos, o Conselho Superior de Polícia, o Comando Operacional, o Comando da Administração dos Recursos Internos e a Inspeção Geral da Administração Interna."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O diretor nacional, os diretores nacionais adjuntos, o Conselho Superior de Polícia, o Conselho de Deontologia e Disciplina e a Junta Superior de Saúde, a Inspeção e as unidades orgânicas de operações e segurança, de recursos humanos e de logística e finanças."
      ],
      "score": 10
    },
    {
      "question": "Na PSP existem as seguintes unidades de polícia:",
      "choices": [
        "A Unidade Especial de Polícia e os Comandos Territoriais de Polícia.",
        "A Unidade Especial de Polícia, a Unidade de Segurança e Honras de Estado e a Unidade de intervenção e reserva.",
        "Os Comandos Regionais de Polícia, os Comandos Metropolitanos de Polícia de Lisboa e do Porto e os Comandos Distritais de Polícia.",
        "A Unidade de Segurança e Honras de Estado e a Unidade de intervenção e reserva."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A Unidade Especial de Polícia e os Comandos Territoriais de Polícia."
      ],
      "score": 10
    },
    //2020
    {
      "question": "A Polícia de Segurança de Segurança Pública, adiante designada por PSP, é uma força de segurança militarizada, uniformizada e armada, com natureza de serviço público e dotada de autonomia administrativa.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "A PSP depende do membro do Governo responsável pela área da defesa nacional e a sua organização é única para todo o território nacional. ",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "A PSP pode prosseguir a sua missão fora do território nacional, desde que legalmente mandatada para esse efeito ",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "No caso de atribuições cometidas simultaneamente à Guarda Nacional Republicana, a área de responsabilidade da PSP é definida por quem?",
      "choices": [
        "Diretor nacional",
        "Comandante da GNR",
        "Definida por portaria do ministro da tutela.",
        "Nenhuma das respostas anteriores"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Definida por portaria do ministro da tutela."
      ],
      "score": 10
    }
  ],
}
