

import { Topic } from '..'

export const psp_estatutos_3: Topic = {
  topic: 'Estatutos - PSP (3)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "A condição policial caracteriza-se, entre outras, pela defesa da legalidade democrática, da segurança externa e dos direitos fundamentais dos cidadãos, nos termos da Constituição e da lei.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "A condição policial caracteriza-se, entre outras, pela disponibilidade permanente para o serviço, bem como para a formação e para o treino.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "A condição policial caracteriza-se, entre outras, pela adoção, em todas as situações, de uma conduta pessoal e profissional conforme aos princípios éticos e deontológicos da função policial.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias assumem o compromisso público de respeitar a Constituição e as demais leis da República e obrigam-se a cumprir os regulamentos e as determinações a que devam respeito, nos termos da lei.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias não se regem por código deontológico nem por regulamento disciplinar próprios.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Os polícias devem dedicar-se ao serviço sem lealdade, zelo, competência, integridade de carácter e espírito de bem servir, utilizando e desenvolvendo de forma permanente a sua aptidão, competência e formação profissional.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Os polícias, ainda que se encontrem fora do período normal de trabalho e da área de responsabilidade da subunidade ou serviço onde exerçam funções, devem, até à intervenção da autoridade de polícia criminal competente, tomar as providências necessárias e urgentes, dentro da sua esfera de competência, para evitar a prática ou para descobrir e deter os autores de qualquer crime de cuja preparação ou execução tenham conhecimento.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias estão investidos do poder de autoridade nos termos e sem limites previstos na Constituição e na lei.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Os polícias devem manter permanente disponibilidade para o serviço, ainda que com sacrifício dos interesses pessoais.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias devem ter residência habitual na localidade onde predominantemente prestam serviço ou em local que diste até 50 km daquela.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "É dever especial dos polícias garantir a proteção das vítimas de crimes, dos detidos e das pessoas que se encontrem sob a sua custódia ou proteção, no respeito pela honra e dignidade da pessoa humana.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "É dever especial dos polícias exibir previamente prova da sua qualidade quando, não uniformizados, aplicarem medidas de polícia ou emitirem qualquer ordem ou mandado legítimo.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "É dever especial dos polícias agir com a determinação exigível, e por vezes recorrer à força mais do que o estritamente necessário para cumprir uma tarefa legalmente exigida ou autorizada.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Os atos processuais de investigação criminal e de coadjuvação das autoridades judiciárias não estão sujeitos a segredo de justiça, nos termos da lei.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Os polícias devem manter sempre as necessárias competências técnicas e as condições físicas e psíquicas exigíveis ao cumprimento da missão.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Para o exercício de funções operacionais ou de apoio, que pela sua natureza e especificidade assim o exijam, pode ser dispensado o uso de uniforme ou armamento, nas condições fixadas por despacho do ministro da tutela.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Os polícias utilizam o armamento e os equipamentos, fornecidos ou autorizados pela PSP, necessários à execução das tarefas de que estão incumbidos e zelam pela respetiva guarda, segurança e conservação.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias consideram-se identificados quando devidamente uniformizados.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias não podem ser temporariamente dispensados da necessidade de revelar a sua identidade e qualidade, meios materiais e equipamentos utilizados.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Quando não uniformizados e em ato ou missão de serviço, os polícias identificam-se através de quaisquer meios que revelem, inequivocamente, a sua qualidade.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    }
  ],
}
