import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Container, Typography } from '@mui/material';
import Android from '../assets/images/android.png';
import Windows from '../assets/images/windows.png';



// Estilo Global
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background: #00509E;
    color: #333;
  }
`;

// Wrapper principal para a página
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 80px;
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding-top: 100px;
  }
`;

// Container principal estilizado
const StyledContainer = styled(Container)`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  max-width: 800px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    width: 90%;
    margin-top: 10%;
  }
`;

// Título principal
const DownloadTitle = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 30px !important;
  color: #00509E !important;
`;

// Seção de conteúdo
const ContentBox = styled.div`
  width: 100%;
  margin-bottom: 40px;
  text-align: left;

  @media (max-width: 600px) {
    text-align: justify;
  }
`;

// Ícones de Download
const IconWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;

  img {
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  .windows {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }

  .android {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .ios {
    width: 95px;
    height: 95px;
    object-fit: contain;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    img {
      margin-bottom: 20px;
    }
  }
`;

// Página de Download
const Download = () => {
  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        <StyledContainer>
          <DownloadTitle variant="h4" gutterBottom>
            Faça o Download do EuQueroEntrar
          </DownloadTitle>

          {/* Informação básica */}
          <ContentBox>
            <Typography variant="body1">
              Escolha a versão adequada ao seu dispositivo e comece a sua
              preparação para ingressar na <strong>PSP</strong> ou <strong>GNR</strong>.
            </Typography>
            <Typography variant="body1">
              Disponível para Windows, Android e iOS.
            </Typography>
          </ContentBox>

          {/* Ícones com links de download */}
          <IconWrapper>
            {/* Windows */}
            <a href="/downloadwindows" >
              <img 
                src={Windows} 
                alt="Windows Download" 
                className="windows"
              />
            </a>

            {/* Android */}
            <a href="/downloadandroid">
              <img 
                src={Android} 
                alt="Android Download" 
                className="android"
              />
            </a>

            {/* iOS */}
            <a href="/downloadios" >
              <img 
                src="https://cdn-icons-png.flaticon.com/512/888/888841.png" 
                alt="iOS Download" 
                className="ios"
              />
            </a>
          </IconWrapper>
        </StyledContainer>
      </PageWrapper>
    </>
  );
};

export default Download;
