import React from "react";
import styled, { createGlobalStyle } from "styled-components";
// import { BrowserRouter as Router } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// Ícones Material UI
import {
  VerifiedUser,
  LibraryMusic,
  Assessment,
  Psychology,
  Print,
  CheckCircle
} from "@mui/icons-material";

// Carrossel (react-slick)
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Estilo Global
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0 !important;
    padding: 0 !important;
    font-family: 'Arial', sans-serif !important;
    background-color: #f5f5f5 !important;
    color: #333 !important;
    overflow-x: hidden !important;
  }
`;

const PageWrapper = styled.div`
  width: 100% !important;
  overflow: hidden !important;
`;

// Hero Section
const BackgroundContainer = styled.div`
  width: 100% !important;
  height: 500px !important;
  background: linear-gradient(
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("https://t3.ftcdn.net/jpg/03/52/01/04/360_F_352010422_mgLhwVNjjgMJaywUWypvDIVPftNy75Lk.jpg")
      center/cover no-repeat !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #fff !important;
`;

// Secção de Benefícios
const BenefitsSection = styled.div`
  background-color: #fff !important;
  padding: 30px 20px !important;

  h2 {
    font-size: 1.8rem !important;
    text-align: center !important;
    color: #00509e !important;
    margin-bottom: 20px !important;
  }

  .benefits-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 20px !important;
    flex-wrap: wrap !important;

    .benefit {
      max-width: 300px !important;
      text-align: center !important;

      .icon {
        font-size: 60px !important;
        color: #333 !important;
        margin-bottom: 10px !important;
      }

      h3 {
        font-size: 1.2rem !important;
        color: #333 !important;
        margin-bottom: 10px !important;
      }

      p {
        font-size: 0.9rem !important;
        color: #666 !important;
      }
    }
  }

  @media (min-width: 768px) {
    padding: 50px 20px !important;

    h2 {
      font-size: 2.5rem !important;
      margin-bottom: 30px !important;
    }

    .benefits-container {
      flex-direction: row !important;
      justify-content: center !important;
      gap: 30px !important;

      .benefit {
        h3 {
          font-size: 1.5rem !important;
        }
        p {
          font-size: 1rem !important;
        }
      }
    }
  }
`;

// Secção de Preços
const PricesSection = styled.div`
  background-color: #f9f9f9 !important;
  padding: 30px 20px !important;

  h2 {
    font-size: 1.8rem !important;
    text-align: center !important;
    color: #00509e !important;
    margin-bottom: 20px !important;
  }
  .prices-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 20px !important;

    .price-card {
      background-color: #fff !important;
      border: 1px solid #ddd !important;
      border-radius: 8px !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
      max-width: 300px !important;
      width: 100% !important;
      text-align: center !important;
      padding: 20px !important;

      h3 {
        font-size: 1.4rem !important;
        color: #00509e !important;
        margin-bottom: 10px !important;
      }
      p {
        font-size: 0.9rem !important;
        color: #666 !important;
        margin-bottom: 15px !important;
      }
      .price {
        font-size: 1.5rem !important;
        color: #333 !important;
        font-weight: bold !important;
        margin-bottom: 20px !important;
      }
      button {
        background-color: #00509e !important;
        color: #fff !important;
        padding: 10px 20px !important;
        font-size: 1rem !important;
        border: none !important;
        border-radius: 8px !important;
        cursor: pointer !important;
        &:hover {
          background-color: #003f7f !important;
        }
      }
    }
  }

  @media (min-width: 768px) {
    padding: 50px 20px !important;

    h2 {
      font-size: 2.5rem !important;
      margin-bottom: 30px !important;
    }

    .prices-container {
      flex-direction: row !important;
      justify-content: center !important;
      gap: 30px !important;

      .price-card {
        h3 {
          font-size: 1.8rem !important;
        }
        .price {
          font-size: 2rem !important;
        }
      }
    }
  }
`;

// Secção de Testemunhos (carrossel)
const TestimonialsSection = styled.div`
  background-color: #fff !important;
  padding: 30px 20px !important;

  h2 {
    font-size: 1.8rem !important;
    text-align: center !important;
    color: #00509e !important;
    margin-bottom: 20px !important;
  }
  .slick-dots li button:before {
    color: #00509e !important;
  }
  .testimonials-slider {
    width: 100% !important;
    max-width: 700px !important;
    margin: 0 auto !important;
    outline: none !important;
  }
  .testimonial-slide {
    padding: 20px !important;
    background-color: #fafafa !important;
    border-radius: 8px !important;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1) !important;
    text-align: left !important;
    min-height: 140px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;

    p {
      font-size: 0.95rem !important;
      color: #555 !important;
      margin-bottom: 10px !important;
    }
    .author {
      font-weight: bold !important;
      color: #333 !important;
      font-size: 0.9rem !important;
      text-align: right !important;
    }
  }
  @media (min-width: 768px) {
    padding: 50px 20px !important;
    h2 {
      font-size: 2.5rem !important;
      margin-bottom: 30px !important;
    }
    .testimonial-slide {
      p {
        font-size: 1rem !important;
      }
    }
  }
`;

// Secção de Mensagem do Desenvolvedor (com estilo de citação)
const DeveloperMessageSection = styled.div`
  background-color: #f9f9f9 !important;
  padding: 30px 20px !important;
  text-align: center !important;

  h2 {
    font-size: 1.8rem !important;
    color: #00509e !important;
    margin-bottom: 20px !important;
  }
  .message-box {
    max-width: 800px !important;
    margin: 0 auto !important;
    background-color: #fff !important;
    border-radius: 8px !important;
    padding: 20px !important;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1) !important;
    text-align: left !important;

    p {
      font-family: "Georgia", serif !important; /* fonte serif p/ citação */
      font-style: italic !important;           
      font-size: 1rem !important;
      line-height: 1.6 !important;
      color: #555 !important;
      margin-bottom: 15px !important;
    }

    .developer-signature {
      margin-top: 0.5rem !important;
      font-size: 0.875rem !important;
      font-style: italic !important;
      color: #333 !important;
    }
  }

  @media (min-width: 768px) {
    padding: 50px 20px !important;

    h2 {
      font-size: 2.5rem !important;
    }
    .message-box p {
      font-size: 1.1rem !important;
    }
  }
`;

// ====== Footer com linha branca fina no topo =======
// import { Link } from "react-router-dom";
const Footer = styled.footer`
  border-top: 1px solid #fff !important; /* Linha branca fina no topo */
  background-color: #00509e !important;
  color: #fff !important;
  padding: 20px !important;
  text-align: center !important;

  p {
    margin: 0 !important;
    font-size: 0.8rem !important;
  }
  a {
    color: #ffd700 !important;
    text-decoration: none !important;
    &:hover {
      text-decoration: underline !important;
    }
  }
  @media (min-width: 768px) {
    p {
      font-size: 0.9rem !important;
    }
  }
`;

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
};

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        {/* Hero Section */}
        <BackgroundContainer>
          <h1 style={{ textAlign: "center" }}>
            Bem-Vindo ao EuQueroEntrar!
          </h1>
        </BackgroundContainer>

        {/* Secção de Benefícios */}
        <BenefitsSection>
          <h2>Porquê usar a plataforma?</h2>
          <div className="benefits-container">
            
            {/* Benefício 1 */}
            <div className="benefit">
              <VerifiedUser className="icon" />
              <h3>Preparação Completa</h3>
              <p>Baseado em testes reais de anos anteriores, garantindo a melhor preparação.</p>
            </div>

            {/* Benefício 2 */}
            <div className="benefit">
              <LibraryMusic className="icon" />
              <h3>Materiais em Áudio</h3>
              <p>Para reveres conteúdo em qualquer lugar, seja no carro ou no ginásio.</p>
            </div>

            {/* Benefício 3 */}
            <div className="benefit">
              <CheckCircle className="icon" />
              <h3>Estatísticas Detalhadas</h3>
              <p>Relatórios e gráficos para identificares pontos fortes e fracos.</p>
            </div>

            {/* Benefício 4 */}
            <div className="benefit">
              <Assessment className="icon" />
              <h3>Inteligência Artificial</h3>
              <p>Recebe sugestões de estudo personalizadas e maximize os teus resultados.</p>
            </div>

            {/* Benefício 5 */}
            <div className="benefit">
              <Psychology className="icon" />
              <h3>Simuladores Realistas</h3>
              <p>Treina como se estivesses no dia da prova, aumentando a confiança.</p>
            </div>

            {/* Benefício 6 */}
            <div className="benefit">
              <Print className="icon" />
              <h3>Gerar Testes em Papel</h3>
              <p>Cria versões offline para praticar à moda antiga.</p>
            </div>
            
          </div>
        </BenefitsSection>

        {/* Secção de Preços */}
        <PricesSection>
          <h2>Planos e Preços</h2>
          <div className="prices-container">
            <div className="price-card">
              <h3>Plano Gratuito</h3>
              <p>Acesso limitado a recursos</p>
              <p>Sem pagamento</p>

              <div className="price">€0.00</div>
              <button onClick={() => navigate("/dashboard")}>Continuar</button>
            </div>
            <div className="price-card">
              <h3>Plano Premium</h3>
              <p>Acesso completo e suporte</p>
              <p>Pagamento único</p>

              <div className="price">€3.99</div>
              <button onClick={() => navigate("/purchase")}>Comprar</button>
            </div>
          </div>
        </PricesSection>

        {/* Testemunhos (carrossel) */}
        <TestimonialsSection>
          <h2>Testemunhos</h2>
          <Slider {...sliderSettings} className="testimonials-slider">
            <div className="testimonial-slide">
              <p>
                "Excelente plataforma! Consegui passar na prova de conhecimentos graças ao
                conteúdo de qualidade e simuladores disponíveis."
              </p>
              <div className="author">- João Silva</div>
            </div>
            <div className="testimonial-slide">
              <p>
                "O EuQueroEntrar é fantástico! A experiência de aprendizagem é dinâmica e
                motivadora. Recomendo a todos."
              </p>
              <div className="author">- Maria Santos</div>
            </div>
            <div className="testimonial-slide">
              <p>
                "A plataforma oferece uma abordagem prática e intuitiva. Vale cada cêntimo
                investido no plano premium!"
              </p>
              <div className="author">- Ana Pereira</div>
            </div>
          </Slider>
        </TestimonialsSection>

        {/* Mensagem do Desenvolvedor (com estilo de citação) */}
        <DeveloperMessageSection>
          <h2>Mensagem do Desenvolvedor</h2>
          <div className="message-box">
            <p>
              “Olá! Sou o criador deste projeto e a minha principal motivação é ajudar futuros agentes
              a concretizarem o seu sonho de ingressar nas forças de segurança. Acredito que,
              ao oferecer ferramentas e conteúdos de qualidade, podemos tornar este percurso
              mais acessível e eficaz.
            </p>
            <p>
              Obrigado por confiares no <strong>EuQueroEntrar</strong> e desejo-te o maior sucesso na tua preparação!”
            </p>
            <p className="developer-signature">— Faustino Pinto</p>
          </div>
        </DeveloperMessageSection>

        {/* Footer */}
        <Footer>
          <p>
            © 2025 EuQueroEntrar. Todos os direitos reservados. <br />
            <a href="/terms">Termos</a> | <a href="/policy">Política</a>
          </p>
        </Footer>
      </PageWrapper>
    </>
  );
};

export default HomePage;
