

import { Topic } from '..'

export const psp_estatutos_1: Topic = {
  topic: 'Estatutos - PSP (1)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    // Quiz estatutos PSP
    {
      "question": "A condição policial caracteriza-se:",
      "choices": [
        "Pela sujeição a um regulamento estipulado pelo Ministério da Administração Interna;",
        "Pela subordinação à hierarquia de comando no Ministério;",
        "Pela subordinação ao interesse público;",
        "Pela subordinação ao interesse do Presidente da República."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pela subordinação ao interesse público;"
      ],
      "score": 10
    },
    {
      "question": "Os polícias devem dedicar-se ao serviço com:",
      "choices": [
        "Dedicação, zelo, competência, integridade de carácter e espírito de bem servir, utilizando e desenvolvendo de forma permanente a sua aptidão, competência e formação profissional.",
        "Aprumo, zelo, competência, integridade de carácter e espírito de bem servir, utilizando e desenvolvendo de forma permanente a sua aptidão, competência e formação profissional.",
        "Convicção, zelo, competência, integridade de carácter e espírito de bem servir, utilizando e desenvolvendo de forma permanente a sua aptidão, competência e formação profissional.",
        "Lealdade, zelo, competência, integridade de carácter e espírito de bem servir, utilizando e desenvolvendo de forma permanente a sua aptidão, competência e formação profissional."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Lealdade, zelo, competência, integridade de carácter e espírito de bem servir, utilizando e desenvolvendo de forma permanente a sua aptidão, competência e formação profissional."
      ],
      "score": 10
    },
    {
      "question": "Os polícias devem ter residência habitual na localidade onde predominantemente prestam serviço ou:",
      "choices": [
        "Em local que diste até 40 km daquela.",
        "Em local que diste até 50 km daquela.",
        "Em local que diste até 60 km daquela.",
        "Em local que diste até 55 km daquela."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Em local que diste até 50 km daquela."
      ],
      "score": 10
    },
    {
      "question": "São deveres especiais dos polícias:",
      "choices": [
        "Atuar sem discriminação em razão de ascendência, sexo, raça, língua, território de origem, religião, convicções políticas ou ideológicas, instrução, situação económica, condição social ou orientação sexual;",
        "Exibir previamente prova da sua qualidade quando, uniformizados, aplicarem medidas de polícia ou emitirem qualquer ordem ou mandado legítimo;",
        "Atuar com discriminação em razão de ascendência, sexo, raça, língua, território de origem, religião, convicções políticas ou ideológicas, instrução, situação económica, condição social ou orientação sexual;",
        "Nenhuma das hipóteses está correta."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Atuar sem discriminação em razão de ascendência, sexo, raça, língua, território de origem, religião, convicções políticas ou ideológicas, instrução, situação económica, condição social ou orientação sexual;"
      ],
      "score": 10
    },
    {
      "question": "O período anual de férias tem a duração de:",
      "choices": [
        "21 dias úteis;",
        "20 dias úteis;",
        "18 dias úteis;",
        "22 dias úteis."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "22 dias úteis."
      ],
      "score": 10
    },
    {
      "question": "As modalidades de licenças sem remuneração compreendem:",
      "choices": [
        "Licença sem remuneração de média duração;",
        "Licença sem remuneração de longa duração;",
        "Licença sem remuneração para acompanhamento de cônjuge falecido;",
        "Licença sem remuneração para o exercício de funções na União Europeia."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Licença sem remuneração de longa duração;"
      ],
      "score": 10
    },
    {
      "question": "O serviço da PSP é:",
      "choices": [
        "De caráter exclusivo e de serviço ao cidadão;",
        "De caráter obrigatório e rotativo;",
        "De caráter permanente e obrigatório;",
        "De serviço ao público."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De caráter permanente e obrigatório;"
      ],
      "score": 10
    },
    {
      "question": "O regime de turnos é:",
      "choices": [
        "Obrigatório, quando o trabalho é prestado nos sete dias da semana;",
        "Semanal prolongado, quando é prestado em todos os cinco dias úteis e no sábado ou em feriados;",
        "Semanal, quando é prestado de segunda-feira a domingo;",
        "Nenhuma das hipóteses está correta."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Nenhuma das hipóteses está correta"
      ],
      "score": 10
    },
    {
      "question": "Qual das alíneas não se encontra correta? Os polícias estão integrados, por ordem decrescente de hierarquia, nas seguintes carreiras e categorias:",
      "choices": [
        "Oficial de polícia, que compreende as categorias de superintendente-chefe, superintendente, intendente, subintendente, comissário e subcomissário;",
        "Chefe de polícia, que compreende as categorias chefe coordenador, chefe principal e chefe;",
        "Agente de polícia, que compreende as categorias de agente coordenador, agente principal e agente.",
        "Agente provisório, sendo admitido ao curso de formação de agentes."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Agente provisório, sendo admitido ao curso de formação de agentes."
      ],
      "score": 10
    },
    {
      "question": "Os polícias podem encontrar-se numa das seguintes situações:",
      "choices": [
        "De licença;",
        "Aposentados;",
        "Adido;",
        "Fora do Ativo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Aposentados;"
      ],
      "score": 10
    },
    {
      "question": "Qual a posição a nível remuneratório de um Agente provisório?",
      "choices": [
        "Nível 1;",
        "Nível 3;",
        "Nível 4;",
        "Nível 2."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "RPCT"
      ],
      "score": 10
    },
    // PC 2017
    {
      "question": "Os policias estão integrados por ordem decrescente de hierarquia nas seguintes carreiras e categoriais:",
      "choices": [
        "Oficial de policia, que compreende as categorias de superintendente, intendente, subintendente e comissário, chefe de policia, que compreende as categorias chefe coordenador, chefe principal e chefe e agente de policia, que compreende as seguintes categorias de agente coordenador, agente principal e agente",
        "Oficial de policia que compreende as categorias de superintendente-chefe, superintendente intendente e subcomissário, chefe de policia que compreende as categorias chefe principal e chefe e agente de policia que compreende as categorias de agente principal e agente",
        "Oficial de policia, que compreende as categorias de superintendente-chefe superintendente intendente subintendente comissario e subcomissário, chefe de policia que compreende as categorias chefe coordenador, chefe principal e chefe e agente de policia que compreende as categorias de agente coordenador, agente principal e agente.",
        "Oficial de policia, que compreende as categorias de superintendente, intendente, subintendente, comissario e subcomissário, chefe de policia que compreende as categorias chefe coordenador, chefe principal e chefe e agente de policia, que compreende as categorias de agente principal e agente."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Oficial de policia, que compreende as categorias de superintendente-chefe superintendente intendente subintendente comissario e subcomissário, chefe de policia que compreende as categorias chefe coordenador, chefe principal e chefe e agente de policia que compreende as categorias de agente coordenador, agente principal e agente."
      ],
      "score": 10
    },
    {
      "question": "São definitivamente nomeados na categoria de agente os alunos habilitados com o curso de formação de agentes. Após a conclusão do mesmo o policia é sujeito a uma avaliação a ter lugar em período experimental com a duração de ",
      "choices": [
        "3 anos e o regime de avaliação do período experimental da nomeação dos policias é aprovado por despacho do ministro da administração interna.",
        "2 anos e o regime de avaliação do período experimental da nomeação dos policias é aprovado por despacho do diretor nacional.",
        "1 ano e o regime de avaliação do período experimental da nomeação dos policias é aprovado por despacho do diretor nacional.",
        "5 anos e o regime de avaliação do período experimental da nomeação dos policias é aprovado por despacho do ministro da administração interna sob proposta do diretor nacional."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "1 ano e o regime de avaliação do período experimental da nomeação dos policias é aprovado por despacho do diretor nacional."
      ],
      "score": 10
    },
    {
      "question": "São condições de promoção para a categoria de agente principal",
      "choices": [
        "Ter pelo menos o tempo mínimo de 4 anos de serviço efetivo na categoria de agente e estar na classe de comportamento exemplar ou na 1ª classe de comportamento e ter frequentado, na categoria, um mínimo de 90 horas de formação policial ou de outras formações reconhecidas como de interesse para as competências da PSP.",
        "Ter pelo menos o tempo mínimo de 6 anos de serviço efetivo na categoria de agente e estar na classe de comportamento exemplar ou na 1ª classe de comportamento e ter frequentado, na categoria, um mínimo de 90 horas de formação policial ou de outras formações reconhecidas como de interesse para as competências da PSP.",
        "Ter pelo menos o tempo mínimo de 6 anos de serviço efetivo na categoria e agente e estar na classe de comportamento exemplar ou na 1ª ou 2ª classe de comportamento e ter frequentado, na categoria, um mínimo de 90 horas de formação policial ou de outras formações reconhecidas como de interesse para as competências da PSP.",
        "Ter pelo menos o tempo mínimo de 8 anos de serviço efetivo na categoria de agente e estar na classe de comportamento exemplar ou na 1ª ou 2ª classe de comportamento e ter frequentado, na categoria, um mínimo de 90 horas de formação policial ou de outras formações reconhecidas como de interesse para as competências da PSP."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ter pelo menos o tempo mínimo de 6 anos de serviço efetivo na categoria de agente e estar na classe de comportamento exemplar ou na 1ª classe de comportamento e ter frequentado, na categoria, um mínimo de 90 horas de formação policial ou de outras formações reconhecidas como de interesse para as competências da PSP."
      ],
      "score": 10
    },
    {
      "question": "Quanto ao uso e porte de arma, os policias",
      "choices": [
        "Não têm direito ao uso e porte de armas e munições de qualquer tipo, mesmo que se sujeitem a um plano de formação e de certificação constituído por provas teóricas e praticas de tiro.",
        "Têm direito ao uso e porte de armas e munições de qualquer tipo, desde que as adquiram num armeiro, mas estão sujeitos a um plano de formação e de certificação constituído por provas teóricas e práticas de tiro.",
        "Têm direito ao uso e porte de armas e munições de qualquer tipo, desde que distribuídas pelo estado, e estão sujeitos a um plano de formação e de certificação constituído por provas teóricas e praticas de tiro.",
        "Têm direito ao uso e porte de armas e munições de qualquer tipo, desde que distribuídas pelo Estado e não estão sujeitos a um plano de formação e de certificação de tiro."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Têm direito ao uso e porte de armas e munições de qualquer tipo, desde que distribuídas pelo estado, e estão sujeitos a um plano de formação e de certificação constituído por provas teóricas e praticas de tiro."
      ],
      "score": 10
    },
    //PC 2019
    {
      "question": "Os polícias exercem as suas funções devidamente uniformizados e armados. Para o exercício de funções operacionais ou de apoio:",
      "choices": [
        "Que pela sua natureza e especificidade assim o exijam, nunca pode ser dispensado o uso de uniforme ou armamento.",
        "Que pela sua natureza e especificidade assim o exijam, pode ser dispensado o uso de uniforme ou armamento, nas condições fixadas por despacho do diretor nacional.",
        "Que pela sua natureza e especificidade assim o exijam, pode ser dispensado o uso de uniforme ou armamento, nas condições fixadas por despacho do Ministro da Administração Interna.",
        "Que pela sua natureza e especificidade assim o exijam, pode ser dispensado o uso de uniforme ou armamento, nas condições fixadas por despacho do Comandante direto."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Que pela sua natureza e especificidade assim o exijam, pode ser dispensado o uso de uniforme ou armamento, nas condições fixadas por despacho do diretor nacional."
      ],
      "score": 10
    },
    {
      "question": "Os polícias têm direito à dotação inicial do fardamento, aprovado por portaria do membro do Governo responsável pela área da administração interna, no momento do ingresso na PSP. Esta afirmação é:",
      "choices": [
        "Verdadeira. A PSP participa nas despesas com a aquisição de fardamento efetuadas pelos polícias na efetividade de serviço, através da atribuição de uma comparticipação anual no valor de € 600, com a natureza de prestação social.",
        "Falsa. A PSP não participa nas despesas com a aquisição de fardamento efetuadas pelos polícias na efetividade de serviço.",
        "Verdadeira. A comparticipação anual no valor de € 600, com a natureza de prestação social, só é assegurada decorridos três anos sobre a data de ingresso na PSP.",
        "Falsa. Os polícias, quando nomeados para integrarem missões internacionais ou de cooperação policial, podem beneficiar de uma dotação complementar de fardamento e equipamento, pagas pelo próprio."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeira. A PSP participa nas despesas com a aquisição de fardamento efetuadas pelos polícias na efetividade de serviço, através da atribuição de uma comparticipação anual no valor de € 600, com a natureza de prestação social."
      ],
      "score": 10
    },
    {
      "question": "O direito a férias é irrenunciável e o seu gozo efetivo não pode ser substituído por qualquer compensação económica, ainda que com o acordo do interessado. No ano civil de ingresso, os polícias:",
      "choices": [
        "Têm direito a dois dias e meio de férias por cada um dos meses completos de serviço efetivo prestado até 31 de dezembro desse ano.",
        "Podem gozar as suas férias, após três meses completos de efetividade de serviço.",
        "Têm direito a dois dias úteis de férias por cada um dos meses completos de serviço efetivo prestado até 31 de dezembro desse ano.",
        "Podem gozar as suas férias, após doze meses completos de efetividade de serviço."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Têm direito a dois dias úteis de férias por cada um dos meses completos de serviço efetivo prestado até 31 de dezembro desse ano."
      ],
      "score": 10
    },
    {
      "question": "A ausência, quando previsível, é comunicada por escrito ao superior hierárquico competente, acompanhada da indicação do motivo justificativo, com a antecedência mínima de cinco dias úteis. Esta afirmação é:",
      "choices": [
        "Falsa. Os polícias podem faltar quatro dias por mês por conta do período de férias, até ao máximo de 15 dias por ano.",
        "Verdadeira. Os polícias podem faltar dois dias por mês por conta do período de férias, até ao máximo de 13 dias por ano.",
        "Falsa. As faltas por conta do período de férias são obrigatoriamente comunicadas com a antecedência mínima de 24 horas e estão sujeitas a autorização, que pode ser recusada se forem suscetíveis de causar prejuízo para o normal funcionamento do serviço, por decisão devidamente fundamentada.",
        "Verdadeira. As faltas por conta do período de férias são obrigatoriamente comunicadas com a antecedência mínima de 24 horas e não estão sujeitas a autorização."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeira. Os polícias podem faltar dois dias por mês por conta do período de férias, até ao máximo de 13 dias por ano."
      ],
      "score": 10
    },
    {
      "question": "O período anual de férias tem a duração de quantos dias úteis?",
      "choices": [
        "21",
        "22",
        "23",
        "24"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "22"
      ],
      "score": 10
    }
  ],
}
