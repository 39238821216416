import React, { useState } from 'react';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../firebase/firebaseConfig';
import { Container, TextField, Button, Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { Box, Alert } from '@mui/material';

const PageTitle = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 30px !important;
  color: #00509E;
`;

const StyledButton = styled('button')({
  background: '#00509E',
  color: '#fff',
  width: '100%',
  padding: '10px 15px',
  border: 'none',
  marginTop: '20px',
  cursor: 'pointer',
  '&:hover': {
    background: '#1e88e5',
  },
});

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Um email foi enviado com as instruções para redefinir a sua senha.');
    } catch (err) {
      console.error("Error resetting password: ", err);
      setError('Erro ao enviar o email de redefinição. Verifique o endereço e tente novamente.');
    }
  };

  

  return (
    <Container maxWidth="sm" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <Paper style={{ padding: '2rem', width: '100%' }}>
      <PageTitle variant="h4" gutterBottom>
            Recuperar
          </PageTitle>
        <form onSubmit={handlePasswordReset}>
          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {message && (
            <Typography variant="body1" color="success" style={{ marginTop: '1rem' }}>
              {message}
            </Typography>
          )}
          {error && (
            <Typography variant="body1" color="error" style={{ marginTop: '1rem' }}>
              {error}
            </Typography>
          )}
          {/* <Button variant="contained" color="primary" type="submit" fullWidth style={{ marginTop: '1rem' }} sx={{ color: '#00509E' }}>
            Enviar Email de Recuperação
          </Button> */}
          <StyledButton type="submit">Enviar Email de Recuperação</StyledButton>
        </form>
      </Paper>
    </Container>
  );
};

export default ResetPassword;
