import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Typography, Button, Checkbox, FormControlLabel } from "@mui/material";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from "chart.js";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";

import { auth, db } from '../firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

import { trackEvent } from "../components/usePageTracking";

import '@fortawesome/fontawesome-free/css/all.min.css';
import { CircularProgress } from "@mui/material";
import jsPDF from "jspdf"; // Certifique-se de instalar com `npm install jspdf`
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import watermarkImage from '../assets/images/watermark.png';
import QRCode from "qrcode";








ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const API_BASE_URL = "https://api.euqueroentrar.pt:7709/api";
const API_BASE_URL_testes = "https://api.euqueroentrar.pt:7709/api";



const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background: #f7f7f7;
    color: #333;
    margin-top: 30px;
    background-color: #00509E;
  }
`;

const PageWrapper = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px);
  padding: 20px;
  box-sizing: border-box;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const StyledContainer = styled.div`
  margin-top: 50px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const QuizTitle = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 20px !important;
  color: #00509E !important;
`;

const Paragraph = styled(Typography)`
  line-height: 1.6 !important;
  margin-bottom: 10px !important;
  text-align: left;
`;

const ButtonContainer2 = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`;
const QuestionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza "Pergunta x de x" */
  justify-content: center; /* Adiciona alinhamento vertical */
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;

  .pergunta {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #111827;
    margin-bottom: 10px; /* Espaço entre "Pergunta x de x" e o tema */
  }

  .tema {
    align-self: flex-start; /* Tema alinhado à esquerda */
    font-size: 18px;
    font-weight: 500;
    color: #6b7280;
    margin-top:10px;
  }
`;

const QuestionContainer = styled.div`
  background-color: white;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
`;

const TemaContainer = styled(Typography)`
  text-align: left;
  font-style: italic;
  color: #555;
  margin-bottom: 10px;
`;

const QuestionText = styled(Paragraph)`
  text-align: left; /* Mantém o texto da pergunta alinhado à direita */
`;

const OptionsList = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align:left;
`;

const OptionButton = styled(Button)`
  text-align: left;
  justify-content: flex-start;
  border: 1px solid transparent;
  text-transform: none !important; /* Garante que o texto fique como recebido */
  background-color: ${({ isSelected, isCorrect }) =>
    isSelected
      ? isCorrect
        ? "#d4edda" // Verde para resposta correta selecionada
        : "#f8d7da" // Vermelho para resposta errada selecionada
      : isCorrect
      ? "#d4edda" // Verde para a resposta correta não selecionada
      : "#f0f0f0"} !important;
  &:hover {
    background-color: ${({ isSelected, isCorrect }) =>
      isSelected
        ? isCorrect
          ? "#c3e6cb" // Verde escuro no hover
          : "#f5c6cb" // Vermelho escuro no hover
        : isCorrect
        ? "#c3e6cb" // Verde claro para a correta
        : "#ddd"} !important;
  }
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;



const SummaryContainer = styled.div`
  margin-top: 30px;
  padding: 20px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  text-align: left;

  div {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #fff;
  }

  button {
    margin-top: 20px;
  }
`;

const CenteredFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 15px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
`;

const CenteredTitleContainer = styled.div`
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  text-align: center;
  width: 100%;
  margin-bottom: 20px; /* Espaço abaixo do título */
`;

const AnswerCard = styled.div`
  margin-bottom: 15px !important;
  padding: 10px !important;
  border: 1px solid #ccc !important;
  border-radius: 6px !important;
  background-color: ${({ isCorrect }) => (isCorrect ? "#fff" : "#f8d7da")} !important; /* Branco se correto, vermelho claro se errado */
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  max-width: 400px;
  text-align: left; /* Alinhamento padrão para o texto */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

  .title-container {
    text-align: center; /* Centraliza os títulos */
    margin-bottom: 20px; /* Espaço abaixo dos títulos */
  }

  .button-container {
    display: flex;
    justify-content: flex-end; /* Alinha o botão à direita */
    margin-top: 20px;

    button {
      padding: 10px 20px;
      background-color: #00509E;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;

      &:hover {
        background-color: #003f7f;
      }
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  background-color: transparent !important; /* Garante que não há cor de fundo */
  border: none !important; /* Remove qualquer borda, se aplicável */
  box-shadow: none; /* Remove sombras, se houver */
  padding: 0; /* Garante que não há espaçamento extra */
`;

const ExplanationPanel = styled.div`
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 8px;
  margin-top: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: #333;
`;


const MagicButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6c63ff; /* Cor principal */
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 15px;
  border: none;
  margin-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  i {
    margin-right: 8px; /* Espaçamento entre o ícone e o texto */
    font-size: 16px;
  }

  &:hover {
    background-color: #574bff; /* Alteração de cor ao hover */
  }

  &:active {
    background-color: #483aff;
  }

  &:disabled {
    background-color: #b3b3b3; /* Cor para estado desabilitado */
    cursor: not-allowed;
  }
`;



const NewPSP = () => {
  const [temas, setTemas] = useState([]);
  const [selectedTemas, setSelectedTemas] = useState([]);
  const [numQuestions, setNumQuestions] = useState(10);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [quizFinished, setQuizFinished] = useState(false);
  const [quizStarted, setQuizStarted] = useState(false);
  const [hasPurchased, setHasPurchased] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [openDialog, setOpenDialog] = useState(false); // Controla se o diálogo está aberto
  const [reportText, setReportText] = useState(""); // Texto do relatório
  const [currentReportQuestion, setCurrentReportQuestion] = useState(null); // Pergunta sendo reportada
  const [explanations, setExplanations] = useState({}); // Armazena explicações por índice
  const [loadingExplanationIndex, setLoadingExplanationIndex] = useState(null); // Índice da pergunta que está carregando

  const generatePDFWithWatermark = async () => {
    // Verifique se os temas selecionados estão presentes
    if (!selectedTemas.length) {
      alert("Por favor, selecione pelo menos um tema.");
      return;
    }
  
    alert("Gerando PDF... Aguarde!");
  
    const temaQuery = selectedTemas.join(",");
  
    try {
      const response = await axios.get(`${API_BASE_URL}/quiz?temaIds=${temaQuery}&quantidade=${numQuestions}`);
      if (response.status === 200) {
        const questions = response.data;
  
        // Organizar perguntas por tema
        const questionsByTema = questions.reduce((acc, question) => {
          const temaName = temas.find((t) => t.id === question.idTema)?.nome || "Desconhecido";
          if (!acc[temaName]) acc[temaName] = [];
          acc[temaName].push(question);
          return acc;
        }, {});
  
        // Inicializar jsPDF
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const margin = 10; // Margem
        const lineHeight = 10; // Altura da linha
        let y = 20; // Posição inicial do conteúdo na página
  
        // Carregar a imagem da marca d'água
        const watermarkImage = new Image();
        watermarkImage.src = 'caminho/para/sua/marca_dagua.png'; // Substitua pelo caminho correto da sua imagem
  
        watermarkImage.onload = () => {
          // Adicionar a marca d'água em cada página
          const addWatermark = () => {
            const imgWidth = pageWidth; // Largura da imagem ajustada para a largura da página
            const imgHeight = pageHeight; // Altura da imagem ajustada para a altura da página
            const x = 0; // Posição X
            const y = 0; // Posição Y
  
            doc.addImage(watermarkImage, 'PNG', x, y, imgWidth, imgHeight, '', 'FAST');
          };
  
          // Função para redefinir o estilo da fonte
          const resetFontStyle = () => {
            doc.setFontSize(12);
            doc.setTextColor(0, 0, 0);
          };
  
          // Adicionar conteúdo ao PDF
          const addContent = () => {
            // Título
            doc.setFontSize(16);
            doc.text("Prova Personalizada", margin, y);
            y += lineHeight * 2;
  
            // Adicionar perguntas organizadas por temas
            for (const temaName of Object.keys(questionsByTema)) {
              if (y > pageHeight - 40) {
                doc.addPage();
                addWatermark(); // Marca d'água na nova página
                resetFontStyle();
                y = 20;
              }
  
              // Título do tema
              doc.setFontSize(14);
              doc.setTextColor(0, 0, 255); // Azul para o tema
              doc.text(temaName, margin, y);
              y += lineHeight * 2;
  
              // Perguntas do tema
              const temaQuestions = questionsByTema[temaName];
              doc.setFontSize(12);
              doc.setTextColor(0, 0, 0);
  
              temaQuestions.forEach((question, index) => {
                if (y > pageHeight - 40) {
                  doc.addPage();
                  addWatermark(); // Marca d'água na nova página
                  resetFontStyle();
                  y = 20;
                }
  
                // Quebra de linha para perguntas longas
                const questionLines = doc.splitTextToSize(`${index + 1}. ${question.perguntaTexto}`, pageWidth - margin * 2);
                doc.text(questionLines, margin, y);
                y += questionLines.length * lineHeight;
  
                // Adicionar opções
                [1, 2, 3, 4].forEach((n) => {
                  const option = question[`resposta${n}`];
                  if (option) {
                    if (y > pageHeight - 40) {
                      doc.addPage();
                      addWatermark(); // Marca d'água na nova página
                      resetFontStyle();
                      y = 20;
                    }
  
                    const optionLines = doc.splitTextToSize(
                      `   ${String.fromCharCode(64 + n)}) ${option}`,
                      pageWidth - margin * 2
                    );
                    doc.text(optionLines, margin, y);
                    y += optionLines.length * lineHeight;
                  }
                });
              });
            }
          };
  
          // Adicionar a marca d'água e o conteúdo
          addWatermark();
          resetFontStyle();
          addContent();
  
          // Salvar o PDF
          doc.save("prova_personalizada.pdf");
          trackEvent("Quiz", "PDF_Test_Created", "PDF_Test_Created");

        };
      } else {
        alert("Erro ao carregar perguntas da API.");
      }
    } catch (error) {
      console.error("Erro ao gerar PDF:", error);
      alert("Erro ao gerar PDF. Verifique sua conexão e tente novamente.");
    }
  };
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  

  const generatePDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
  
    // Marca d'água
    doc.setFontSize(50);
    doc.setTextColor(200, 200, 200);
    doc.text("www.euqueroentrar.pt", pageWidth / 2, pageHeight / 2, {
      angle: 45,
      align: "center",
    });
  
    // Título
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text("Prova Personalizada", 10, 20);
  
    // Listar perguntas e opções
    let y = 30;
    quizQuestions.forEach((question, index) => {
      doc.setFontSize(12);
      doc.text(`${index + 1}. ${question.perguntaTexto}`, 10, y);
      y += 10;
  
      // Listar respostas
      [1, 2, 3, 4].forEach((n) => {
        const option = question[`resposta${n}`];
        if (option) {
          doc.text(`   ${String.fromCharCode(64 + n)}) ${option}`, 15, y);
          y += 7;
        }
      });
  
      // Nova página se ultrapassar limite
      if (y > pageHeight - 20) {
        doc.addPage();
        y = 20;
  
        // Marca d'água na nova página
        doc.setFontSize(50);
        doc.setTextColor(200, 200, 200);
        doc.text("www.euqueroentrar.pt", pageWidth / 2, pageHeight / 2, {
          angle: 45,
          align: "center",
        });
      }
    });
  
    // Salvar o PDF
    doc.save("prova_personalizada.pdf");
  };
  const navigate = useNavigate();

  useEffect(() => {
    
    // Fetch temas from API
    axios
      .get(`${API_BASE_URL}/temas`)
      .then((response) => {
        if (response.status === 200) {
          setTemas(response.data);
        }
      })
      .catch((error) => console.error("Erro ao buscar temas:", error));
  }, []);

  useEffect(() => {
    const checkPurchaseStatus = async () => {
      try {
        const userId = auth.currentUser?.uid;
        if (userId) {
          const userDoc = await getDoc(doc(db, "users", userId));
          if (userDoc.exists()) {
            setHasPurchased(userDoc.data().hasPurchased || false);
          }
        } else {
          setHasPurchased(false);
        }
      } catch (error) {
        console.error("Erro ao verificar status de compra:", error);
      }
    };

    checkPurchaseStatus();
  }, []);

  const calculateStats = () => {
    const stats = selectedTemas.map((temaId) => {
      const tema = temas.find((t) => t.id === temaId);
      return {
        tema: tema ? tema.nome : "Desconhecido",
        correct: 0,
        wrong: 0,
      };
    });
  
    answers.forEach((answer, index) => {
      const question = quizQuestions[index];
      const temaId = question.idTema;
      const temaIndex = stats.findIndex((stat) => temas.find((t) => t.id === temaId)?.nome === stat.tema);
  
      if (temaIndex >= 0) {
        if (answer.correct) {
          stats[temaIndex].correct += 1;
        } else {
          stats[temaIndex].wrong += 1;
        }
      }
    });
  
    return stats;
  };

  const generateChartData = () => {
    const stats = calculateStats();
    return {
      labels: stats.map((stat) => stat.tema),
      datasets: [
        {
          label: "Respostas Corretas",
          data: stats.map((stat) => stat.correct),
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
        {
          label: "Respostas Erradas",
          data: stats.map((stat) => stat.wrong),
          backgroundColor: "rgba(255, 99, 132, 0.6)",
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Verifica se a string é válida
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const chartData = generateChartData();

  const handleTemaChange = (temaId) => {
    if (selectedTemas.includes(temaId)) {
      setSelectedTemas(selectedTemas.filter((id) => id !== temaId));
    } else {
      setSelectedTemas([...selectedTemas, temaId]);
    }
  };

  const fetchQuestions = () => {
    
    try {
      // Rastreia o evento
      
    }catch (error) {
      console.error("Erro ao rastrear evento:", error);}
    if (hasPurchased) {
      const temaQuery = selectedTemas.join(","); // Cria uma string "2,3,8"
      if (!temaQuery){
        alert("Por favor selecione um tema")
      }
      trackEvent("Quiz", "Start Quiz", "Start Button Clicked");
      setExplanations({});
      axios
        .get(`${API_BASE_URL}/quiz?temaIds=${temaQuery}&quantidade=${numQuestions}`)
        .then((response) => {
          if (response.status === 200) {
            const sortedQuestions = response.data.sort((a, b) => a.idTema - b.idTema);
            setQuizQuestions(sortedQuestions);
            setCurrentQuestionIndex(0);
            setAnswers([]);
            setQuizFinished(false);
            setQuizStarted(true);
          }
        })
        .catch((error) => console.error("Erro ao buscar perguntas:", error));
    } else {
      navigate("/purchase"); // Redireciona para a página de compra
    }
  };

  const temaMap = temas.reduce((map, tema) => {
    map[tema.id] = tema.nome;
    return map;
  }, {});

  const handleAnswer = (optionIndex) => {
    const currentQuestion = quizQuestions[currentQuestionIndex];
  
    // Se já foi respondida, não faz nada
    if (answers.some((answer) => answer.question === currentQuestion.perguntaTexto)) {
      return;
    }
  
    const isCorrect = optionIndex === currentQuestion.respostaCerta;
  
    // Salvar resposta
    setAnswers((prev) => [
      ...prev,
      {
        question: currentQuestion.perguntaTexto,
        selected: currentQuestion[`resposta${optionIndex + 1}`],
        correct: isCorrect,
        correctAnswer: currentQuestion[`resposta${currentQuestion.respostaCerta + 1}`],
      },
    ]);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < quizQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      saveGameToAPI();
      setQuizFinished(true);
    }
  };

  const handleFinishQuiz = () => {
    saveGameToAPI(); // Salvar o jogo na API ao terminar o quiz

    setQuizFinished(true);
  };

  const handleRestartQuiz = () => {
    setQuizStarted(false);
    setQuizFinished(false);
    setAnswers([]);
    setCurrentQuestionIndex(0);
  };

  const handleOpenDialog = (question) => {
    setCurrentReportQuestion(question);
    setOpenDialog(true);
  };
  
  // Fecha o diálogo
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setReportText("");
    setCurrentReportQuestion(null);
  };
  
  // Envia o relatório
  const handleSubmitReport = async () => {
    if (reportText.trim() === "") {
      alert("Por favor, insira um texto para o relatório.");
      return;
    }
  
    const userId = auth.currentUser?.uid || "Usuário não autenticado"; // Obtém o ID do usuário ou usa um fallback
  
    const reportData = {
      perguntaId: currentReportQuestion?.id, // ID da pergunta
      userId: userId, // ID do usuário
      reportText: reportText, // Texto do relatório
      corrigido: false, // Padrão como false
    };
  
    try {
      const response = await axios.post(`${API_BASE_URL_testes}/Quiz/report_perguntas`, reportData, {
        headers: { "Content-Type": "application/json" },
      });
  
      if (response.status === 200) {
        alert("Relatório enviado com sucesso. Obrigado pelo feedback!");
        handleCloseDialog();
      } else {
        alert("Não foi possível enviar o relatório. Tente novamente.");
      }
    } catch (error) {
      console.error("Erro ao enviar relatório:", error);
      alert("Erro ao enviar o relatório. Verifique sua conexão e tente novamente.");
    }
  };

  //logica de premium aqui: 

  const handleExplain = async (index, question, correctAnswer) => {
    try {
      setLoadingExplanationIndex(index); // Ativa o estado de loading para a pergunta atual
      trackEvent("Quiz", "AI_Explanation", "AI_Explanation Button Clicked");
      const explanation = await explainAnswerWithAI(question, correctAnswer);
      setExplanations((prev) => ({
        ...prev,
        [index]: explanation,
      }));
    } catch (error) {
      console.error("Erro ao gerar explicação:", error);
      alert("Não foi possível gerar a explicação no momento.");
    } finally {
      setLoadingExplanationIndex(null); // Desativa o estado de loading após a explicação ser carregada
    }
  };

  const explainAnswerWithAI = async (question, correctAnswer) => {
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: "Você é um assistente que ajuda a explicar questões em Português de Portugal.",
            },
            {
              role: "user",
              content: `A pergunta é: '${question}'. Faz-me uma breve explicação porque é que a resposta certa é: '${correctAnswer}'. Importante ser em muitas poucas palavras`,
            },
          ],
          max_tokens: 150,
          temperature: 0.7,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer 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`,

          },
        }
      );
      return response.data.choices[0].message.content.trim();
    } catch (error) {
      console.error("Erro ao obter explicação:", error);
      return "Não foi possível gerar a explicação no momento.";
    }
  };

  const generatePDFWithQuestions = async () => {

    if (hasPurchased) {
      if (!selectedTemas.length) {
          alert("Por favor, selecione pelo menos um tema.");
          return;
      }

      alert("Gerando PDF... Aguarde!");

      try {
          // Gerar o PDF na API
          const generateResponse = await axios.post(
              'https://api.euqueroentrar.pt:7709/api/quiz/generate',
              {
                  UserId: auth.currentUser?.uid,
                  TemaIds: selectedTemas,
                  Quantidade: numQuestions
              }
          );

          if (generateResponse.status === 200) {
              const { pdfId, accessUrl } = generateResponse.data;

              // Obter perguntas da API
              const questionsResponse = await axios.get(
                  `https://api.euqueroentrar.pt:7709/api/quiz/resposta?id=${pdfId}`
              );

              if (questionsResponse.status === 200) {
                  const questions = questionsResponse.data;

                  // Organizar perguntas por tema
                  const questionsByTema = questions.reduce((acc, question) => {
                      const temaName = question.temaNome || "Desconhecido";
                      if (!acc[temaName]) acc[temaName] = [];
                      acc[temaName].push(question);
                      return acc;
                  }, {});

                  // Inicializar jsPDF
                  const doc = new jsPDF();
                  const pageWidth = doc.internal.pageSize.getWidth();
                  const pageHeight = doc.internal.pageSize.getHeight();
                  const margin = 10; // Margem
                  const lineHeight = 10; // Altura da linha
                  let y = 20; // Posição inicial do conteúdo na página

                  const addWatermark = () => {
                      doc.addImage(watermarkImage, 'PNG', 0, 0, pageWidth, pageHeight, undefined, 'FAST');
                  };

                  const createNewPage = () => {
                      doc.addPage();
                      addWatermark();
                      y = margin;
                  };

                  addWatermark();

                  // Adicionar conteúdo ao PDF
                  doc.setFontSize(20);
                  doc.setTextColor(0, 0, 0);
                  doc.text("Teste em papel - euqueroentrar.pt", margin, y);
                  y += lineHeight * 2;

                  doc.setFontSize(10);
                  doc.setTextColor(0, 0, 0);
                  doc.text("Este teste não é oficial e pode conter erros. Teste gerado aleatóriamente.", margin, y);
                  y += lineHeight * 2;

                  for (const [temaName, temaQuestions] of Object.entries(questionsByTema)) {
                      if (y > pageHeight - 40) createNewPage();

                      // Adicionar título do tema
                      doc.setFontSize(14);
                      doc.setTextColor(0, 0, 255);
                      doc.text(`Tema: ${temaName}`, margin, y);
                      y += lineHeight * 2;

                      // Perguntas do tema
                      doc.setFontSize(12);
                      doc.setTextColor(0, 0, 0);

                      temaQuestions.forEach((question, index) => {
                          if (y > pageHeight - 40) {
                              createNewPage();
                          }

                          const questionText = `${index + 1}. ${question.perguntaTexto}`;
                          const questionLines = doc.splitTextToSize(questionText, pageWidth - margin * 2);
                          doc.text(questionLines, margin, y);
                          y += questionLines.length * lineHeight;

                          // Adicionar opções de resposta
                          [1, 2, 3, 4].forEach((n) => {
                              const option = question[`resposta${n}`];
                              if (option) {
                                  if (y > pageHeight - 40) {
                                      createNewPage();
                                  }

                                  const optionText = `   ${String.fromCharCode(64 + n)}) ${option}`;
                                  const optionLines = doc.splitTextToSize(optionText, pageWidth - margin * 2);
                                  doc.text(optionLines, margin, y);
                                  y += optionLines.length * lineHeight;
                              }
                          });

                          y += lineHeight; // Espaço extra entre perguntas
                      });
                  }

                  // Gerar QR Code
                  const qrCodeDataUrl = await QRCode.toDataURL(accessUrl);

                  // Adicionar QR Code na última página
                  createNewPage();
                  doc.setFontSize(16);
                  doc.text("Link das soluções (24h de validade): ", margin, margin);
                  doc.addImage(qrCodeDataUrl, 'PNG', margin, margin + lineHeight, 50, 50);
                  doc.text(accessUrl, margin, margin + lineHeight * 7);

                  // Salvar o PDF
                  doc.save("prova_personalizada.pdf");
              } else {
                  alert("Erro ao carregar perguntas da API.");
              }
          } else {
              alert("Erro ao gerar PDF na API.");
          }
      } catch (err) {
        if (err.response && err.response.data === "Limite de testes atingido.") {
          alert("Limite de testes atingido.");
          console.error("Erro ao gerar PDF:", err);}
          else{
            alert("Erro ao gerar PDF. Verifique sua conexão e tente novamente.");
          }
          
      }
    }
    else
    {
      navigate("/purchase"); // Redireciona para a página de compra

    }
};


const saveGameToAPI = async () => {
  try {
    const userId = auth.currentUser?.uid; // Obtenha o ID do usuário logado
    if (!userId) {
      alert("É necessário estar autenticado para salvar o jogo.");
      return;
    }

    // Preparar os dados do jogo para envio
    const gameData = {
      userId: userId,
      Respostas: quizQuestions.map((question, index) => {
        const userAnswer = answers[index]; // A resposta do usuário para a pergunta atual
        return {
          perguntaId: question.id, // ID da pergunta
          respostaCerta: question.respostaCerta, // Índice correto da API (0, 1, 2, 3)
          respostaAssinalada: userAnswer
            ? [0, 1, 2, 3].find(
                (n) => question[`resposta${n + 1}`] === userAnswer.selected
              ) // Mapeia o índice baseado em 0
            : null, // Caso não tenha sido respondida
        };
      }),
    };

    // Enviar os dados para a API
    const response = await axios.post(
      `${API_BASE_URL_testes}/quiz/salvar_jogo`,
      gameData,
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    if (response.status === 200) {
      const { jogoId } = response.data;
      // alert(`Jogo salvo com sucesso! ID do jogo: ${jogoId}`);
    } else {
      alert("Erro ao salvar o jogo. Tente novamente.");
    }
  } catch (error) {
    console.error("Erro ao salvar o jogo:", error);
    alert("Erro ao salvar o jogo. Verifique sua conexão e tente novamente.");
  }
};




  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        {!quizStarted && (
          <StyledContainer>
            <QuizTitle variant="h3">PSP</QuizTitle>
            <QuizTitle variant="h6">Prova Customizada</QuizTitle>

            <br/>
            <Paragraph><strong>Seleciona os temas</strong></Paragraph>

            <CenteredFormContainer>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedTemas.includes(8)} // Cultura Geral
                  onChange={() => handleTemaChange(8)}
                />
              }
              label={<strong>Cultura Geral</strong>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedTemas.includes(9)} // Portugues
                  onChange={() => handleTemaChange(9)}
                />
              }
              label={<strong>Português</strong>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedTemas.includes(13)} // Portugues
                  onChange={() => handleTemaChange(13)}
                />
              }
              label={<strong>CRP, DUDH e UE*</strong>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedTemas.includes(2)} // Estatutos PSP
                  onChange={() => handleTemaChange(2)}
                />
              }
              label={<strong>Estatutos da PSP</strong>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedTemas.includes(3)} // Lei Organica PSP
                  onChange={() => handleTemaChange(3)}
                />
              }
              label={<strong>Lei Orgânica da PSP</strong>}
            />
          
            <Typography style={{ fontSize: "10px", textAlign: "left" }}>* Constituição da República Portuguesa, Documento Universal dos Direitos Humanos, União Europeia</Typography>
            <br/>
            <Paragraph>Número de perguntas:</Paragraph>
            <input
              type="number"
              value={numQuestions === 0 ? "" : numQuestions} // Mostra "" se o valor for 0
              onChange={(e) => {
                const value = e.target.value;
                if (value === "") {
                  setNumQuestions(0); // Define como 0 para evitar erros futuros
                } else if (Number(value) > 100) {
                  setNumQuestions(100); // Limita o valor máximo a 100
                } else {
                  setNumQuestions(Number(value)); // Converte para número apenas se não estiver vazio
                }
              }}
              min="1"
              max="100" // Define o máximo permitido diretamente no input
              style={{
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #d1d5db",
                fontSize: "16px",
                width: "100%",
                maxWidth: "200px",
                textAlign: "center"
              }}
            />
          </CenteredFormContainer>

          <ButtonContainer2>
          <Button
  variant="contained"
  onClick={generatePDFWithQuestions}
  sx={{
    backgroundColor: "#28a745",
    "&:hover": {
      backgroundColor: "#218838",
    },
  }}
>
  Gerar Teste em Papel
</Button>
  <Button
    variant="contained"
    onClick={fetchQuestions}
    sx={{
      backgroundColor: "#00509E",
      "&:hover": {
        backgroundColor: "#003f7f",
      },
    }}
  >
    Começar Prova
  </Button>
  
</ButtonContainer2>
          </StyledContainer>
        )}

        {quizStarted && quizQuestions.length > 0 && !quizFinished && (
          <QuestionContainer>
            
            
            <QuestionHeader>
              <span className="pergunta">
                Pergunta {currentQuestionIndex + 1} de {quizQuestions.length}
              </span>
              <span className="tema">
                Tema: {temas.find((t) => t.id === quizQuestions[currentQuestionIndex].idTema)?.nome || "Desconhecido"}
              </span>
            </QuestionHeader>
            <QuestionText>{quizQuestions[currentQuestionIndex].perguntaTexto}</QuestionText>

            <OptionsList>
              {[1, 2, 3, 4].map((n) => {
                const option = quizQuestions[currentQuestionIndex][`resposta${n}`];
                if (!option) return null;

                const isSelected =
                  answers[currentQuestionIndex] &&
                  answers[currentQuestionIndex].selected === option;
                const isCorrect =
                  answers[currentQuestionIndex] &&
                  answers[currentQuestionIndex].correctAnswer === option;
                const highlightCorrect =
                  answers[currentQuestionIndex] &&
                  !answers[currentQuestionIndex].correct &&
                  isCorrect;

                return (
                  <OptionButton
                    key={n}
                    variant="outlined"
                    onClick={() => handleAnswer(n - 1)}
                    isSelected={isSelected}
                    isCorrect={highlightCorrect || isCorrect}
                    disabled={!!answers[currentQuestionIndex]} // Desativa após resposta
                  >
                    {capitalizeFirstLetter(option)} {/* Capitaliza a primeira letra */}
                  </OptionButton>
                );
              })}
            </OptionsList>

            <ButtonContainer>
              <Button variant="contained" color="error" onClick={handleFinishQuiz}>
                Terminar
              </Button>
              <Button
                variant="contained"
                onClick={handleNextQuestion}
                disabled={
                  !answers.some(
                    (answer) =>
                      answer.question === quizQuestions[currentQuestionIndex]?.perguntaTexto
                  )
                }
                sx={{
                  backgroundColor: "#00509E",
                  "&:hover": {
                    backgroundColor: "#003f7f", // Cor no hover
                  },
                  "&:disabled": {
                    backgroundColor: "#d3d3d3", // Cor para estado desativado
                  },
                }}
              >
                Avançar
              </Button>
            </ButtonContainer>
          </QuestionContainer>
        )}

{quizFinished && (
  <SummaryContainer>
    <br />
    <br />
    <br />
    <CenteredTitleContainer>
      <Typography variant="h5">Resumo da Prova</Typography>
    </CenteredTitleContainer>
    <br />
    <div>
      <Bar
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false, // Permite ajuste ao contêiner pai
          plugins: {
            title: {
              display: true,
              text: "Desempenho por Tema",
            },
            legend: {
              position: "top",
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: "Temas",
              },
            },
            y: {
              title: {
                display: true,
                text: "Quantidade",
              },
              beginAtZero: true,
            },
          },
        }}
        height={400} // Altura padrão
      />
    </div>
    <br />
    {answers.map((answer, index) => (
      <AnswerCard key={index} isCorrect={answer.correct}>
        <Typography variant="subtitle1">
          <strong>Pergunta {index + 1} <br /></strong>
          Tema: {temaMap[quizQuestions[index]?.idTema] || "Desconhecido"} <br />
          <strong>{answer.question}</strong>
        </Typography>
        <Typography variant="body1">
          <br />
          <strong>Sua resposta:</strong> {answer.selected}
          {answer.correct ? " ✅" : " ❌"}
        </Typography>
        {!answer.correct && (
          <Typography variant="body1">
            <strong>Resposta correta:</strong> {answer.correctAnswer}
          </Typography>
        )}
        {/* Botão para reportar a pergunta */}
        <FlexContainer>
          {/* Botão de Reportar */}
          <Button
            variant="outlined"
            color="error"
            onClick={() => handleOpenDialog(quizQuestions[index])}
            size="small"
            sx={{ marginTop: "10px" }}
          >
            Reportar
          </Button>

          {/* Botão Explicar com AI */}
          <Button
              variant="contained"
              color="primary"
              onClick={() =>
                handleExplain(
                  index,
                  quizQuestions[index].perguntaTexto,
                  quizQuestions[index][
                    `resposta${quizQuestions[index].respostaCerta + 1}`
                  ]
                )
              }
              size="small"
              sx={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px", // Espaçamento entre o ícone/texto
              }}
              disabled={loadingExplanationIndex === index} // Desativa o botão enquanto está carregando
            >
              {loadingExplanationIndex === index ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <>
                  <i className="fas fa-magic" style={{ marginRight: "8px" }} />
                  Explicar com AI
                </>
              )}
            </Button>
        </FlexContainer>
          {explanations[index] && (
              <ExplanationPanel>
                <Typography variant="body1">
                  <strong>Explicação da AI:</strong> {explanations[index]}
                </Typography>
              </ExplanationPanel>
            )}

      </AnswerCard>
    ))}
    <Button
      variant="contained"
      onClick={handleRestartQuiz}
      sx={{
        backgroundColor: "#00509E",
        "&:hover": {
          backgroundColor: "#003f7f", // Cor no hover
        },
      }}
    >
      Voltar ao Início
    </Button>

    
  </SummaryContainer>
  
  
)}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
  <DialogTitle>Reportar Pergunta</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Por favor, descreva o motivo do relatório para a pergunta abaixo:
    </DialogContentText>
    <Typography variant="subtitle1" style={{ marginTop: "10px", fontWeight: "bold" }}>
      {currentReportQuestion?.perguntaTexto}
    </Typography>
    <TextField
      autoFocus
      margin="dense"
      label="Motivo do Relatório"
      type="text"
      fullWidth
      multiline
      rows={4}
      value={reportText}
      onChange={(e) => setReportText(e.target.value)}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDialog} color="primary">
      Cancelar
    </Button>
    <Button onClick={handleSubmitReport} color="error" variant="contained">
      Enviar Relatório
    </Button>
  </DialogActions>
</Dialog>
      </PageWrapper>
      {showModal && (
        <ModalOverlay>
          <ModalContent>
            <div className="title-container">
              <QuizTitle variant="h3">PSP</QuizTitle>
              <QuizTitle variant="h6">Prova Customizada</QuizTitle>
            </div>
            <Typography variant="body1">
              📚 A nossa base de dados contém mais de <strong>1000 perguntas</strong>, abrangendo diversos temas essenciais para a preparação dos exames. 
            </Typography>
            <Typography variant="body1" style={{ marginTop: "10px" }}>
              ✅ Grande parte das questões foi retirada de <strong>testes reais</strong>, garantindo que estás a treinar com conteúdo de alta qualidade e relevância.
            </Typography>
            <Typography variant="body1" style={{ marginTop: "10px" }}>
              🔄 Cada prova é gerada de forma <strong>totalmente aleatória</strong>, tornando cada teste único e desafiador.
            </Typography>
            <Typography variant="body1" style={{ marginTop: "10px" }}>
              🧠 Esta abordagem permite uma preparação abrangente e eficaz, aumentando as tuas chances de sucesso nos exames oficiais.
            </Typography>
            <Typography variant="body1" style={{ marginTop: "10px" }}>
              📝 A funcionalidade de <strong>Gerar Testes em Papel</strong> está limitado a <strong>5 testes por dia</strong>.
            </Typography>
            <div className="button-container">
              <button onClick={() => setShowModal(false)}>OK</button>
            </div>
          </ModalContent>
        </ModalOverlay>
      )}

    </>
  );
};

export default NewPSP;
