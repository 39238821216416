import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Container, TextField, Button, Typography, Paper, Alert, Box } from '@mui/material';
import { db } from '../firebase/firebaseConfig';
import { collection, addDoc, getDocs, serverTimestamp, orderBy, query, doc, setDoc } from 'firebase/firestore';

// Estilo Global
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background: #f5f9ff;
    color: #333;
  }
`;

// Wrapper principal para a página
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 80px;
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding-top: 100px;
  }
`;

const StyledContainer = styled(Container)`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 800px;
  text-align: center;
`;

const TestimonialBox = styled(Paper)`
  padding: 20px;
  margin: 20px 0;
  text-align: left;
`;

const TestimonialsPage = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [newTestimonial, setNewTestimonial] = useState({ name: '', text: '' });
  const [alert, setAlert] = useState({ visible: false, severity: '', message: '' });

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const q = query(collection(db, 'testemunhos'), orderBy('timestamp', 'desc'));
        const querySnapshot = await getDocs(q);
        const loadedTestimonials = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTestimonials(loadedTestimonials);
      } catch (error) {
        console.error('Erro ao buscar testemunhos: ', error);
      }
    };
    fetchTestimonials();
  }, []);

  const handleInputChange = (e) => {
    setNewTestimonial({ ...newTestimonial, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      if (!newTestimonial.name || !newTestimonial.text) {
        setAlert({ visible: true, severity: 'warning', message: 'Preencha todos os campos.' });
        return;
      }
      const id = `${newTestimonial.name}_${Date.now()}`; // Gerar ID único
      await setDoc(doc(db, 'testemunhos', id), {
        ...newTestimonial,
        timestamp: serverTimestamp(),
      });
      setAlert({ visible: true, severity: 'success', message: 'Testemunho enviado com sucesso!' });
      setNewTestimonial({ name: '', text: '' });
      setFormVisible(false);

      const q = query(collection(db, 'testemunhos'), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      setTestimonials(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error('Erro ao enviar testemunho: ', error);
      setAlert({ visible: true, severity: 'error', message: 'Falha ao enviar testemunho.' });
    } finally {
      setTimeout(() => setAlert({ visible: false, severity: '', message: '' }), 3000);
    }
  };

  return (
    <>
      <GlobalStyle />

      <PageWrapper>
        {alert.visible && (
          <Box sx={{ width: '50%', position: 'fixed', top: 100, left: '50%', transform: 'translateX(-50%)', zIndex: 1200 }}>
            <Alert severity={alert.severity} variant="filled">
              {alert.message}
            </Alert>
          </Box>
        )}

        <StyledContainer>
          <Typography variant="h4" gutterBottom>
            Testemunhos
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setFormVisible(!formVisible)}
            style={{ marginBottom: '20px' }}
          >
            {formVisible ? 'Fechar Formulário' : 'Escrever Testemunho'}
          </Button>

          {formVisible && (
            <Paper style={{ padding: '20px', marginBottom: '20px' }}>
              <TextField
                label="Nome"
                name="name"
                type="text"
                fullWidth
                margin="normal"
                value={newTestimonial.name}
                onChange={handleInputChange}
              />
              <TextField
                label="Testemunho"
                name="text"
                type="text"
                fullWidth
                margin="normal"
                multiline
                rows={4}
                value={newTestimonial.text}
                onChange={handleInputChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ marginTop: '20px' }}
              >
                Enviar
              </Button>
            </Paper>
          )}

          {testimonials.map(({ id, name, text, timestamp }) => (
            <TestimonialBox key={id}>
              <Typography variant="h6">{name}</Typography>
              <Typography variant="body2" color="textSecondary">
                {timestamp && new Date(timestamp.seconds * 1000).toLocaleDateString('pt-PT')}
              </Typography>
              <Typography variant="body1" style={{ marginTop: '10px' }}>
                {text}
              </Typography>
            </TestimonialBox>
          ))}
        </StyledContainer>
      </PageWrapper>
    </>
  );
};

export default TestimonialsPage;
