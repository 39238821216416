import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { Typography, Button } from "@mui/material";
import Stefan from '../assets/images/Stefan.png';


const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background: #f7f7f7;
    color: #333;
  }
`;

const DashboardButton = styled(Button)`
  margin-top: 20px !important;
  border-radius: 6px !important;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px); /* Ajusta o espaço total excluindo a altura da navbar */
  padding: 20px;
  margin-top: 80px; /* Espaço suficiente para a navbar */
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const StyledContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  max-width: 800px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    width: 90%; /* Ajusta a largura no mobile */
  }
`;

const WelcomeTitle = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 20px !important;
`;

const Paragraph = styled(Typography)`
  line-height: 1.6 !important;
  margin-bottom: 20px !important;
  text-align: left;
`;

const DetailsBox = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
`;

const ImageContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;

  img {
    width: 200px; /* Ajustado para imagem maior */
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ddd;
  }
`;

const InputField = styled.input`
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const ObrigadoCompraPT = () => {
  const navigate = useNavigate();
  const goToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        <StyledContainer>
          <WelcomeTitle variant="h4">Obrigado!</WelcomeTitle>

          <Paragraph>
          Caso o pagamento seja realizado, serás contactado(a) nas próximas 48 horas por chamada telefónica para agendamento da tua consulta.
          </Paragraph>

         

          
          <DashboardButton 
          variant="contained" 
          color="primary"
          onClick={goToDashboard}
        >
          Ir para o Dashboard
        </DashboardButton> 
         
       
        </StyledContainer>
      </PageWrapper>
    </>
  );
};

export default ObrigadoCompraPT;
