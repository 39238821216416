import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import MemoryGame from './memoryGame';

const MaintenanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 20vh;
  text-align: center;
  background: linear-gradient(135deg, #f7f7f7, #eaeaea); /* Tons neutros */
  color: #333; /* Texto escuro */
  font-family: 'Roboto', sans-serif;
  padding: 20px;

  @media (min-width: 768px) {
    padding: 40px;
  }
`;

// Título principal
const MaintenanceTitle = styled(Typography)`
  font-size: 2rem !important; /* Mobile-first */
  font-weight: bold !important;
  margin-bottom: 20px;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    font-size: 3rem !important; /* Aumenta em telas maiores */
  }
`;

// Texto de descrição
const MaintenanceText = styled(Typography)`
  font-size: 1rem !important;
  margin-bottom: 30px;
  line-height: 1.8 !important;
  max-width: 600px;

  @media (min-width: 768px) {
    font-size: 1.25rem !important; /* Texto maior em telas maiores */
  }
`;

// Botão para contato
const ContactButton = styled.a`
  display: inline-block;
  background: #007bff; /* Azul moderno */
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 6px;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
`;

// Imagem de manutenção
const MaintenanceImage = styled.img`
  max-width: 100%; /* Mobile-first */
  max-height: 200px;
  margin-bottom: 30px;
  animation: float 3s ease-in-out infinite;

  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }

  @media (min-width: 768px) {
    max-width: 300px; /* Aumenta em telas maiores */
    max-height: 300px;
  }
`;

const Maintenance: React.FC = () => {
  return (<>
    <MaintenanceWrapper>
      {/* <MaintenanceImage src="../assets/images/maintenance.png" alt="Em Manutenção" /> */}
      <MaintenanceTitle variant="h3">
        Em Manutenção
      </MaintenanceTitle>
      <br />
      <br />
      <br />
      <MaintenanceText variant="body1">
        Estamos a trabalhar para melhorar o site e proporcionar uma experiência ainda melhor. <br />Até já!
      </MaintenanceText>

      
      {/* <ContactButton href="mailto:support@euqueroentrar.pt">
        Entre em Contato
      </ContactButton> */}
    </MaintenanceWrapper>
    <br />
    <br />
    <br />

    <MemoryGame/>
  </>
  );
};

export default Maintenance;
