

import { Topic } from '..'

export const psp_leiOrganica_6: Topic = {
  topic: 'Lei Orgânica - PSP (6)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Ao diretor nacional compete fixar os períodos de funcionamento e atendimento dos serviços da PSP.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias estão sujeitos à hierarquia de comando, nos termos previstos na respetiva lei orgânica.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "As listas de antiguidade dos polícias são organizadas por categoria e publicadas anualmente, com referência à situação a 1 de janeiro do ano anterior.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Os polícias são ordenados, dentro de cada categoria, por ordem crescente de antiguidade.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Os policiais graduados são sempre considerados de maior antiguidade que os policiais promovidos a categoria igual.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "De acordo com a sua natureza, as funções policiais classificam-se como, entre outras, funções operacionais, quando implicarem, essencial ou predominantemente, a utilização de conhecimentos e a aplicação de técnicas policiais.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias não podem ser nomeados para cargo a que corresponda categoria inferior à que possuem, nem estar subordinados a policias de menor categoria",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "A promoção consiste no acesso a categoria superior dentro de outra carreira.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "A promoção dos polícias é da competência do membro do Governo responsável pela área da administração interna.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias podem ser graduados em categoria superior, por despacho diretor nacional, sob proposta do membro do Governo responsável pela área da administração interna, com carácter excecional e temporário.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "A carreira de oficial de polícia é uma carreira especial de complexidade funcional de grau 3.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "A carreira de chefe de polícia é uma carreira especial de complexidade funcional de grau 2.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "A promoção a chefe é feita de entre os polícias da carreira de agente que tenham, no mínimo, cinco anos de serviço efetivo e concluam com aproveitamento o CFC, por ordem da respetiva classificação.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "A carreira de agente de polícia é uma carreira especial de complexidade funcional de grau 1.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "A promoção a agente principal é feita por antiguidade, na sequência de procedimento de verificação do preenchimento das condições e sujeito ao número de vagas fixado.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "É condição de promoção para a categoria de agente principal, entre outras, ter pelo menos, o tempo mínimo de seis anos de serviço efetivo na categoria de agente.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "A colocação por oferecimento consiste na colocação de um polícia num comando territorial, a requerimento do próprio, para ocupação de posto de trabalho em categoria superior.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "A colocação por oferecimento pode ser ordinária ou extraordinária.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "A colocação por promoção consiste na colocação do polícia na Direção Nacional e nos comandos territoriais, na sequência de procedimento concursal para categoria superior ou por antiguidade.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "A colocação por convite consiste na colocação do polícia na Direção Nacional, num estabelecimento de ensino policial ou nos Serviços Sociais da PSP, para ocupação de posto de trabalho na mesma categoria.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    }
  ],
}
