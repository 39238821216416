import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Estilo Global (se já utiliza em outro lugar, pode remover ou adaptar)
// Estilo Global
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background: #00509E !important;
    color: #333;
    
  }
`;

// Wrapper principal para a página
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 80px; /* Espaço para navbar fixa */
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding-top: 100px; /* Ajuste para dispositivos móveis */
    
  }
`;

// Container principal estilizado
const StyledContainer = styled(Container)`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;

  max-width: 800px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    width: 90%; /* Ajusta a largura no mobile */
    margin-top: 10%;
  }
`;

// Título principal
const WelcomeTitle = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 30px !important;
`;

// Seção de conteúdo
const ContentBox = styled.div`
  width: 100%;
  margin-bottom: 40px;
  text-align: left;

  @media (max-width: 600px) {
    text-align: justify;
  }
`;

// Subtítulo
const SectionTitle = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 10px !important;
`;

// Parágrafo de texto
const Paragraph = styled(Typography)`
  line-height: 1.6 !important;
  margin-bottom: 20px !important;
`;

// Caixa estilizada para mensagem
const DeveloperBox = styled.div`
  background: #e6f9fc;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  border-left: 6px solid #00bcd4;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
`;

// Botão estilizado
const DashboardButton = styled(Button)`
  margin-top: 20px !important;
  border-radius: 6px !important;
`;

const Welcome = () => {
  const navigate = useNavigate();

  // Função para navegar ao dashboard
  const goToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <>

      <GlobalStyle />
      <StyledContainer>
        <WelcomeTitle variant="h4" gutterBottom>
          Bem-vindo(a) ao EuQueroEntrar!
        </WelcomeTitle>

        {/* Seção: Sobre o Projeto */}
        <ContentBox>
          <SectionTitle variant="h6">Sobre o Projeto</SectionTitle>
          <Paragraph variant="body1">
            O <strong>EuQueroEntrar</strong> é uma plataforma online focada na preparação
            de candidatos para os concursos de admissão à 
            <strong> GNR (Guarda Nacional Republicana)</strong> e 
            <strong> PSP (Polícia de Segurança Pública)</strong>. Aqui, encontrarás 
            materiais de estudo, testes práticos, conteúdo interativo e 
            diversos recursos de apoio desenvolvidos especialmente para te ajudar 
            a enfrentar cada fase do processo de recrutamento.
          </Paragraph>
          <Paragraph variant="body1">
            O nosso objetivo é fornecer-te o suporte necessário para que possas estudar de forma 
            organizada e eficiente, melhorando as tuas chances de sucesso nas provas de conhecimentos, 
            provas físicas e testes psicotécnicos.
          </Paragraph>
        </ContentBox>

        {/* Seção: Mensagem do Desenvolvedor em box */}
        <ContentBox>
          <SectionTitle variant="h6">Mensagem do Desenvolvedor</SectionTitle>
          <DeveloperBox>
          <Paragraph variant="body1" style={{ marginBottom: 0 }}>
            “Olá! Sou o criador deste projeto e a minha principal motivação é ajudar futuros agentes
            a concretizarem o seu sonho de ingressar nas forças de segurança. Acredito que,
            ao oferecer ferramentas e conteúdos de qualidade, podemos tornar este percurso
            mais acessível e eficaz. <br />
            Obrigado por confiares no <strong>EuQueroEntrar</strong> e desejo-te o maior sucesso na tua preparação!”
          </Paragraph>
            <Typography variant="body2" style={{ marginTop: '0.5rem', fontSize: '0.875rem', fontStyle: 'italic' }}>
          — Faustino Pinto
        </Typography>
          </DeveloperBox>
        </ContentBox>

        {/* Botão para o Dashboard */}
        {/* <DashboardButton 
          variant="contained" 
          color="primary"
          onClick={goToDashboard}
        >
          Ir para o Dashboard
        </DashboardButton> */}
      </StyledContainer>
    </>
  );
};

export default Welcome;
