import React, { useState, useEffect } from "react";
import {
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import styled, { createGlobalStyle } from "styled-components";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";

import { auth, db } from '../firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f7f7f7;
    color: #333;
  }
`;

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // Azul padrão
    },
    secondary: {
      main: "#dc004e", // Vermelho padrão
    },
    mode: "light", // Pode ser 'dark' para tema escuro
  },
});


const Container = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const FilterContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Alinha os filtros à direita */
  @media (max-width: 600px) {
    align-items: center; /* Centraliza para dispositivos móveis */
  }
`;


const QuizTitle = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 20px !important;
  color: #00509E !important;
`;

const ChartContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 40px;
  height: 500px; /* Aumentando a altura do gráfico */
  @media (max-width: 600px) {
    padding: 10px;
    height: 400px; /* Ajuste para dispositivos móveis */
  }
`;

const CardWrapper = styled(Card)`
  margin-bottom: 15px !important;
  width: 100% !important;
  max-width: 800px !important;
  border: 1px solid #ddd !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  background-color: ${({ isIncorrect }) => (isIncorrect ? "#f8d7da" : "#fff")} !important; /* Vermelho para erradas */
  @media (max-width: 600px) {
    margin-bottom: 10px !important;
  }
`;

const MeusJogos = () => {
  const [jogos, setJogos] = useState([]);
  const [respostasPorTema, setRespostasPorTema] = useState([]);
  const [filtro, setFiltro] = useState("ultima semana");
  const [filtroGrafico, setFiltroGrafico] = useState("ultima semana");
  const [melhorTema, setMelhorTema] = useState(null);
  const [piorTema, setPiorTema] = useState(null);
  const [hasPurchased, setHasPurchased] = useState(null);

  

  const navigate = useNavigate();

  

  const fetchPurchaseStatus = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (userId) {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          setHasPurchased(userDoc.data().hasPurchased); // Verifica o campo correto 'hasPurchased'
        } else {
          setHasPurchased(false); // Se o documento do usuário não existir
        }
      } else {
        setHasPurchased(false); // Se o usuário não estiver logado
      }
    } catch (error) {
      console.error('Erro ao buscar o status de compra: ', error);
      setHasPurchased(false); // Em caso de erro, considera que não tem compra
    }
  };

  useEffect(() => {
    fetchPurchaseStatus();
  }, []);

  useEffect(() => {
    const fetchRespostasPorTema = async () => {
      try {
        const userId = auth.currentUser?.uid; // Substituir pelo ID do usuário real
        const dias =
          filtroGrafico === "hoje"
            ? 1
            : filtroGrafico === "ultima semana"
            ? 7
            : filtroGrafico === "ultimo mes"
            ? 30
            : filtroGrafico === "ultimo ano"
            ? 365
            : 0;

        const url = `https://api.euqueroentrar.pt:7709/api/quiz/respostas_por_tema?userId=${userId}&dias=${dias}`;
        const response = await axios.get(url);

        if (response.status === 200) {
          setRespostasPorTema(response.data);

          // Determinar melhor e pior tema por média
          if (response.data.length > 0) {
            const temasComMedias = response.data.map((tema) => ({
              ...tema,
              mediaCertas:
                tema.respostasCertas / (tema.respostasCertas + tema.respostasErradas || 1),
            }));

            const melhor = temasComMedias.reduce((prev, curr) =>
              curr.mediaCertas > prev.mediaCertas ? curr : prev
            );

            const pior = temasComMedias.reduce((prev, curr) =>
              curr.mediaCertas < prev.mediaCertas ? curr : prev
            );

            setMelhorTema(melhor);
            setPiorTema(pior);
          } else {
            setMelhorTema(null);
            setPiorTema(null);
          }
        } else {
          console.error("Erro ao carregar respostas por tema: Dados inválidos.");
        }
      } catch (error) {
        console.error("Erro ao carregar respostas por tema:", error);
      }
    };

    fetchRespostasPorTema();
  }, [filtroGrafico]);

  useEffect(() => {
    const fetchJogos = async () => {
      try {
        const userId = auth.currentUser?.uid; // Substituir pelo ID do usuário real
        const dias =
          filtro === "hoje"
            ? 1
            : filtro === "ultima semana"
            ? 7
            : filtro === "ultimo mes"
            ? 30
            : filtro === "ultimo ano"
            ? 365
            : 0;

        const url = `https://api.euqueroentrar.pt:7709/api/quiz/jogos_usuario?userId=${userId}&dias=${dias}`;
        const response = await axios.get(url);

        if (response.status === 200) {
          setJogos(response.data);
        } else {
          console.error("Erro ao carregar jogos:", response);
        }
      } catch (error) {
        console.error("Erro ao carregar jogos:", error);
      }
    };

    fetchJogos();
  }, [filtro]);

  const chartData = {
    labels: respostasPorTema.map((tema) => tema.temaNome),
    datasets: [
      {
        label: "Respostas Corretas",
        data: respostasPorTema.map((tema) => tema.respostasCertas),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Respostas Erradas",
        data: respostasPorTema.map((tema) => tema.respostasErradas),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    if (hasPurchased === false) {
      const timeout = setTimeout(() => {
        navigate('/purchase'); // Redireciona para a página de compra após 2 segundos
      }, 2000);
  
      return () => clearTimeout(timeout); // Limpa o timeout se o componente for desmontado
    }
  }, [hasPurchased, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
            
            <Container>
            <QuizTitle variant="h3">Estatísticas</QuizTitle>
            <QuizTitle variant="h5">por Tema</QuizTitle>

        <FilterContainer>
          {/* <Typography variant="body1" gutterBottom>
            Filtrar gráfico por período:
          </Typography> */}
          <Select
            value={filtroGrafico}
            onChange={(e) => setFiltroGrafico(e.target.value)}
            displayEmpty
          >
            <MenuItem value="hoje">Hoje</MenuItem>
            <MenuItem value="ultima semana">Última Semana</MenuItem>
            <MenuItem value="ultimo mes">Último Mês</MenuItem>
            <MenuItem value="ultimo ano">Último Ano</MenuItem>
            <MenuItem value="sempre">Sempre</MenuItem>
          </Select>
        </FilterContainer>

        {melhorTema && piorTema ? (
          <Typography variant="body1" gutterBottom>
            <strong>Melhor Tema:</strong> {melhorTema.temaNome} com média de <span style={{ color: 'green' }}>{Math.round(melhorTema.mediaCertas * 100)}% de respostas certas.</span><br /><br />

            <strong>Pior Tema:</strong> {piorTema.temaNome} com média de <span style={{ color: 'red' }}>{Math.round(piorTema.mediaCertas * 100)}% de respostas certas.</span><br /><br />
          </Typography>
        ) : (
          <Typography variant="body1" gutterBottom>
            Nenhum dado disponível para o período selecionado.
          </Typography>
        )}

        <ChartContainer>
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                title: {
                  display: true,
                  text: "Respostas por Tema",
                },
                legend: {
                  position: "top",
                },
              },
            }}
          />
        </ChartContainer>

        <Typography variant="h4" gutterBottom>
          Meus Jogos
        </Typography>

        {/* Combobox para filtros */}
        <FilterContainer>
          {/* <Typography variant="body1" gutterBottom>
            Filtrar jogos por período:
          </Typography> */}
          <Select
            value={filtro}
            onChange={(e) => setFiltro(e.target.value)}
            displayEmpty
          >
            <MenuItem value="hoje">Hoje</MenuItem>
            <MenuItem value="ultima semana">Última Semana</MenuItem>
            <MenuItem value="ultimo mes">Último Mês</MenuItem>
            <MenuItem value="ultimo ano">Último Ano</MenuItem>
            <MenuItem value="sempre">Sempre</MenuItem>
          </Select>
        </FilterContainer>

        {/* Listagem dos jogos */}
        <div>
          {jogos.length > 0 ? (
            jogos.reduce((acc, jogo) => {
              // Agrupar perguntas por jogoId
              const existingGroup = acc.find(
                (group) => group.jogoId === jogo.jogoId
              );

              if (existingGroup) {
                existingGroup.perguntas.push(jogo);
              } else {
                acc.push({
                  jogoId: jogo.jogoId,
                  dataHoraJogo: jogo.dataHoraJogo,
                  perguntas: [jogo],
                });
              }

              return acc;
            }, []).map((group, index) => (
              <Accordion
                key={index}
                style={{ width: "100%", maxWidth: "800px" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography>
  {new Date(group.dataHoraJogo).toLocaleString('pt-PT')} {/* Formato dd/mm/aaaa hh:mm:ss */}
</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {group.perguntas.map((pergunta, idx) => (
                    <CardWrapper
                      key={idx}
                      isIncorrect={
                        pergunta.respostaAssinalada !== pergunta.respostaCerta
                      }
                    >
                      <CardContent>
                        <Typography variant="body1">
                          <strong>Pergunta:</strong> {pergunta.perguntaTexto}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Opções:</strong>
                          <ul>
                            {[
                              "resposta1",
                              "resposta2",
                              "resposta3",
                              "resposta4",
                            ].map((respostaKey, optionIdx) => (
                              <li key={optionIdx}>
                                {String.fromCharCode(97 + optionIdx)}) {pergunta[respostaKey]}
                                {pergunta.respostaAssinalada === optionIdx &&
                                  (pergunta.respostaCerta === optionIdx
                                    ? " ✅"
                                    : " ❌")}
                              </li>
                            ))}
                          </ul>
                        </Typography>
                        <Typography variant="body2">
                          <strong>Resposta Assinalada:</strong> {pergunta[`resposta${pergunta.respostaAssinalada + 1}`] || "Não respondida"}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Resposta Certa:</strong> {pergunta[`resposta${pergunta.respostaCerta + 1}`] || "Não disponível"}
                        </Typography>
                      </CardContent>
                    </CardWrapper>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Typography variant="body1">
              Nenhum jogo encontrado para o período selecionado.
            </Typography>
          )}
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default MeusJogos;
