

import { Topic } from '..'

export const psp_estatutos_2: Topic = {
  topic: 'Estatutos - PSP (2)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Relativamente ao período de férias acresce um dia útil de férias por cada quantos anos de serviço efetivamente prestado?",
      "choices": [
        "5",
        "10",
        "15",
        "20"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "10"
      ],
      "score": 10
    },
    {
      "question": "Quem pode procede à alteração do período de férias por conveniência de serviço?",
      "choices": [
        "Diretor Nacional",
        "Inspetor nacional",
        "Membro do governo responsável pela área da Administração interna",
        "Diretores nacionais adjuntos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Diretor Nacional"
      ],
      "score": 10
    },
    {
      "question": "Que tipo de faltas existem?",
      "choices": [
        "Justificadas e não justificadas",
        "Justificadas e injustificadas",
        "Justificadas",
        "Não justificadas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Justificadas e injustificadas"
      ],
      "score": 10
    },
    {
      "question": "Com que antecedência é comunicada uma falta?",
      "choices": [
        "2 dias úteis",
        "2 dias",
        "5 dias úteis",
        "5 dias"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "5 dias úteis"
      ],
      "score": 10
    },
    {
      "question": "De quem é a competência para a concessão da Licença sem remuneração de curta duração?",
      "choices": [
        "Diretor Nacional",
        "Membro do governo responsável pela área da Administração interna",
        "Comandante da UEP",
        "Diretores nacionais adjuntos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Diretor Nacional"
      ],
      "score": 10
    },
    {
      "question": "De quem é a competência para a concessão da Licença sem remuneração de longa duração?",
      "choices": [
        "Diretor Nacional",
        "Membro do governo responsável pela área da Administração interna",
        "Comandante da UEP",
        "Diretores nacionais adjuntos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Membro do governo responsável pela área da Administração interna"
      ],
      "score": 10
    },
    {
      "question": "Por quanto tempo máximo é concedida a licença sem remuneração de curta duração?",
      "choices": [
        "1 mês",
        "6 meses",
        "1 ano",
        "2 anos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "1 ano"
      ],
      "score": 10
    },
    {
      "question": "Um agente pode pedir a concessão licença sem remuneração de longa duração após quantos anos de ingresso na PSP?",
      "choices": [
        "1 ano",
        "2 anos",
        "5 anos",
        "10 anos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "5 anos"
      ],
      "score": 10
    },
    {
      "question": "Um oficial pode pedir a concessão licença sem remuneração de longa duração após quantos anos de ingresso na PSP?",
      "choices": [
        "1 ano",
        "2 anos",
        "5 anos",
        "10 anos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "10 anos"
      ],
      "score": 10
    },
    {
      "question": "Carga horária semanal de um agente da PSP?",
      "choices": [
        "32 horas",
        "36 horas",
        "40 horas",
        "44 horas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "36 horas"
      ],
      "score": 10
    },
    {
      "question": "O ingresso na carreira de oficial de polícia faz se em que categoria?",
      "choices": [
        "Comissário",
        "Subcomissário",
        "Intendente",
        "Superintendente"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Subcomissário"
      ],
      "score": 10
    },
    {
      "question": "De quem é a competência os despachos da promoção?",
      "choices": [
        "Diretor Nacional",
        "Membro do governo responsável pela área da Administração interna",
        "Comandante da UEP",
        "Diretores nacionais adjuntos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Membro do governo responsável pela área da Administração interna"
      ],
      "score": 10
    },
    {
      "question": "Em que série são publicados os extratos dos despachos das promoções no DR?",
      "choices": [
        "1º",
        "2º",
        "3º",
        "4º"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "2º"
      ],
      "score": 10
    },
    {
      "question": "A carreira de oficial de polícia é uma carreira especial de complexidade funcional de que grau?",
      "choices": [
        "1",
        "2",
        "3",
        "4"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "3"
      ],
      "score": 10
    },
    {
      "question": "A promoção a comissário é feita mediante procedimento concursal, no qual é necessário no mínimo quantos anos de serviço efetivo na categoria de subcomissário?",
      "choices": [
        "1",
        "2",
        "4",
        "5"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "5"
      ],
      "score": 10
    },
    {
      "question": "A promoção a superintendente-chefe é feita mediante procedimento concursal, no qual é necessário no mínimo quantos anos de serviço efetivo na categoria de superintendente?",
      "choices": [
        "1",
        "2",
        "4",
        "5"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "4"
      ],
      "score": 10
    },
    {
      "question": "Em que promoção é necessário estar habilitado com o Curso de Direção e Estratégia Policial (CDEP)?",
      "choices": [
        "Superintendente",
        "Intendente",
        "Superintendente chefe",
        "Subintendente"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Superintendente"
      ],
      "score": 10
    },
    {
      "question": "A carreira de chefe de polícia é uma carreira especial de complexidade funcional de que grau?",
      "choices": [
        "1",
        "2",
        "3",
        "4"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "2"
      ],
      "score": 10
    },
    {
      "question": "A promoção a chefe principal é feita mediante procedimento concursal, no qual é necessário no mínimo quantos anos de serviço na categoria de chefe?",
      "choices": [
        "4",
        "5",
        "6",
        "8"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "8"
      ],
      "score": 10
    },
    {
      "question": "A promoção a agente coordenador é feita mediante procedimento concursal, no qual é necessário no mínimo quantos anos de serviço na categoria de agente principal?",
      "choices": [
        "6",
        "8",
        "12",
        "14"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "14"
      ],
      "score": 10
    }
    
    
  ],
}
