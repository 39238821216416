import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { CircularProgress, Typography, Alert } from "@mui/material";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  background-color: #f7f7f7;
  margin-top: 60px;
`;

const ResponsesContainer = styled.div`
  max-width: 800px;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
`;

const ThemeTitle = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
  color: #00509e;
  margin-top: 20px;
`;

const QuestionCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const QuestionText = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
`;

const OptionList = styled.div`
  margin-left: 20px;
`;

const OptionItem = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
  color: ${({ isCorrect }) => (isCorrect ? "green" : "#555")};
  font-weight: ${({ isCorrect }) => (isCorrect ? "bold" : "normal")};
`;

const QuizTitle = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 20px !important;
  color: #00509e !important;
`;

const ResponsePage = () => {
  const [searchParams] = useSearchParams();
  const [groupedResponses, setGroupedResponses] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const pdfId = searchParams.get("id");

  useEffect(() => {
    if (!pdfId) {
      setError("ID do PDF não especificado.");
      setLoading(false);
      return;
    }

    const fetchResponses = async () => {
      try {
        const response = await axios.get(
          `https://api.euqueroentrar.pt:7709/api/quiz/resposta`,
          {
            params: { id: pdfId },
          }
        );

        if (response.status === 200) {
          if (Array.isArray(response.data) && response.data.length > 0) {
            // Agrupar perguntas por temaNome
            const grouped = response.data.reduce((acc, item) => {
              const theme = item.temaNome || "Tema Desconhecido";
              if (!acc[theme]) acc[theme] = [];
              acc[theme].push(item);
              return acc;
            }, {});
            setGroupedResponses(grouped);
          } else {
            setError("Nenhuma resposta encontrada para este teste.");
          }
        } else {
          setError("Não foi possível carregar as respostas.");
        }
      } catch (err) {
        setError("Erro ao carregar as respostas.");
      } finally {
        setLoading(false);
      }
    };

    fetchResponses();
  }, [pdfId]);

  if (loading) {
    return (
      <PageWrapper>
        <CircularProgress />
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Carregando as respostas...
        </Typography>
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper>
        <Alert severity="error">{error}</Alert>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <QuizTitle variant="h3">Correção</QuizTitle>

      <ResponsesContainer>
        {Object.keys(groupedResponses).map((theme) => (
          <div key={theme}>
            <ThemeTitle>{theme}</ThemeTitle>
            {groupedResponses[theme].map((response, index) => (
              <QuestionCard key={response.perguntaId}>
                <QuestionText>{`${index + 1}. ${response.perguntaTexto}`}</QuestionText>

                <OptionList>
                  {[response.resposta1, response.resposta2, response.resposta3, response.resposta4]
                    .filter(Boolean)
                    .map((resposta, optIndex) => (
                      <OptionItem
                        key={`${response.perguntaId}-${optIndex}`}
                        isCorrect={optIndex === response.respostaCerta}
                      >
                        {`${String.fromCharCode(65 + optIndex)}) ${resposta}`}
                      </OptionItem>
                    ))}
                </OptionList>
              </QuestionCard>
            ))}
          </div>
        ))}
      </ResponsesContainer>
    </PageWrapper>
  );
};

export default ResponsePage;
