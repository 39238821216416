import React, { useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';

import Main from './components/Main';
import NavBar from './components/NavBar';
import QuizProvider from './context/QuizContext';
import { GlobalStyles } from './styles/Global';
import { themes } from './styles/Theme';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './context/PrivateRoute';
import Login from './components/Login';
import Profile from './components/Profile';
import Purchase from './components/Purchase';
import Register from './components/Register';
import Psp from './components/pages/psp';
import Gnr from './components/pages/gnr';
import Changelog from './components/pages/changelog';
import Audio_gnr_leiOrganica from './components/pages/audio_gnr_leiOrganica';
import Audio_gnr_estatutos from './components/pages/audio_gnr_estatutos';
import TrainingPlanPage from './components/pages/planoTreino';
import Mbway from './components/mbway';
import Welcome from './components/welcome';
import Maintenance from './components/Maintenance';
import ResetPassword from './components/ResetPassword';
import ConsultaPersonalTrainer from './components/ConsultaPersonalTrainer';
import ObrigadoCompraPT from './components/ObrigadoCompraPT';
import { usePageTracking } from './components/usePageTracking';

import MemoryGame from './components/memoryGame';
import MapMemory from './components/mapMemoryGame';

import Audio_psp_estatutos from './components/pages/audio_psp_estatutos';
import Audio_psp_leiOrganica from './components/pages/audio_psp_leiOrganica';

import Download from './components/download';
import DownloadWindows from './components/download_windows';
import DownloadAndroid from './components/download_android';
import DownloadIos from './components/download_ios';
import Gestao from './components/pages/gestao';

import ProvasPage from './components/provas';

import TestimonialsPage from './components/Testemunhos';

import TawkToScript from './components/TawkToScript';

import NewPSP from './components/New_psp';
import PspMenu from './components/psp_menu';
import Audios_psp from './components/pages/audios_psp';

import SuggestionsPage from './components/sugestao';
import ResponsePage from './components/Testes_PDF_respostas';

import MeusJogos from './components/OsMeusJogos';

function App() {
  // usePageTracking();

  const [currentTheme, setCurrentTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme || 'light';
  });

  const toggleTheme = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setCurrentTheme(checked ? 'dark' : 'light');
    localStorage.setItem('theme', checked ? 'dark' : 'light');
  };

  const theme = currentTheme === 'light' ? themes.light : themes.dark;
  const muiTheme = createTheme({
    palette: {
      mode: currentTheme as 'light' | 'dark',
    },
  });

  const isMaintenanceMode = false; // Altere para true para exibir a página de manutenção

  if (isMaintenanceMode) {
    return <Maintenance />;
    
  }

  return (
    <StyledThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        <GlobalStyles />
        <AuthProvider>
          <QuizProvider>
            <Router>
              <AppContent toggleTheme={toggleTheme} currentTheme={currentTheme} />
            </Router>
          </QuizProvider>
        </AuthProvider>
      </MuiThemeProvider>
    </StyledThemeProvider>
  );
}

function AppContent({ toggleTheme, currentTheme }: { toggleTheme: any; currentTheme: string }) {
  usePageTracking(); // Agora está dentro do Router
  const location = useLocation(); // Agora está dentro do Router

  const hideNavBarRoutes = ['/login', '/register', '/reset-password'];

  return (
    <>
      {!hideNavBarRoutes.includes(location.pathname) && (
        <>
        <NavBar toggleTheme={toggleTheme} currentTheme={currentTheme} handlePurchaseNavigation={() => {}} />
        {/* <TawkToScript /> */}
        </>
      )}
      
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/testes_em_pdf/resposta" element={<ResponsePage />} />

        <Route
          path="/gestao"
          element={
            <PrivateRoute>
              <Gestao />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/jogomemoria"
          element={
            <PrivateRoute>
              <MemoryGame />
            </PrivateRoute>
          }
        />
        <Route
          path="/jogomemoriamapa"
          element={
            <PrivateRoute>
              <MapMemory />
            </PrivateRoute>
          }
        />
        <Route
          path="/provas"
          element={
            <PrivateRoute>
              <ProvasPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/provas/psp"
          element={
            <PrivateRoute>
              <PspMenu />
            </PrivateRoute>
          }
        />
        <Route
          path="/provas/psp/audios"
          element={
            <PrivateRoute>
              <Audios_psp />
            </PrivateRoute>
          }
        />
        <Route
          path="/welcome"
          element={
            <PrivateRoute>
              <Welcome />
            </PrivateRoute>
          }
        />
        <Route
          path="/consulta"
          element={
            <PrivateRoute>
              <ConsultaPersonalTrainer />
            </PrivateRoute>
          }
        />
        <Route
          path="/obrigado"
          element={
            <PrivateRoute>
              <ObrigadoCompraPT />
            </PrivateRoute>
          }
        />
         <Route
          path="/download"
          element={
            <PrivateRoute>
              <Download />
            </PrivateRoute>
          }
        />
        <Route
          path="/downloadwindows"
          element={
            <PrivateRoute>
              <DownloadWindows />
            </PrivateRoute>
          }
        />
        <Route
          path="/downloadandroid"
          element={
            <PrivateRoute>
              <DownloadAndroid />
            </PrivateRoute>
          }
        />
        <Route
        path="/downloadios"
        element={
          <PrivateRoute>
            <DownloadIos />
          </PrivateRoute>
        }
      />
        <Route
          path="/purchase"
          element={
            <PrivateRoute>
              <Purchase />
            </PrivateRoute>
          }
        />
        <Route
          path="/purchase/Mbway"
          element={
            <PrivateRoute>
              <Mbway />
            </PrivateRoute>
          }
        />
        <Route
          path="/provas/psp/provaspsp"
          element={
            <PrivateRoute>
              <Psp />
            </PrivateRoute>
          }
        />
        <Route
          path="/provas/psp/provaspsp/provacustomizada"
          element={
            <PrivateRoute>
              <NewPSP />
            </PrivateRoute>
          }
        />
        <Route
          path="/gnr"
          element={
            <PrivateRoute>
              <Gnr />
            </PrivateRoute>
          }
        />
        <Route
          path="/changelog"
          element={
            <PrivateRoute>
              <Changelog />
            </PrivateRoute>
          }
        />
        <Route
          path="/audio_gnr_leiOrganica"
          element={
            <PrivateRoute>
              <Audio_gnr_leiOrganica />
            </PrivateRoute>
          }
        />
        <Route
          path="/audio_gnr_estatutos"
          element={
            <PrivateRoute>
              <Audio_gnr_estatutos />
            </PrivateRoute>
          }
        />
        <Route
          path="/audio_psp_estatutos"
          element={
            <PrivateRoute>
              <Audio_psp_estatutos />
            </PrivateRoute>
          }
        />
        <Route
          path="/audio_psp_leiOrganica"
          element={
            <PrivateRoute>
              <Audio_psp_leiOrganica />
            </PrivateRoute>
          }
        />
        <Route
          path="/planodetreino"
          element={
            <PrivateRoute>
              <TrainingPlanPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/testemunhos"
          element={
            <PrivateRoute>
              <TestimonialsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/sugestoes"
          element={
            <PrivateRoute>
              <SuggestionsPage />
            </PrivateRoute>
          }
        />
        <Route
        path="/profile/estatisticas"
        element={
          <PrivateRoute>
            <MeusJogos />
          </PrivateRoute>
        }
      />
        <Route
        path="/testes_em_pdf/resposta"
        element={
          <PrivateRoute>
            <ResponsePage />
          </PrivateRoute>
        }
      />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Main currentTheme={currentTheme} toggleTheme={toggleTheme} />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}

export default App;
