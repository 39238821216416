import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Container, Typography } from '@mui/material';
import Step1 from '../assets/images/step1_ios.jpg'; // Substitua pelo caminho correto da imagem do passo 1
import Step2 from '../assets/images/step2_ios.jpg'; // Substitua pelo caminho correto da imagem do passo 2

// Estilo Global
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background: #00509E;
    color: #333;
  }
`;

// Wrapper principal para a página
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 80px;
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding-top: 100px;
  }
`;

// Container principal estilizado
const StyledContainer = styled(Container)`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  max-width: 800px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    width: 90%;
    margin-top: 10%;
  }
`;

// Título principal
const PageTitle = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 30px !important;
  color: #00509E !important;
`;

// Seção de instruções
const InstructionBox = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 40px;

  @media (max-width: 600px) {
    text-align: justify;
  }
`;

// Imagens de Passos
const StepImage = styled.img`
  width: 100%;
  max-width: 500px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const DownloadAndroid = () => {
  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        <StyledContainer>
          <PageTitle variant="h4" gutterBottom>
            Instale o EuQueroEntrar no Android
          </PageTitle>

          <InstructionBox>
            <Typography variant="body1" gutterBottom>
              A aplicação <strong>EuQueroEntrar</strong> pode ser instalada diretamente no seu telemóvel/tablet como uma aplicação web. Siga os passos abaixo para instalar:
            </Typography>

            {/* Passo 1 */}
            <Typography variant="h6" gutterBottom>
              Passo 1: Clique no ícone da barra de URL
            </Typography>
            <StepImage src={Step1} alt="Passo 1: Clique no botão de instalação na barra de URL" />

            {/* Passo 2 */}
            <Typography variant="h6" gutterBottom>
            Passo 2: Adicionar ao ecrã principal
            </Typography>
            <StepImage src={Step2} alt="Passo 2: Adicionar ao ecrã principal" />
          </InstructionBox>
        </StyledContainer>
      </PageWrapper>
    </>
  );
};

export default DownloadAndroid;
