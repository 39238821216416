import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PSPImage from '../assets/images/provas.jpg'; // Substitua pelo caminho correto da imagem PSP
import GNRImage from '../assets/images/ouvir.jpg'; // Substitua pelo caminho correto da imagem GNR

// Estilo Global
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background: #00509E;
    color: #333;
  }
`;

// Wrapper principal para a página
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 80px;
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding-top: 100px;
  }
`;

// Container principal estilizado
const StyledContainer = styled(Container)`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 40px;
  text-align: center;
  max-width: 900px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    width: 90%;
    margin-top: 10%;
  }
`;

// Título principal
const PageTitle = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 30px !important;
  color: #00509E;
`;

// Botões estilizados para links com imagens
const LinkButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 2px solid #00509E;
  border-radius: 12px;
  padding: 20px;
  margin: 20px;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  span {
    font-size: 1.2rem;
    font-weight: bold;
    color: #00509E;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

// Caixa de botão para layout
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

const PspMenu = () => {
  const navigate = useNavigate();

  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        <StyledContainer>
          <PageTitle variant="h4" gutterBottom>
            Escolha o Tipo de Conteúdo
          </PageTitle>

          <Typography variant="body1" gutterBottom>
            Selecione abaixo para <strong>Treinar Provas</strong> ou <strong>Ouvir Matéria</strong>
          </Typography>

          <ButtonContainer>
            <LinkButton onClick={() => navigate('/provas/psp/provaspsp')}>
              <img src={PSPImage} alt="Provas PSP" />
              <span>Provas</span>
            </LinkButton>

            <LinkButton onClick={() => navigate('/provas/psp/audios')}>
              <img src={GNRImage} alt="Provas GNR" />
              <span>Ouvir Matéria</span>
            </LinkButton>
          </ButtonContainer>
        </StyledContainer>
      </PageWrapper>
    </>
  );
};

export default PspMenu;
