

import { Topic } from '..'

export const psp_leiOrganica_7: Topic = {
  topic: 'Lei Orgânica - PSP (7)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Os polícias nomeados para missões internacionais por períodos superiores a 180 dias ficam colocados administrativamente na Direção Nacional.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "As normas de execução e de operacionalização dos instrumentos de mobilidade interna são aprovadas por despacho do membro do Governo responsável pela área da administração interna.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "O recrutamento para as polícias municipais de Lisboa e do Porto é autorizado pelo membro do Governo responsável pela área da administração interna.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias podem encontrar-se numa das seguintes situações: Em formação; Ativo; Pré-aposentação; Aposentação.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Os polícias na situação de ativo podem encontrar-se na efetividade de serviço ou fora da efetividade de serviço.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os atos processuais de investigação criminal e de coadjuvação das autoridades judiciárias não estão sujeitos a segredo de justiça, nos termos da lei.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Os polícias na efetividade de serviço ocupam os postos de trabalho não previstos no mapa de pessoal da PSP.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Os polícias na situação de adido não são contados no efetivo do mapa de pessoal da PSP.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os limites máximos de idade da passagem à situação de pré-aposentação para os polícias são os seguintes: Superintendente-chefe - 62 anos; Restantes categorias e carreiras - 60 anos.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "O ensino ministrado em estabelecimentos policiais de ensino tem como finalidade a habilitação profissional dos polícias, a aprendizagem de conhecimentos adequados à evolução da ciência e da tecnologia, bem como ao seu desenvolvimento cultural.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias são obrigados a frequentar, anualmente, no mínimo, 25 horas de formação policial ou de outras formações reconhecidas como de interesse para as competências da PSP.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "A avaliação individual é obrigatória e contínua, abrangendo todos os polícias na efetividade de serviço.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Na PSP, as avaliações individuais podem ser: Periódicas; Extraordinárias.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias que trabalhem em dia feriado obrigatório têm direito a dois dias de descanso compensatório com duração igual a metade do número de horas prestadas previsto no n.º 2 do artigo 165.º da Lei Geral do Trabalho em Funções Públicas, aprovada pela Lei n.º 35/2014, de 20 de junho, alterada pela Lei n.º 82-B/2014, de 31 de dezembro.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Aos polícias podem ser atribuídos prémios de desempenho nos termos previstos para os demais trabalhadores em funções públicas.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias e seus familiares têm direito a ação social complementar, através dos Serviços Sociais da PSP, nos termos previstos em lei especial.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "O regime de admissão e progressão da carreira do pessoal músico da PSP é objeto de regulamentação própria a fixar por portaria do membro do Governo responsável pela área da administração interna.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "O regime do formador policial e a certificação da formação policial são regulados por despacho do diretor nacional.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "A avaliação individual é facultativa, mas abrangendo todos os polícias na efetividade de serviço.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "O regime das ajudas de custo dos polícias é regulado pela lei.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    }
  ],
}
