

import { Topic } from '..'

export const psp_estatutos_4: Topic = {
  topic: 'Estatutos - PSP (4)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "Aos polícias, quando devidamente identificados e em missão de serviço, é facultada a entrada livre em estabelecimentos e outros locais públicos ou abertos ao público para a realização de ações policias.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Aos polícias, quando devidamente identificados e em missão de serviço, é facultado o livre acesso, em todo o território nacional, aos transportes coletivos terrestres, fluviais e marítimos.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias têm direito à utilização gratuita dos transportes coletivos terrestres, fluviais e marítimos nas deslocações dentro da área de circunscrição em que exercem funções e entre a sua residência habitual e a localidade em que prestam serviço, até à distância de 50 km.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias têm direito à escusa do uso de documento de identificação e, quando nas situações de ativo e de pré-aposentação na efetividade de serviço, de carteira de identificação policial.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Os polícias têm direito à dotação inicial do fardamento, aprovado por portaria do membro do Governo responsável pela área da administração interna, no momento do ingresso na PSP.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "A PSP não participa nas despesas com a aquisição de fardamento efetuadas pelos polícias na efetividade de serviço.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Os polícias têm direito ao uso e porte de armas e munições de qualquer tipo, desde que distribuídas pelo Estado, e estão sujeitos a um plano de formação e de certificação constituído por provas teóricas e práticas de tiro.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Aos polícias que intervenham em processo penal por ofensa sofrida no exercício das suas funções, ou por causa delas, é concedido apoio jurídico.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Incapacidade física - É aplicável aos polícias o regime legal em vigor para os deficientes das Forças Armadas.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Na PSP, o período anual de férias tem a duração de 25 dias úteis.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Tipos de faltas - As faltas podem ser justificadas ou injustificadas.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias podem faltar dois dias por mês por conta do período de férias, até ao máximo de 15 dias por ano.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "As modalidades de licenças sem remuneração compreendem, entre outras: Licença sem remuneração de curta duração; Licença sem remuneração de média duração; Licença sem remuneração de longa duração.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "A licença sem remuneração de curta duração é concedida por período igual ou superior a 30 dias e inferior a um ano.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "O diretor nacional não pode recusar a concessão das licenças (sem remuneração).",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "A licença sem remuneração de longa duração é concedida por períodos de um ano até cinco anos.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "O serviço da PSP é de carácter permanente e obrigatório.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias podem recusar-se, sem motivo justificado, a comparecer no seu posto de trabalho ou a nele permanecer para além do período normal de trabalho e eximir-se a desempenhar qualquer missão de serviço.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "O período normal de trabalho é de 36 horas, nele se incluem os períodos destinados a atividades complementares de aperfeiçoamento técnico-policial, designadamente ações de formação e treino.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "Ao diretor nacional compete autorizar os serviços de piquete.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    }
  ],
}
