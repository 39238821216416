import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { AppLogo } from '../../config/icons';
import { PageCenter } from '../../styles/Global';
import Welcome from '../welcome';

interface LogoAnimationProps {
  logoSize: string;
}

const LogoAnimation = styled.div<LogoAnimationProps>`
  svg {
    width: ${({ logoSize }) => logoSize};
    transition: width 1s;
  }
`;

const frase = () => {
  const frases = [
    "Supera-te",
    "Desafia-te",
    "Domina o desafio",
    "Vence os teus limites",
    "Disciplina gera resultados",
    "Faz a diferença",
    "Pronto para o desafio",
    "Agarra a tua oportunidade",
    "A força da disciplina",
    "Faz acontecer",
    "Na linha da frente",
    "Atitude vencedora",
    "Determinação sem fim",
    "Conquista o teu lugar",
    "Treina duro, vence fácil",
    "Sempre um passo à frente",
    "A vitória começa aqui",

  ];

  

  const fraseAleatoria = frases[Math.floor(Math.random() * frases.length)];
  return fraseAleatoria;
};

const SplashScreen = () => {
  const [logoSize, setLogoSize] = useState('80px');
  const [fraseAtual, setFraseAtual] = useState('');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setLogoSize('240px');
      } else {
        setLogoSize('350px');
      }
    };

    // Set initial logo size
    handleResize();

    // Set initial phrase
    setFraseAtual(frase());

    // Update logo size on window resize
    window.addEventListener('resize', handleResize);

    

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const HighlightedTextsfp = styled.h1`
    /* color: #095802; Verde tropa */
    font-weight: bold;
    color: ${({ theme }) => theme.colors.themeText};
    `;

  return (
    <>
      <PageCenter justifyCenter>
        
      {/* <HighlightedTextsfp>{fraseAtual}</HighlightedTextsfp> */}
      <Welcome />
      
      </PageCenter>
    </>
  );
};

export default SplashScreen;
