import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Typography, Button, TextField } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const API_BASE_URL = "https://api.euqueroentrar.pt:7709/api";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background: #f7f7f7;
    color: #333;
    margin-top: 30px;
    background-color: #00509E;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px);
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const StyledContainer = styled.div`
  margin-top: 50px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const Title = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 20px !important;
  color: #00509E !important;
`;

const Paragraph = styled(Typography)`
  line-height: 1.6 !important;
  margin-bottom: 10px !important;
  text-align: left;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SuggestionsPage = () => {
  const [suggestion, setSuggestion] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const navigate = useNavigate();


  const handleSubmit = async () => {
    if (suggestion.trim() === "") {
      setStatusMessage("Por favor, insira uma sugestão.");
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/quiz/sugestoes`, suggestion, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 200) {
        setStatusMessage("Sugestão enviada com sucesso! Obrigado pelo feedback.");
        alert("Sugestão enviada com sucesso! Obrigado pelo feedback.");
        setSuggestion("");
        navigate("/");
      }
    } catch (error) {
      console.error("Erro ao enviar sugestão:", error);
      setStatusMessage("Ocorreu um erro ao enviar sua sugestão. Tente novamente mais tarde.");
    }
  };

  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        <StyledContainer>
          <Title variant="h3">Caixa de Sugestões</Title>
          <Paragraph>
            📬 Aqui podes enviar sugestões e compartilhar a tua opinião sobre como podemos melhorar.
          </Paragraph>

          <TextField
            label="Digite sua sugestão"
            multiline
            rows={4}
            value={suggestion}
            onChange={(e) => setSuggestion(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />

          {statusMessage && (
            <Typography
              variant="body2"
              style={{
                color: statusMessage.includes("sucesso") ? "green" : "red",
                marginTop: "10px",
              }}
            >
              {statusMessage}
            </Typography>
          )}

          <ButtonContainer>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                backgroundColor: "#00509E",
                "&:hover": {
                  backgroundColor: "#003f7f",
                },
              }}
            >
              Enviar Sugestão
            </Button>
          </ButtonContainer>
        </StyledContainer>
      </PageWrapper>
    </>
  );
};

export default SuggestionsPage;
