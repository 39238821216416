import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from './firebaseConfig';

const updatePurchaseStatus = async (userId) => {
  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      hasPurchased: true,
      purchaseDate: serverTimestamp()
    });
    console.log("User purchase status updated");
  } catch (error) {
    console.error("Error updating purchase status: ", error);
  }
};

export default updatePurchaseStatus;
