import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { Typography, Button } from "@mui/material";
import Stefan from '../assets/images/Stefan.png';
import { auth } from '../firebase/firebaseConfig';
import axios from 'axios';
import mbway from '../assets/images/mbway.png';


const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background: #00509E;
    color: #333;
  }
`;

const DashboardButton = styled(Button)`
  margin-top: 20px !important;
  border-radius: 6px !important;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px); /* Ajusta o espaço total excluindo a altura da navbar */
  padding: 20px;
  margin-top: 80px; /* Espaço suficiente para a navbar */
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const StyledContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  max-width: 800px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    width: 90%; /* Ajusta a largura no mobile */
  }
`;

const WelcomeTitle = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 20px !important;
`;

const Paragraph = styled(Typography)`
  line-height: 1.6 !important;
  margin-bottom: 20px !important;
  text-align: left;
`;

const DetailsBox = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
`;

const ImageContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;

  img {
    width: 200px; /* Ajustado para imagem maior */
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ddd;
  }
`;

const InputField = styled.input`
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;
const QuizTitle = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 20px !important;
  color: #00509E !important;
`;

const MbwayButton = styled(Button)`
  background-color: transparent !important;
  color: #000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 6px !important;
  font-weight: bold !important;
  background-image: url(${mbway});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 49px;
  width: 150px;
  cursor: default !important;
`;


const ConsultaPersonalTrainer = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [isFormValid, setIsFormValid] = useState(false); // Novo estado para controle do botão
  const navigate = useNavigate();

  // Função para verificar se todos os campos estão preenchidos
  const validateForm = () => {
    const isValid =
      formData.name.trim() !== "" &&
      formData.email.trim() !== "" &&
      formData.phone.trim() !== "";
    setIsFormValid(isValid);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    validateForm(); // Verificar se o formulário é válido após cada alteração
  };

  const handleSubmit = async () => {
    const googleFormData = new FormData();
    googleFormData.append("entry.129269917", formData.name);
    googleFormData.append("entry.181590449", formData.email);
    googleFormData.append("entry.441863496", formData.phone);

    try {
      const response = await fetch(
        "https://docs.google.com/forms/d/e/1FAIpQLSdwrcDoWYvvvL717354cCdPg7TuZGmdYFJ_RZmpW1G_qSuf8Q/formResponse",
        {
          method: "POST",
          body: googleFormData,
          mode: "no-cors",
        }
      );
      if (response) {
        alert("Formulário enviado com sucesso!");
        navigate("/obrigadoCompraPT");
      }
    } catch (error) {
      console.error("Erro ao enviar o formulário para o Google Forms: ", error);
      alert("Erro ao enviar o formulário. Tente novamente.");
    }
  };

  const handlePayPalApprove = async (data, actions) => {
    try {
      await actions.order.capture();
      alert("Pagamento realizado com sucesso! Enviando formulário...");
      handleSubmit();
    } catch (error) {
      console.error("Erro ao processar o pagamento: ", error);
      alert("Erro ao processar o pagamento. Por favor, tente novamente.");
    }
  };

  const handleMbwayPayment = async () => {
    if (!formData.phone) {
      alert('Por favor, insira um número de telefone válido.');
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      alert('Usuário não autenticado. Por favor, faça login.');
      navigate('/login');
      return;
    }

    const payload = {
      userId: formData.name + '-' + formData.email + '-' + formData.phone + '-' + user.uid,
      telemovel: formData.phone,
      nome: formData.name || 'Usuário',
      email: formData.email,
      valor: 20, 
    };

    setLoading(true);
    try {
      const response = await axios.post(
        'https://eqe-n33r864zg-picpintos-projects.vercel.app/api/mbway',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Resposta do Middleware:', response.data);
      alert('Pagamento MB WAY iniciado com sucesso. Verifique seu MB WAY.');
      navigate("/obrigado");
    } catch (error) {
      console.error('Erro ao processar o pagamento MB WAY:', error);
      alert('Erro ao iniciar o pagamento MB WAY. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        <StyledContainer>
        <QuizTitle variant="h3">Plano de Treino</QuizTitle>
        <QuizTitle variant="h5">Consulta online</QuizTitle>

          {/* <WelcomeTitle variant="h4"></WelcomeTitle> */}

          <ImageContainer>
            <img src={Stefan} alt="Personal Trainer" />
          </ImageContainer>

          <Paragraph>
            Olá! Sou Stefan Valente, Personal Trainer certificado com experiência em ajudar candidatos a alcançar os seus objetivos físicos para as <strong>provas das forças de segurança</strong>. Durante esta consulta, vamos trabalhar juntos para criar um <strong>plano de treino totalmente personalizado</strong> às tuas necessidades.
          </Paragraph>

          <Paragraph>
            A consulta será realizada online (Zoom ou Ferramenta equiparada) ou por chamada telefónica, dependendo da tua preferência. Após a consulta, receberás um <strong>plano de treino detalhado</strong> para te ajudar a alcançar os teus objetivos de forma eficaz e segura.
          </Paragraph>

          <DetailsBox>
            <Paragraph>
              <strong>Preço:</strong> 20€
              <br />
              <strong>Duração:</strong> 30 minutos
            </Paragraph>
            <Paragraph>
              
            </Paragraph>
          </DetailsBox>

          <Paragraph>
            Após o pagamento, serás contactado(a) nas próximas 48 horas por chamada telefónica para agendamento da tua consulta.
          </Paragraph>

          <form autoComplete="off">
  <InputField
    type="text"
    name="name"
    placeholder="Nome"
    value={formData.name}
    onChange={handleChange}
    required
    readOnly
    onFocus={(e) => e.target.removeAttribute("readOnly")}
  />
  <InputField
    type="email"
    name="email"
    placeholder="Email"
    value={formData.email}
    onChange={handleChange}
    required
    readOnly
    onFocus={(e) => e.target.removeAttribute("readOnly")}
  />
  <br/>
  <br/>
  <Paragraph style={{ textAlign: 'center' }}>
  Metodo de pagamento:
</Paragraph>
  <MbwayButton
          variant="contained"
          style={{ marginBottom: '20px' }}
        >
        </MbwayButton>
        <br/>
        <br/>
  <InputField
    type="tel"
    name="phone"
    placeholder="Nº de Telefone"
    value={formData.phone}
    onChange={handleChange}
    required
    readOnly
    onFocus={(e) => e.target.removeAttribute("readOnly")}
  />
</form>
<Button
            variant="contained"
            color="primary"
            onClick={handleMbwayPayment}
            style={{ marginTop: '20px' }}
            disabled={loading}
          >
            {loading ? 'Processando...' : 'Confirmar Pagamento'}
          </Button>


          {/* <DashboardButton 
          variant="contained" 
          color="primary"
          onClick={handleSubmit}
        >
          Ir para o Dashboard
        </DashboardButton>  */}
         {/* <PayPalScriptProvider
        options={{
          "client-id":
            "AeIo__Xzy2mK7oJ4bCNfF4xHXYIxzSrE5xkZGiii0PXv0UnOiuw0_m7zUP8EZhRVKpxC8VnTjOkaZq3T",
          currency: "EUR",
        }}
      >
        <PayPalButtons
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [{ amount: { value: "20" } }],
            });
          }}
          onApprove={handlePayPalApprove}
          onError={(err) => {
            console.error("Erro no pagamento: ", err);
            alert("Erro ao processar o pagamento. Por favor, tente novamente.");
          }}
          forceReRender={[isFormValid]} // Força o botão a ser re-renderizado com base na validade do formulário
          disabled={!isFormValid} // Desabilita o botão se o formulário for inválido
        />
      </PayPalScriptProvider> */}
        </StyledContainer>
      </PageWrapper>
    </>
  );
};

export default ConsultaPersonalTrainer;
