import { ReactNode } from 'react';
import { ReactComponent as Angular } from '../assets/icons/angular.svg';
import { ReactComponent as CSS } from '../assets/icons/css-3.svg';
import { ReactComponent as Django } from '../assets/icons/dj.svg';
import { ReactComponent as Gatsby } from '../assets/icons/gatsby.svg';
import { ReactComponent as JavaScript } from '../assets/icons/javascript.svg';
import { ReactComponent as Kotlin } from '../assets/icons/kotlin.svg';
import { ReactComponent as Laravel } from '../assets/icons/laravel.svg';
import { ReactComponent as Python } from '../assets/icons/python.svg';
import { ReactComponent as ReactIcon } from '../assets/icons/react.svg';
import { ReactComponent as BulbIcon } from '../assets/icons/bulb.svg';
import { ReactComponent as Premium} from '../assets/icons/premium.svg';
import { ReactComponent as Select} from '../assets/icons/1.svg';
import { ReactComponent as Play} from '../assets/icons/play.svg';
import { ReactComponent as Premium2} from '../assets/icons/premium2.svg';




import { auth, db } from '../firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

export type QuizTopic = {
  title: string;
  icon: ReactNode;
  disabled?: boolean;
};

const fetchPurchaseStatus = async () => {
  let hasPurchased = false;
  try {
    const userId = auth.currentUser?.uid;
    if (userId) {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        hasPurchased = userDoc.data().hasPurchased;
      }
    }
  } catch (error) {
    console.error('Error fetching purchase status: ', error);
  }
  return hasPurchased;
};

const getQuizzes = async (): Promise<{ geral_quiz: QuizTopic[]; psp_quiz_pc: QuizTopic[]; gnr_estatutos_quiz: QuizTopic[]; psp_estatutos_quiz: QuizTopic[]; gnr_leiOrganica_quiz: QuizTopic[]; psp_leiOrganica_quiz: QuizTopic[];geral_crp_dudh_ue_quiz: QuizTopic[]; geral_cg_quiz: QuizTopic[]; geral_lp_quiz: QuizTopic[]; gnr_provaCompleta_quiz: QuizTopic[]; matrizes_progressivas: QuizTopic[]; raciocinio_verbal: QuizTopic[];raciocinio_abstrato: QuizTopic[]; raciocinio_espacial: QuizTopic[];}> => {
  const hasPurchased = await fetchPurchaseStatus(); 

  let PremiumIcon


  if (hasPurchased){
    PremiumIcon = <Play />
  }
  else{
    PremiumIcon = <Premium2 />
  }

  const geral_quiz: QuizTopic[] = [
    // {
    //   title: 'Javascript',
    //   icon: PremiumIcon ,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'CRP',
    //   icon: PremiumIcon ,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'Cultura Geral',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    {
      title: 'DUDH',
      icon: <Play />,
    },

    //novo
    //geral
  ];
    const geral_cg_quiz: QuizTopic[] = [
    {
      title: 'Cultura Geral (1)',
      icon: <Play />
    },
    {
      title: 'Cultura Geral (2)',
      icon: <Play />
    },
    // {
    //   title: 'Cultura Geral (3)',
    //   icon: <Play />
    // },
    // {
    //   title: 'Cultura Geral (4)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'Cultura Geral (5)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'Cultura Geral (6)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },

    //TODO corrigir, report enviado por whatsapp, ambas as provas não abrem. Verificar data/*.tsx

    // {
    //   title: 'Cultura Geral (7)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'Cultura Geral (8)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // }


  ];

  const geral_crp_dudh_ue_quiz: QuizTopic[] = [
    {
      title: 'CRP, DUDH e UE (1)',
      icon: <Play />,
    },
    {
      title: 'CRP, DUDH e UE (2)',
      icon: <Play />,
    },
    // {
    //   title: 'CRP, DUDH e UE (3)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
  ];

  const geral_lp_quiz: QuizTopic[] = [
    {
      title: 'Língua Portugesa (1)',
      icon: <Play />,
    },
    {
      title: 'Língua Portugesa (2)',
      icon: <Play />,
    },
    // {
    //   title: 'Língua Portugesa (3)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'Língua Portugesa (4)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'Língua Portugesa (5)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
  ];


  // GNR 

  const gnr_estatutos_quiz: QuizTopic[] = [
    {
      title: 'GNR - Estatutos (1)',
      icon: <Play />,
    },
    {
      title: 'GNR - Estatutos (2)',
      icon: <Play />,
    },
    {
      title: 'GNR - Estatutos (3)',
      icon: <Play />,
    },
    // {
    //   title: 'GNR - Estatutos (4)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'GNR - Estatutos (5)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'GNR - Estatutos (6)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'GNR - Estatutos (7)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
  ]

  const gnr_leiOrganica_quiz: QuizTopic[] = [
    {
      title: 'GNR - Lei Orgânica (1)',
      icon: <Play />,
    },
    {
      title: 'GNR - Lei Orgânica (2)',
      icon: <Play />,
    },
    // {
    //   title: 'GNR - Lei Orgânica (3)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'GNR - Lei Orgânica (4)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'GNR - Lei Orgânica (5)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
  ]

  const gnr_provaCompleta_quiz: QuizTopic[] = [
    {
      title: 'GNR - Prova Completa (1)',
      icon: <Play />,
    },
    {
      title: 'GNR - Prova Completa (2)',
      icon: <Play />,
    },
    // {
    //   title: 'GNR - Prova Completa (3)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'GNR - Prova Completa (4)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'GNR - Prova Completa (5)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
  ]


  const psp_leiOrganica_quiz: QuizTopic[] = [
    {
      title: 'Lei Orgânica - PSP (1)',
      icon: <Play />,
    },
    {
      title: 'Lei Orgânica - PSP (2)',
      icon: <Play />,
    },
    // {
    //   title: 'Lei Orgânica - PSP (3)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'Lei Orgânica - PSP (4)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'Lei Orgânica - PSP (5)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'Lei Orgânica - PSP (6)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'Lei Orgânica - PSP (7)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // }
  ]

  const psp_estatutos_quiz: QuizTopic[] = [
    {
      title: 'Estatutos - PSP (1)',
      icon: <Play />,
    },
    {
      title: 'Estatutos - PSP (2)',
      icon: <Play />,
    },
    // {
    //   title: 'Estatutos - PSP (3)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'Estatutos - PSP (4)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'Estatutos - PSP (5)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'Estatutos - PSP (6)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'Estatutos - PSP (7)',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // }
  ]

  const psp_quiz_pc: QuizTopic[] = [
    {
      title: 'Teste Geral (1) - PSP',
      icon: <Play />
    },
    // {
    //   title: 'Teste Geral (2) - PSP',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // },
    // {
    //   title: 'Teste Geral (3) - PSP',
    //   icon: PremiumIcon,
    //   disabled: !hasPurchased,
    // }
  ];

  const matrizes_progressivas: QuizTopic[] = [
    {
      title: 'Matrizes Progressivas (1)',
      icon: <Play />,
    },
    {
      title: 'Matrizes Progressivas (2)',
      icon: <Play />,
    },
    {
      title: 'Matrizes Progressivas (3)',
      icon: <Play />,
    },
  ];

  const raciocinio_verbal: QuizTopic[] = [
    {
      title: 'Raciocínio Verbal (1)',
      icon: <Play />,
    },
    {
      title: 'Raciocínio Verbal (2)',
      icon: <Play />,
    },
    {
      title: 'Raciocínio Verbal (3)',
      icon: <Play />,
    },
  ];

  const raciocinio_abstrato: QuizTopic[] = [
    {
      title: 'Raciocínio Abstrato (1)',
      icon: <Play />,
    },
    {
      title: 'Raciocínio Abstrato (2)',
      icon: <Play />,
    }

  ];

  const raciocinio_espacial: QuizTopic[] = [
    {
      title: 'Raciocínio Espacial (1)',
      icon: <Play />,
    },
    {
      title: 'Raciocínio Espacial (2)',
      icon: <Play />,
    }


  ];

  return { geral_quiz, psp_quiz_pc, gnr_estatutos_quiz, psp_estatutos_quiz, gnr_leiOrganica_quiz, psp_leiOrganica_quiz,geral_crp_dudh_ue_quiz, geral_cg_quiz, geral_lp_quiz, gnr_provaCompleta_quiz, matrizes_progressivas, raciocinio_verbal, raciocinio_abstrato, raciocinio_espacial};
};

export { getQuizzes };
