import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

// Configuração de Axios
const instance = axios.create({
  baseURL: 'https://api.euqueroentrar.pt:7709',
  validateStatus: (status) => true,
});

interface TabButtonProps {
  active: boolean;
}

const MainContainer = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  min-height: 100vh;
  background-color: white;
  width: 100%; /* Define como 100% para mobile */
  max-width: 100%; /* Ocupa toda a largura disponível */
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 768px) {
    width: 80%; /* Ajusta para 80% em telas maiores */
    max-width: 1200px; /* Limita largura máxima */
  }
`;

const Title = styled.h1`
  margin: 10px 0;
  font-size: 1.5rem;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const Label = styled.label`
  margin: 5px 0;
  font-size: 0.9rem;
`;

const Input = styled.input`
  margin: 5px 0;
  padding: 5px;
  width: 250px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
`;

const Select = styled.select`
  margin: 5px 0;
  padding: 5px;
  width: 260px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  margin: 10px 0;
  padding: 8px 10px; /* Ajusta para mobile */
  font-size: 0.8rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;

  &:hover {
    background-color: #45a049;
  }

  @media (min-width: 768px) {
    padding: 10px 15px; /* Ajuste maior para telas grandes */
    font-size: 0.9rem;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
`;
const TabButton = styled.button<TabButtonProps>`
  padding: 8px 10px;
  font-size: 0.8rem; /* Ajuste para mobile */
  border: none;
  background-color: ${({ active }) => (active ? '#f0f0f0' : 'white')};
  color: ${({ active }) => (active ? '#00509E' : '#555')};
  font-weight: bold;
  cursor: pointer;

  @media (min-width: 768px) {
    font-size: 1rem; /* Maior em telas grandes */
    padding: 10px 15px;
  }
`;

const Table = styled.table`
  width: 100%; /* Preenche 100% para mobile */
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 0.9rem; /* Tamanho menor para mobile */

  @media (min-width: 768px) {
    font-size: 1rem; /* Aumenta para telas maiores */
  }
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 10px; /* Menor espaçamento para mobile */
  background-color: #f2f2f2;
  text-align: left;

  @media (min-width: 768px) {
    padding: 15px; /* Maior espaçamento em telas grandes */
  }
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  font-size: 0.9rem;

  @media (min-width: 768px) {
    padding: 15px;
    font-size: 1rem;
  }
`;

const DeleteButton = styled.button`
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  cursor: pointer;

  &:hover {
    background-color: #d32f2f;
  }
`;

const ContentWrapper = styled.div`
  width: 100%; /* Ocupa 100% em mobile */
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 768px) {
    width: 80%; /* Ajusta largura em telas maiores */
    max-width: 1200px;
  }
`;


const ActionButton = styled.button`
  padding: 8px 10px;
  font-size: 0.8rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }

  @media (min-width: 768px) {
    font-size: 0.9rem;
    padding: 10px 15px;
  }
`;

type Sugestao = {
  id: number;
  texto: string;
  dateTime: string;
};


type Report = {
  reportId: number; // ID do relatório
  perguntaId: number | null; // ID da pergunta, pode ser null
  userId: string; // ID do usuário
  reportText: string; // Texto do relatório
  corrigido: boolean; // Se foi corrigido
  perguntaTexto: string | null; // Texto da pergunta
  resposta1: string | null; // Primeira resposta
  resposta2: string | null; // Segunda resposta
  resposta3: string | null; // Terceira resposta
  resposta4: string | null; // Quarta resposta
  respostaCerta: string | null; // Resposta correta
};

const Gestao = () => {
  const [currentTab, setCurrentTab] = useState('perguntas'); // Aba atual
  const [reports, setReports] = useState<Report[]>([]);
  const [perguntas, setPerguntas] = useState<any[]>([]);
  const [sugestoes, setSugestoes] = useState<Sugestao[]>([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [temas, setTemas] = useState<any[]>([]);
  const [temaId, setTemaId] = useState('');
  const [novaPergunta, setNovaPergunta] = useState<{
    perguntaTexto: string;
    resposta1: string;
    resposta2: string;
    resposta3?: string;
    resposta4?: string;
    respostaCerta?: number; // Alterado para ser opcional
    pontuacao: number;
    tipo: string;
  }>({
    perguntaTexto: '',
    resposta1: '',
    resposta2: '',
    resposta3: '',
    resposta4: '',
    respostaCerta: 0,
    pontuacao: 1,
    tipo: 'MCQs',
  });

  useEffect(() => {
    instance
      .get('/api/temas')
      .then((response) => {
        if (response.status === 200) {
          setTemas(response.data);
        } else {
          console.error('Erro ao buscar temas:', response.status);
        }
      })
      .catch((error) => console.error('Erro ao buscar temas:', error));
  }, []);


  const fetchSugestoes = async () => {
    try {
      const response = await instance.get('/api/quiz/sugestoes');
      console.log('Sugestões recebidas:', response.data);
  
      if (response.status === 200 && Array.isArray(response.data)) {
        setSugestoes(response.data);
      } else {
        console.error('Formato de resposta inesperado:', response.data);
        alert('Erro ao carregar sugestões.');
      }
    } catch (error) {
      console.error('Erro ao buscar sugestões:', error);
      alert('Erro ao carregar sugestões.');
    }
  };

  useEffect(() => {
    if (currentTab === 'sugestoes') {
      fetchSugestoes();
    }
  }, [currentTab]);


  const fetchReports = async () => {
    try {
        const response = await instance.get('/api/quiz/report_perguntas');
        console.log('Dados recebidos da API:', response.data);

        if (response.status === 200 && Array.isArray(response.data)) {
            setReports(response.data);
        } else {
            console.error('Formato de resposta inesperado:', response.data);
            alert('Erro ao carregar reports.');
        }
    } catch (error) {
        console.error('Erro ao buscar reports:', error);
        alert('Erro ao carregar reports.');
    }
};
  
  // Atualiza os reports quando a aba "Reports" é selecionada
  useEffect(() => {
    if (currentTab === 'reports') {
      fetchReports();
    }
  }, [currentTab]);


  const handleCorrigir = async (id: number) => {
    if (!window.confirm('Tem certeza que deseja corrigir este report?')) return;

    try {
      const response = await instance.put(`/api/quiz/report_perguntas/${id}/corrigido`);
      if (response.status === 200) {
        alert('Report corrigido com sucesso!');
        fetchReports(); // Atualiza a lista de reports
      } else {
        console.error('Erro ao corrigir report:', response.data);
        alert('Não foi possível corrigir o report.');
      }
    } catch (error) {
      console.error('Erro ao corrigir report:', error);
      alert('Erro ao corrigir report.');
    }
  };


  const handleTemaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTemaId = e.target.value;
    console.log("Tema selecionado:", selectedTemaId); // Log para depuração
    setTemaId(selectedTemaId);
  
    if (!selectedTemaId) {
      console.error("Nenhum tema foi selecionado.");
      return;
    }
  
    // Formata o temaId como lista (mesmo que tenha um único ID)
    const temaIds = selectedTemaId;
  
    instance
      .get(`/api/quiz?temaIds=${temaIds}&quantidade=100`) // Atualizado para temaIds
      .then((response) => {
        console.log("Resposta da API:", response); // Debugging
        if (response.status === 200) {
          console.log("Perguntas recebidas:", response.data);
          setPerguntas(response.data); // Atualiza perguntas
        } else {
          console.error('Erro ao buscar perguntas:', response.status);
          setPerguntas([]); // Garante estado limpo
        }
      })
      .catch((error) => {
        console.error('Erro ao buscar perguntas:', error);
        setPerguntas([]); // Garante estado limpo
      });
  };
  
  const handleDeleteSugestao = (id: number) => {
    if (window.confirm('Tem certeza que deseja apagar esta sugestão?')) {
      instance
        .delete(`/api/sugestoes/${id}`)
        .then((response) => {
          if (response.status === 200) {
            alert('Sugestão apagada com sucesso!');
            setSugestoes((prev) => prev.filter((sugestao) => sugestao.id !== id));
          } else {
            console.error('Erro ao apagar sugestão:', response.status);
          }
        })
        .catch((error) => console.error('Erro ao apagar sugestão:', error));
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setNovaPergunta({ ...novaPergunta, [name]: value });
  };

  const handleDelete = (id: number) => {
    if (window.confirm('Tem certeza que deseja apagar esta pergunta?')) {
      instance
        .delete(`/api/quiz/${id}`)
        .then((response) => {
          if (response.status === 200) {
            alert('Pergunta apagada com sucesso!');
            setPerguntas(perguntas.filter((pergunta) => pergunta.id !== id));
          } else {
            console.error('Erro ao apagar pergunta:', response.status);
            alert('Não foi possível apagar a pergunta.');
          }
        })
        .catch((error) => {
          console.error('Erro ao apagar pergunta:', error);
          alert('Erro ao apagar pergunta.');
        });
    }
  };

  const isFormValid = () => {
    const { perguntaTexto, resposta1, resposta2, resposta3, resposta4, tipo } = novaPergunta;
    if (!perguntaTexto || !resposta1 || !resposta2) return false;

    if (tipo === 'MCQs' && (!resposta3 || !resposta4)) return false;

    return true;
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!isFormValid()) {
      alert('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    instance
      .post('/api/quiz', { ...novaPergunta, idTema: temaId })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          alert('Pergunta adicionada com sucesso!');
          setNovaPergunta({
            perguntaTexto: '',
            resposta1: '',
            resposta2: '',
            resposta3: '',
            resposta4: '',
            respostaCerta: novaPergunta.tipo === 'MCQs' ? 0 : undefined,
            pontuacao: 1,
            tipo: 'MCQs',
          });

          handleTemaChange({
            target: { value: temaId } as unknown as EventTarget & HTMLSelectElement,
          } as React.ChangeEvent<HTMLSelectElement>);
        } else {
          console.error('Erro ao adicionar pergunta:', response.status);
        }
      })
      .catch((error) => console.error('Erro ao adicionar pergunta:', error));
  };

  const correctPassword = "M3lg@s918816746";

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();

    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert("Senha incorreta!");
    }
  };

  if (!isAuthenticated) {
    return (
      <MainContainer>
        <Title>Autenticação</Title>
        <Form onSubmit={handleLogin}>
          <Label>Digite a senha:</Label>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button type="submit">Entrar</Button>
        </Form>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <Title>Gestão do Sistema</Title>

      <TabsContainer>
        <TabButton active={currentTab === 'perguntas'} onClick={() => setCurrentTab('perguntas')}>
          Perguntas
        </TabButton>
        <TabButton active={currentTab === 'sugestoes'} onClick={() => setCurrentTab('sugestoes')}>
          Sugestões
        </TabButton>
        <TabButton active={currentTab === 'reports'} onClick={() => setCurrentTab('reports')}>
          Reports
        </TabButton>
      </TabsContainer>
      {currentTab === 'reports' && (
        <div>
          <Title>Relatórios de Perguntas</Title>
          <Table>
            <thead>
              <tr>
                <Th>ID</Th>
                <Th>Pergunta</Th>
                <Th>Relatório</Th>
                <Th>Resposta Certa</Th>
                <Th>Corrigido</Th>
                <Th>Ações</Th>
              </tr>
            </thead>
            <tbody>
  {reports.length > 0 ? (
    reports.map((report, index) => (
      <tr key={report.reportId || `report-${index}`}>
        {/* ID do Relatório */}
        <Td>{report.reportId || 'ID não disponível'}</Td>

        {/* Pergunta */}
        <Td>
          {report.perguntaTexto || 'Pergunta não disponível'}
        </Td>

        {/* Relatório */}
        <Td>{report.reportText || 'Relatório não disponível'}</Td>

        {/* Resposta Certa */}
        <Td>
          {report.respostaCerta || 'Resposta não disponível'}
        </Td>

        {/* Corrigido */}
        <Td>{report.corrigido ? 'Sim' : 'Não'}</Td>

        {/* Ações */}
        <Td>
          {!report.corrigido && report.reportId && (
            <ActionButton onClick={() => handleCorrigir(report.reportId)}>
              Marcar como Corrigido
            </ActionButton>
          )}
        </Td>
      </tr>
    ))
  ) : (
    <tr>
      <Td colSpan={6}>Nenhum relatório encontrado.</Td>
    </tr>
  )}
</tbody>


          </Table>
        </div>
      )}
      {currentTab === 'sugestoes' && (
        <ContentWrapper>
          <Table>
            <thead>
              <tr>
                <Th>ID</Th>
                <Th>Sugestão</Th>
                <Th>Data</Th>
                {/* <Th>Ações</Th> */}
              </tr>
            </thead>
            <tbody>
  {sugestoes && sugestoes.length > 0 ? (
    sugestoes.map((sugestao) => (
      <tr key={sugestao.id}>
        <Td>{sugestao.id}</Td>
        <Td>{sugestao.texto || 'Texto não disponível'}</Td>
        <Td>
          {sugestao.dateTime && !isNaN(new Date(sugestao.dateTime).getTime())
            ? new Date(sugestao.dateTime).toLocaleString()
            : 'Data Inválida'}
        </Td>
        {/* <Td>
          <DeleteButton onClick={() => handleDeleteSugestao(sugestao.id)}>
            Apagar
          </DeleteButton>
        </Td> */}
      </tr>
    ))
  ) : (
    <tr>
      <Td colSpan={4}>Nenhuma sugestão encontrada.</Td>
    </tr>
  )}
</tbody>
          </Table>
          </ContentWrapper>
      )}

      {currentTab === 'perguntas' && (
        <div>
      <Title>Gestão de Perguntas</Title>

      <Form onSubmit={handleFormSubmit}>
        <Label>Tema:</Label>
        <Select value={temaId} onChange={handleTemaChange} required>
          <option value="">Escolha um tema</option>
          {temas.map((tema: any) => (
            <option key={tema.id} value={tema.id}>
              {tema.nome}
            </option>
          ))}
        </Select>

        <Label>Pergunta:</Label>
        <Input
          type="text"
          name="perguntaTexto"
          value={novaPergunta.perguntaTexto}
          onChange={handleInputChange}
          required
        />

        <Label>Tipo de Pergunta:</Label>
        <Select
          name="tipo"
          value={novaPergunta.tipo}
          onChange={handleInputChange}
          required
        >
          <option value="MCQs">Múltipla Escolha (1 resposta correta)</option>
          <option value="Boolean">Verdadeiro/Falso</option>
          <option value="MAQs">Múltipla Escolha (várias respostas corretas)</option>
        </Select>

        <Label>Respostas:</Label>
        {[1, 2, 3, 4].map((n, index) => (
          <CheckboxWrapper key={index}>
            <Input
              type="text"
              name={`resposta${n}`}
              placeholder={`Resposta ${n}`}
              value={novaPergunta[`resposta${n}` as keyof typeof novaPergunta]}
              onChange={handleInputChange}
              required={novaPergunta.tipo === 'MCQs' || n <= 2}
            />
            {novaPergunta.tipo === 'MCQs' && (
              <input
                type="radio"
                name="respostaCerta"
                checked={novaPergunta.respostaCerta === index}
                onChange={() =>
                  setNovaPergunta({ ...novaPergunta, respostaCerta: index })
                }
              />
            )}
          </CheckboxWrapper>
        ))}

        <Label>Pontuação:</Label>
        <Input
          type="number"
          name="pontuacao"
          value={novaPergunta.pontuacao}
          onChange={handleInputChange}
          min="1"
          required
        />

        <Button type="submit">Adicionar</Button>
      </Form>

      <Table>
        <thead>
          <tr>
            <Th>Pergunta</Th>
            <Th>Tipo</Th>
            <Th>Respostas</Th>
            <Th>Correta(s)</Th>
            <Th>Pontuação</Th>
            <Th>Ações</Th>
          </tr>
        </thead>
        <tbody>
          {perguntas.map((pergunta: any) => (
            <tr key={pergunta.id}>
              <Td>{pergunta.perguntaTexto}</Td>
              <Td>{pergunta.tipo}</Td>
              <Td>
                {[pergunta.resposta1, pergunta.resposta2, pergunta.resposta3, pergunta.resposta4]
                  .filter(Boolean)
                  .join(', ')}
              </Td>
              <Td>
                {pergunta.tipo === 'MCQs' || pergunta.tipo === 'Boolean'
                  ? `Resposta ${pergunta.respostaCerta + 1}`
                  : pergunta.respostasCertas}
              </Td>
              <Td>{pergunta.pontuacao}</Td>
              <Td>
                <DeleteButton onClick={() => handleDelete(pergunta.id)}>
                  Apagar
                </DeleteButton>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
      </div>
      )}
    </MainContainer>
  );
};

export default Gestao;
