

import { Topic } from '..'

export const psp_leiOrganica_2: Topic = {
  topic: 'Lei Orgânica - PSP (2)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    //2017
    
    {
      "question": "A que símbolos tem direito a PSP?",
      "choices": [
        "Brasão de armas , hino nacional, estandarte nacional",
        "Brasão de armas, selo branco, hino nacional",
        "Bandeira heráldicas, estandarte nacional, hino nacional, selo branco",
        "Brasão de armas, bandeiras heráldicas, hino e selo branco."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Brasão de armas, bandeiras heráldicas, hino e selo branco."
      ],
      "score": 10
    },
    {
      "question": "A Direção Nacional, as unidades de polícia e os estabelecimentos de ensino têm direito a que símbolos?",
      "choices": [
        "Brasão de armas, selo branco, hino nacional",
        "Bandeira heráldica, estandarte nacional, hino nacional, selo branco",
        "Brasão de armas, bandeiras heráldicas, hino e selo branco.",
        "Brasão de armas, bandeiras heráldicas e selo branco."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Brasão de armas, bandeiras heráldicas e selo branco."
      ],
      "score": 10
    },
    {
      "question": "Considera-se força pública, o efetivo mínimo de quantos agentes em missão de serviço?",
      "choices": [
        "1",
        "2",
        "3",
        "4"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "2"
      ],
      "score": 10
    },
    {
      "question": "Indique qual é considerada autoridade de polícia:",
      "choices": [
        "Comandante do UEP",
        "Ministro da Tutela",
        "Comandante da GNR",
        "Nenhuma das respostas anteriores"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Comandante do UEP"
      ],
      "score": 10
    },
    {
      "question": "Qual é o órgão de carácter consultivo do diretor nacional o qual compete pronunciar-se sobre os assuntos relativos à atividade da PSP e sua relação com as populações, apoiar a decisão do diretor nacional em assuntos de particular relevância?",
      "choices": [
        "Conselho deontológico de disciplina",
        "Conselho superior de polícia",
        "Junta superior de saúde",
        "Inspeção"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Conselho superior de polícia"
      ],
      "score": 10
    },
    {
      "question": "Por quem é dirigido o órgão de inspeção?",
      "choices": [
        "Diretor nacional",
        "Inspetor nacional",
        "Comandante do UEP",
        "Nenhuma das respostas anteriores"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Inspetor nacional"
      ],
      "score": 10
    },
    {
      "question": "Por quantos médicos é constituída a junta superior de saúde?",
      "choices": [
        "2",
        "3",
        "4",
        "5"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "3"
      ],
      "score": 10
    },
    {
      "question": "A criação e extinção de subunidades dos comandos territoriais de polícia e da UEP são aprovadas por portaria do ministro da tutela.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    //Quiz Lei Orgânica 
    {
      "question": "A Polícia de Segurança Pública:",
      "choices": [
        "é uma força de segurança, militarizada e armada, com natureza de serviço público e dotada de autonomia administrativa.",
        "é uma força de segurança, uniformizada, militarizada e armada, com natureza de serviço público e dotada de autonomia administrativa.",
        "é uma força de segurança, uniformizada e armada, com natureza de serviço privado e dotada de autonomia administrativa.",
        "é uma força de segurança, uniformizada e armada, com natureza de serviço público e dotada de autonomia administrativa."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "é uma força de segurança, uniformizada e armada, com natureza de serviço público e dotada de autonomia administrativa."
      ],
      "score": 10
    },
    {
      "question": "A PSP:",
      "choices": [
        "depende do membro do Governo responsável pela área da Defesa Nacional e a sua organização é única para todo o território nacional.",
        "depende do membro do Governo responsável pela área da administração interna e a sua organização é única para todo o território nacional.",
        "depende do membro do Governo responsável pela área da administração interna e a sua organização é única para todo o território internacional.",
        "depende do membro do Governo responsável pela área da Defesa Nacional e a sua organização é única para todo o território internacional."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "depende do membro do Governo responsável pela área da administração interna e a sua organização é única para todo o território nacional."
      ],
      "score": 10
    },
    {
      "question": "Constituem atribuições da PSP:",
      "choices": [
        "Garantir as condições de segurança que permitam o exercício dos direitos e liberdades e o respeito pelas garantias dos cidadãos, bem como o pleno funcionamento das instituições democráticas, no respeito pela legalidade e pelos princípios do Estado de direito.",
        "Garantir a ordem e a tranquilidade públicas e a segurança e a proteção das pessoas e dos bens.",
        "Prevenir a criminalidade em geral , em coordenação com as demais forças e serviços de segurança.",
        "Todas as respostas anteriores estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as respostas anteriores estão corretas."
      ],
      "score": 10
    },
    {
      "question": "A PSP:",
      "choices": [
        "não pode dirimir conflitos de natureza privada, devendo, nesses casos, limitar a sua ação à manutenção da ordem pública.",
        "pode dirimir conflitos de natureza privada, devendo, nesses casos, limitar a sua ação à manutenção da ordem pública.",
        "pode dirimir conflitos de natureza privada e publica, devendo, nesses casos, limitar a sua ação à manutenção da ordem pública.",
        "não pode dirimir conflitos de natureza publica, devendo, nesses casos, limitar a sua ação à manutenção da ordem pública."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "não pode dirimir conflitos de natureza privada, devendo, nesses casos, limitar a sua ação à manutenção da ordem pública."
      ],
      "score": 10
    },
    {
      "question": "A intervenção da PSP depende:",
      "choices": [
        "Do pedido de outra força de segurança.",
        "De ordem especial.",
        "De imposição legal.",
        "Todas as repostas anteriores estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as repostas anteriores estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Deveres de colaboração da PSP",
      "choices": [
        "A PSP, sem prejuízo das prioridades legais da sua atuação, coopera com as demais forças e serviços de segurança, bem como com as autoridades privadas, designadamente, com os órgãos autárquicos e outros organismos, nos termos da lei.",
        "As autoridades da administração central, regional e local, os serviços públicos e as demais entidades públicas ou privadas devem prestar à PSP a colaboração que legitimamente lhes for solicitada para o exercício das suas funções.",
        "As autoridades administrativas podem não comunicar à PSP, quando solicitado, o teor das decisões sobre as infrações que esta lhes tenha participado.",
        "Todas as respostas anteriores estão erradas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "As autoridades da administração central, regional e local, os serviços públicos e as demais entidades públicas ou privadas devem prestar à PSP a colaboração que legitimamente lhes for solicitada para o exercício das suas funções."
      ],
      "score": 10
    },
    {
      "question": "Considera-se força pública:",
      "choices": [
        "O efetivo mínimo de quatro agentes em missão de serviço.",
        "O efetivo mínimo de um agente em missão de serviço.",
        "O efetivo mínimo de dois agentes em missão de serviço.",
        "O efetivo mínimo de três agentes em missão de serviço."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O efetivo mínimo de dois agentes em missão de serviço."
      ],
      "score": 10
    },
    {
      "question": "São consideradas autoridades de polícia:",
      "choices": [
        "O diretor nacional; Os diretores nacionais-adjuntos; O inspetor nacional; O comandante da Unidade Especial de Polícia; Os comandantes das unidades e subunidades até ao nível de esquadra;  Outros oficiais da PSP, quando no exercício de funções de comando ou chefia operacional.",
        "O diretor nacional; Os diretores nacionais-adjuntos; O inspetor nacional; O comandante da Unidade Especial de Polícia; Os comandantes das unidades e subunidades até ao nível de territórios;  Outros oficiais da PSP, quando no exercício de funções de comando ou chefia operacional.",
        "O diretor nacional; Os diretores nacionais-adjuntos; O inspetor nacional; O comandante da Unidade Especial de Polícia; Os comandantes das unidades; chefe comissario e subunidades até ao nível de esquadra.",
        "Os diretores nacionais-adjuntos; O inspetor nacional; O comandante da Unidade Especial de Polícia; Os comandantes das unidades e subunidades até ao nível de esquadra;  Outros oficiais da PSP, quando no exercício de funções de comando ou chefia operacional."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O diretor nacional; Os diretores nacionais-adjuntos; O inspetor nacional; O comandante da Unidade Especial de Polícia; Os comandantes das unidades e subunidades até ao nível de esquadra;  Outros oficiais da PSP, quando no exercício de funções de comando ou chefia operacional."
      ],
      "score": 10
    },
    {
      "question": "Prestação de serviços especiais",
      "choices": [
        "A PSP pode manter pessoal com funções policiais em organismos de interesse público, em condições definidas por portaria do ministro das finanças.",
        "A PSP não pode manter pessoal com funções policiais em organismos de interesse público, em condições definidas por portaria do ministro da tutela.",
        "O pessoal da PSP não pode ser nomeado em comissão de serviço para organismos internacionais ou países estrangeiros, em função dos interesses nacionais e dos compromissos.",
        "O pessoal da PSP pode ser nomeado em comissão de serviço para organismos internacionais ou países estrangeiros, em função dos interesses nacionais e dos compromissos."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O pessoal da PSP pode ser nomeado em comissão de serviço para organismos internacionais ou países estrangeiros, em função dos interesses nacionais e dos compromissos."
      ],
      "score": 10
    },
    {
      "question": "Estrutura Geral da PSP",
      "choices": [
        "A Direção Nacional; As unidades de polícia; Os estabelecimentos de ensino policial.",
        "A Direção Nacional; Os estabelecimentos de ensino policial, comandos territoriais.",
        "A Direção Nacional; As unidades de força; Os estabelecimentos de ensino policial.",
        "A Direção Nacional; As unidades de polícia; Os estabelecimentos de prisão."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A Direção Nacional; As unidades de polícia; Os estabelecimentos de ensino policial."
      ],
      "score": 10
    },
    {
      "question": "Estabelecimentos de ensino policial",
      "choices": [
        "Instituto Superior de Culturas Policiais e Segurança Interna; A Escola Prática de Polícia.",
        "Instituto Ciências Policiais e Segurança Interna; A Escola Prática de Polícia.",
        "Instituto Superior de Ciências Policiais e Segurança Interna; A Escola Prática de Polícia.",
        "Instituto Superior de Ciências Policiais e Segurança Internacional; A Escola Prática de Polícia."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Instituto Superior de Ciências Policiais e Segurança Interna; A Escola Prática de Polícia."
      ],
      "score": 10
    },
    {
      "question": "Órgãos de inspeção e consulta",
      "choices": [
        "A Inspeção; O Conselho Superior de Polícia, o Conselho de Deontologia e Disciplina e a Junta Superior de Saúde.",
        "A Inspeção; O Conselho Superior de Polícia, o Conselho de Deontologia e Disciplina e a Junta Superior de Saúde, órgãos de consulta.",
        "A Inspeção; O Conselho Supremo de Polícia, o Conselho de Deontologia e Disciplina e a Junta Superior de Saúde, órgãos de consulta.",
        "A Inspeção; O Conselho Superior de Polícia, o Conselho de Deontologia e Disciplina e a Junta de Saúde, órgãos de consulta."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A Inspeção; O Conselho Superior de Polícia, o Conselho de Deontologia e Disciplina e a Junta Superior de Saúde, órgãos de consulta."
      ],
      "score": 10
    }
  ],
}
