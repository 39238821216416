import { Theme } from './styled'

export const themes: Record<string, Theme> = {
  light: {
    colors: {
      primaryText: '#0D1B2A', // cor do texto das perguntas
      secondaryText: '#1B263B', // cor do texto das respostas
      themeText: '#333',
      themeColor: '#00509E', // azul escuro para transmitir autoridade
      themeGradient: 'linear-gradient(to right, #00509E, #00509E)', // gradiente de azul escuro para azul claro
      background: '#00509E', // fundo claro e neutro
      cardBackground: '#FFFFFF',
      selectTopicBg: '#FFFFFF',
      appLogo: '#00509E',
      buttonText: '#FFFFFF',
      outlineButtonText: '#00509E',
      buttonBackground: 'linear-gradient(90.04deg, #00509E 0.03%, #00509E 99.96%)', // gradiente de azul escuro para azul claro
      selectedAnswer: '#E0F7FA', // azul claro para a resposta selecionada
      infoText: '#E63946', // vermelho para o texto das tags de informações
      infoBackground: '#FFCDD2', // fundo vermelho claro para as tags de informações
      border: '#EAEAEA',
      answerBg: '#ffffff',
      disabledCard: '#fbf4ecbc',
      disabledButton: '#e7e8e9',
      success: '#2D6A4F', // verde escuro para sucesso
      successLight: '#DFF0D8', // verde claro para sucesso
      danger: '#E63946', // vermelho para perigo
      dangerLight: '#FFD7DE',
      white: '#FFFFFF',
      black: '#000000',
      dark: '#282526',
      darkGray: '#9fa3a9',
      darkerGray: '#817a8e',
      appTimer:'#0D1B2A'
    },
    fonts: {
      anekMalayalam: 'Anek Malayalam',
    },
    shadows: {
      activeButton: '3px 2px 22px 1px rgba(0, 0, 0, 0.24)',
    },
    paddings: {
      container: '15px',
      pageTop: '30px',
    },
    margins: {
      pageTop: '30px',
    },
  },
  dark: {
    colors: {
      primaryText: '#FFFFFF', // cor do texto das perguntas
      secondaryText: '#E0E0E0', // cor do texto das respostas
      themeText: '#FFFFFF',
      themeColor: '#00509E', // azul escuro para manter a seriedade
      themeGradient: 'linear-gradient(90deg, #0D1B2A 0%, #1B263B 100%)', // gradiente de azul escuro
      background: 'linear-gradient(90deg, #0D1B2A 0%, #1B263B 100%)',
      cardBackground: '#121212', // fundo dos cartões em um cinza bem escuro
      selectTopicBg: '#1C1C1C', // fundo para seleção de tópicos
      appLogo: '#00509E',
      buttonText: '#FFFFFF',
      outlineButtonText: '#00509E', // azul escuro para botões de contorno
      buttonBackground: 'linear-gradient(90.04deg, #00509E 0.03%, #3E92CC 99.96%)', // gradiente de azul escuro para azul claro
      selectedAnswer: '#2A2A2A', // cinza escuro para a resposta selecionada
      infoText: '#E63946', // vermelho para o texto das tags de informações
      infoBackground: '#4E2A2A', // fundo vermelho escuro para as tags de informações
      border: '#3E3E3E', // bordas em cinza médio
      answerBg: '#1A1A1A', // fundo das respostas em cinza bem escuro
      disabledCard: '#3A3A3A', // cartões desabilitados em cinza escuro
      disabledButton: '#2A2A2A', // botões desabilitados em cinza escuro
      success: '#2D6A4F', // verde escuro para sucesso
      successLight: '#1C3D2A', // verde escuro para fundos de sucesso
      danger: '#E63946', // vermelho para perigo
      dangerLight: '#4E2A2A', // fundo vermelho escuro para perigo
      white: '#FFFFFF',
      black: '#000000',
      dark: '#1A1A1A', // cinza muito escuro para elementos gerais
      darkGray: '#9fa3a9',
      darkerGray: '#817a8e',
      appTimer:'#FFFFFF'
    },
    fonts: {
      anekMalayalam: 'Anek Malayalam',
    },
    shadows: {
      activeButton: '3px 2px 22px 1px rgba(0, 0, 0, 0.24)',
    },
    paddings: {
      container: '15px',
      pageTop: '30px',
    },
    margins: {
      pageTop: '30px',
    },
  },
}
