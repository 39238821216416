import React from "react";
import styled, { createGlobalStyle } from "styled-components";

// Estilo Global
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0 !important;
    padding: 0 !important;
    font-family: 'Arial', sans-serif !important;
    background-color: #00509e !important;
    color: #333 !important;
  }
`;

// Wrapper da página
const PageWrapper = styled.div`
  margin-top: 40px;
  width: 100% !important;
  min-height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
`;

// Container do conteúdo
const ContentContainer = styled.div`
  max-width: 800px !important;
  width: 90% !important;
  background-color: #fff !important;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  padding: 30px !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1) !important;

  h1 {
    font-size: 1.8rem !important;
    color: #00509e !important;
    margin-bottom: 20px !important;
    text-align: center !important;
  }

  h2 {
    font-size: 1.2rem !important;
    margin-top: 20px !important;
    color: #333 !important;
  }

  p {
    font-size: 1rem !important;
    line-height: 1.5 !important;
    color: #555 !important;
    margin-bottom: 15px !important;
  }
`;

function PrivacyPolicy() {
  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        <ContentContainer>
          <h1>Política de Privacidade</h1>

          <p>
            A tua privacidade é importante para nós. Este documento explica quais as
            informações recolhidas, como são utilizadas e quais os teus direitos em relação
            aos teus dados.
          </p>

          <h2>1. Recolha de Informações</h2>
          <p>
            Podemos recolher dados pessoais, como nome e email, quando te registas na plataforma,
            bem como informações sobre a tua utilização (ex.: datas de acesso, testes realizados, etc.).
          </p>

          <h2>2. Utilização dos Dados</h2>
          <p>
            Os dados recolhidos servem para melhorar a tua experiência, oferecer conteúdos
            personalizados e facilitar o suporte. Podemos também utilizar essas informações para
            comunicações relacionadas com a plataforma.
          </p>

          <h2>3. Partilha de Informações</h2>
          <p>
            Não vendemos ou partilhamos os teus dados com terceiros, exceto quando necessário para
            o funcionamento do serviço (ex.: processadores de pagamento) ou exigido por lei.
          </p>

          <h2>4. Segurança</h2>
          <p>
            Implementamos medidas de segurança para proteger os teus dados contra acesso não autorizado.
            Contudo, nenhum método de transmissão ou armazenamento digital é 100% seguro.
          </p>

          <h2>5. Cookies</h2>
          <p>
            Utilizamos cookies para melhorar a experiência do utilizador, armazenar preferências e fornecer
            conteúdos relevantes. Podes desativar os cookies no teu navegador, mas algumas funcionalidades
            podem ficar limitadas.
          </p>

          <h2>6. Direitos do Utilizador</h2>
          <p>
            Tens o direito de aceder, corrigir ou eliminar os teus dados pessoais. Para tal, contacta-nos 
            através dos nossos canais de suporte.
          </p>

          <p>
            Caso a política de privacidade seja atualizada, notificaremos os utilizadores para garantir
            transparência. A tua utilização contínua após alterações significa que aceitas as novas
            disposições.
          </p>
        </ContentContainer>
      </PageWrapper>
    </>
  );
}

export default PrivacyPolicy;
