import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppLogo } from '../../config/icons';
import { useQuiz } from '../../context/QuizContext';
import { getQuizzes, QuizTopic } from '../../data/quizTopics';
import { device } from '../../styles/BreakPoints';
import { FaVolumeUp, FaDumbbell, FaYoutube } from 'react-icons/fa';  // Ícone de áudio
import {
  CenterCardContainer,
  HighlightedText,
  LogoContainer,
  PageCenter,
} from '../../styles/Global';
import { ScreenTypes } from '../../types';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Importa o ícone do WhatsApp
import Link from '@mui/material/Link';
import img_masculino from '../../assets/images/masculino.png'
import img_feminino from '../../assets/images/feminino.png'
import mbway from '../../assets/images/mbway.png';



import { FaChevronDown, FaChevronUp } from 'react-icons/fa';



const Heading = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  color: #00509E;
`;

const SelectButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 60%;
  gap: 30px;
  margin-top: 40px;
  margin-bottom: 45px;
  @media (max-width: 768px) {
    row-gap: 20px;
    column-gap: 20px;
    max-width: 100%;
  }
`;

interface SelectButtonProps {
  active: boolean;
  disabled?: boolean;
}

const SelectButton = styled.div<SelectButtonProps>`
  background-color: ${({ disabled }) =>
    disabled ? '#d3d3d3' : '#F9F9F9'};
  border: ${({ active }) =>
    active ? '2px solid #00509E' : '2px solid #d3d3d3'};
  transition: background-color 0.4s ease-out;
  border-radius: 10px;
  padding: 14px 10px;
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#d3d3d3' : '#e6f7ff')};
  }
`;

const StyledImage = styled.img`
  display: block; /* Garante que a imagem seja tratada como um elemento de bloco */
  width: 30%; /* Ajusta a largura da imagem */
  max-width: 300px; /* Define uma largura máxima */
  border-radius: 8px; /* Adiciona bordas arredondadas */
  margin: 20px auto; /* Centraliza horizontalmente */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adiciona sombra */
`;
const SelectButtonText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #00509E;
  margin-left: 10px;
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 500;
  }
`;

const Panel = styled.div`
  border: 2px solid #00509E;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 30px;
  width: 80%;
  background-color: #F9F9F9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  @media (max-width: 768px) {
    width: 100%;
  }
`;



const DetailText = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  width: 100%;
  margin: 0;
  color: #333;
`;

const HighlightedTexts = styled.span`
  color: #00509E;
  font-weight: bold;
`;

const CollapsibleTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  color: #00509E;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin: 10px auto;
  width: 90%;
  background-color: #e6f7ff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #d1efff;
    transform: scale(1.02);
  }

  &::after {
    content: '+';
    font-size: 24px;
    font-weight: bold;
    color: #00509E;
    transition: transform 0.3s ease;
  }

  &.expanded::after {
    content: '-';
    transform: rotate(180deg);
  }

  @media (max-width: 768px) {
    font-size: 24px;
    padding: 8px 16px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const WhatsAppButton = styled.button.attrs((props) => ({
  type: 'button',
  ...props
}))`
  background-color: #25D366;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  &:hover {
    background-color: #22bb5c;
  }
`;

const AudioContainer = styled.div`
  text-align: right;
  margin: 16px 0;
`;

const AudioLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #00509E;
  &:hover {
    color: #003f7f;
  }
`;

const AudioIcon = styled(FaVolumeUp)`
  margin-right: 8px;
  font-size: 18px;
`;

const PlanoTreinoContainer = styled.div`
  text-align: right;
  margin: 16px 0;
`;

const PlanoTreinoLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #00509E;
  &:hover {
    color: #003f7f;
  }
`;

const PlanoTreinoIcon = styled.div`
  margin-right: 8px;
  font-size: 18px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  padding: 10px;
`;

const CardLink = styled.a`
  text-decoration: none;
  color: inherit;
  display: block;
  background-color: #fff;
  border-radius: 8px;
  transition: transform 0.2s, box-shadow 0.2s;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    
  }
`;

const Card = styled.div`
  background-color: #f1f1f1;
  padding: 20px;
  width: 200px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;



const Card2 = styled.div`
  background-color: #f1f1f1;
  padding: 20px;
  width: 350px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const CardTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
  color: #00509E;
`;

const CardDescription = styled.p`
  font-size: 14px;
  color: #555;
`;

const VideoIcon = styled.a`
  font-size: 64px;
  color: #FF0000;
  margin-top: 10px;
  display: inline-block;
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 20px 0;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  th, td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
  }
  th {
    background-color: #F9F9F9;
    color: #00509E;
  }
  @media (max-width: 600px) {
    th, td {
      font-size: 12px;
      padding: 8px;
    }
  }
`;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: "20px",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#f9f9f9",
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    gap: "20px", // Espaço entre as imagens
    marginTop: "15px",
  },
  image: {
    width: "120px", // Ajuste o tamanho como preferir
    height: "auto",
    borderRadius: "8px", // Deixa as bordas arredondadas
    transition: "transform 0.3s ease", // Animação ao passar o mouse
    cursor: "pointer",
  },
  imageHover: {
    transform: "scale(1.05)", // Aumenta levemente a imagem ao passar o mouse
  },
};

const MenuTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #00509E;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin: 10px auto;
  width: 90%;
  background-color: #e6f7ff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-sizing: border-box;

  &:hover {
    background-color: #d1efff;
    transform: scale(1.02);
  }

  &::after {
    content: '+';
    font-size: 24px;
    font-weight: bold;
    color: #00509E;
    transition: transform 0.3s ease;
  }

  &.expanded::after {
    content: '-';
    transform: rotate(180deg);
  }

  @media (max-width: 768px) {
    font-size: 20px;
    padding: 8px 16px;
  }
`;

const LikePopup: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <h2>Entra no nosso DISCORD!</h2>
        <br />
        <p>Juntos somos mais fortes!</p>
        <br />
        <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="outlined" color="secondary" onClick={onClose}>
    Fechar
  </Button>
  <Link href="https://discord.gg/tnnAhrjrkV" target="_blank" rel="noopener noreferrer">
    <Button variant="contained" color="primary">
      Entrar
    </Button>
  </Link>
  
</div>

      </ModalContent>
    </ModalOverlay>
  );
};

const QuizTopicsScreen: React.FC = () => {

  const [isKnowledgeExpanded, setIsKnowledgeExpanded] = useState(false); // Estado para colapsar/descolapsar Prova de Conhecimentos
  const [isTestThemeExpanded, setIsTestThemeExpanded] = useState(false); // Estado para colapsar/descolapsar Tema Teste
  const [isTest2ThemeExpanded, setIsTest2ThemeExpanded] = useState(false); // Estado para colapsar/descolapsar Tema Teste

  const toggleKnowledgePanel = () => setIsKnowledgeExpanded(!isKnowledgeExpanded);
  const toggleTestThemePanel = () => setIsTestThemeExpanded(!isTestThemeExpanded);
  const toggleTest2ThemePanel = () => setIsTest2ThemeExpanded(!isTest2ThemeExpanded);

  const [showLikePopup, setShowLikePopup] = useState(false); 
  
  const [hasPurchased, setHasPurchased] = useState<boolean | null>(null); // Estado para armazenar se o usuário comprou ou não
  const [showPurchaseReminder, setShowPurchaseReminder] = useState(false); // Estado para controlar a exibição do modal
  const { quizTopic, selectQuizTopic, setCurrentScreen } = useQuiz();
  const [loading, setLoading] = useState(true);
  const [quizzes, setQuizzes] = useState<{ geral_quiz: QuizTopic[]; psp_quiz_pc: QuizTopic[]; gnr_provaCompleta_quiz: QuizTopic[]; gnr_estatutos_quiz: QuizTopic[]; psp_estatutos_quiz: QuizTopic[];gnr_leiOrganica_quiz: QuizTopic[]; psp_leiOrganica_quiz: QuizTopic[];geral_crp_dudh_ue_quiz: QuizTopic[]; geral_cg_quiz: QuizTopic[]; geral_lp_quiz: QuizTopic[], matrizes_progressivas: QuizTopic[], raciocinio_verbal: QuizTopic[], raciocinio_abstrato: QuizTopic[], raciocinio_espacial: QuizTopic[] }>({
    geral_quiz: [],
    psp_quiz_pc: [],
    gnr_estatutos_quiz: [],
    psp_estatutos_quiz: [],
    gnr_leiOrganica_quiz: [],
    psp_leiOrganica_quiz : [],
    geral_crp_dudh_ue_quiz: [],
    geral_cg_quiz: [],
    geral_lp_quiz: [],
    gnr_provaCompleta_quiz: [],
    matrizes_progressivas: [],
    raciocinio_verbal: [],
    raciocinio_abstrato: [],
    raciocinio_espacial: []
    
  });

  

  const navigate = useNavigate();

  const fetchPurchaseStatus = async () => {
    let purchased = false;
    try {
      const userId = auth.currentUser?.uid;
      if (userId) {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          purchased = userDoc.data().hasPurchased;
        }
      }
    } catch (error) {
      console.error('Error fetching purchase status: ', error);
    }
    return purchased;
  };

  

  useEffect(() => {
    const loadQuizzes = async () => {
      const fetchedQuizzes = await getQuizzes(); // Simulação de chamada API
      setQuizzes(fetchedQuizzes);
      setLoading(false);

      // Fetch purchase status
      const purchasedStatus = await fetchPurchaseStatus(); // Simulação de chamada API
      setHasPurchased(purchasedStatus);

      // Gerar valor booleano aleatório
      const randomBoolean = Math.random() >= 0.5;

      

      // Verificar status de compra e booleano aleatório
      if (!purchasedStatus && randomBoolean) {
        setShowPurchaseReminder(true); // Mostra o modal ou outra ação
      } else {
        setShowPurchaseReminder(false); // Não exibe o modal
      }
    };

    const checkLastPopupDisplay = () => {
      const lastDisplay = localStorage.getItem('lastLikePopupDisplay');
      const currentTime = new Date().getTime();

      if (!lastDisplay || currentTime - parseInt(lastDisplay, 10) > 4 * 60 * 60 * 1000) {
        setShowLikePopup(true);  // Mostrar popup se passou mais de um dia
        localStorage.setItem('lastLikePopupDisplay', currentTime.toString());
      }
    };

    // setShowLikePopup(true);  // Mostrar popup se passou mais de um dia

    loadQuizzes();
    checkLastPopupDisplay();
  }, []);

  const goToQuizDetailsScreen = (title: string) => {
    selectQuizTopic(title);
    setCurrentScreen(ScreenTypes.QuizDetailsScreen);
  };

  const handlePurchaseNavigation = () => {
    setShowPurchaseReminder(false); // Fecha o modal
    navigate('/purchase');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  

  return (
    <PageCenter light justifyCenter>
      <CenterCardContainer>
        <LogoContainer>
          <AppLogo />
        </LogoContainer>
        <Heading>
          <h1><HighlightedTexts>PSP</HighlightedTexts></h1>
        </Heading>

            <div style={{ display: 'flex', justifyContent: 'flex-end !important'}}>
           
            </div>

            <MenuTitle onClick={() => navigate("/provas/psp/provaspsp/provacustomizada")}>
              Prova de Conhecimentos
            </MenuTitle>
            <br />
            <br />
            <MenuTitle onClick={toggleKnowledgePanel}>
              Prova de Conhecimentos (Demo) {isKnowledgeExpanded }
            </MenuTitle>
           

           
            
        <br />
        {isKnowledgeExpanded && (
          <>
            
            <Panel>
        <DetailText>Cultura Geral</DetailText>
        <SelectButtonContainer>
          {quizzes.geral_cg_quiz.map(({ title, icon, disabled }) => (
            <SelectButton
              key={title}
              active={quizTopic === title}
              onClick={() => !disabled && goToQuizDetailsScreen(title)}
              disabled={disabled}
            >
              {icon}
              <SelectButtonText>{title}</SelectButtonText>
            </SelectButton>
          ))}
        </SelectButtonContainer>
        </Panel>

        <Panel>
        <DetailText>Língua Portuguesa</DetailText>
        <SelectButtonContainer>
          {quizzes.geral_lp_quiz.map(({ title, icon, disabled }) => (
            <SelectButton
              key={title}
              active={quizTopic === title}
              onClick={() => !disabled && goToQuizDetailsScreen(title)}
              disabled={disabled}
            >
              {icon}
              <SelectButtonText>{title}</SelectButtonText>
            </SelectButton>
          ))}
        </SelectButtonContainer>
        </Panel>
          
        <Panel>
        <DetailText>Constituição da República Portuguêsa, Declaração Universal dos Direitos Humanos e União Europeia</DetailText>
        <SelectButtonContainer>
          {quizzes.geral_crp_dudh_ue_quiz.map(({ title, icon, disabled }) => (
            <SelectButton
              key={title}
              active={quizTopic === title}
              onClick={() => !disabled && goToQuizDetailsScreen(title)}
              disabled={disabled}
            >
              {icon}
              <SelectButtonText>{title}</SelectButtonText>
            </SelectButton>
          ))}
        </SelectButtonContainer>
        </Panel>


        <Panel>
        <DetailText>Estatuto Profissional do Pessoal com Funções Policiais da PSP</DetailText>
        <AudioContainer>
            <AudioLink href="/audio_psp_estatutos">
              <AudioIcon /> Ouvir Resumo da Matéria
            </AudioLink>
          </AudioContainer>
        <SelectButtonContainer>
          {quizzes.psp_estatutos_quiz.map(({ title, icon, disabled }) => (
            <SelectButton
              key={title}
              active={quizTopic === title}
              onClick={() => !disabled && goToQuizDetailsScreen(title)}
              disabled={disabled}
            >
              {icon}
              <SelectButtonText>{title}</SelectButtonText>
            </SelectButton>
          ))}
        </SelectButtonContainer>
        </Panel>


        <Panel> 
        <DetailText>Lei Orgânica da PSP</DetailText>
          <AudioContainer>
            <AudioLink href="/audio_psp_leiOrganica">
              <AudioIcon /> Ouvir Resumo da Matéria
            </AudioLink>
          </AudioContainer>
        <SelectButtonContainer>
          {quizzes.psp_leiOrganica_quiz.map(({ title, icon, disabled }) => (
            <SelectButton
              key={title}
              active={quizTopic === title}
              onClick={() => !disabled && goToQuizDetailsScreen(title)}
              disabled={disabled}
            >
              {icon}
              <SelectButtonText>{title}</SelectButtonText>
            </SelectButton>
          ))}
        </SelectButtonContainer>
        </Panel>

        <Panel>
        <DetailText>Prova Completa</DetailText>
        <SelectButtonContainer>
          {quizzes.psp_quiz_pc.map(({ title, icon, disabled }) => (
            <SelectButton
              key={title}
              active={quizTopic === title}
              onClick={() => !disabled && goToQuizDetailsScreen(title)}
              disabled={disabled}
            >
              {icon}
              <SelectButtonText>{title}</SelectButtonText>
            </SelectButton>
          ))}
        </SelectButtonContainer>
        </Panel>
          </>
        )}
          <br />
          {/* <h1 onClick={toggleTestThemePanel} style={{ cursor: 'pointer', textAlign: 'center' }}>
        Provas Físicas {isTestThemeExpanded ? '▲' : '▼'}
      </h1> */}
      <MenuTitle onClick={toggleTestThemePanel}>
      Provas Físicas {isTestThemeExpanded}
      </MenuTitle>
      <br />
      {isTestThemeExpanded && (
  <>
  <br />
  <Card2>
  <CardTitle>Plano de treino personalizado</CardTitle>
  <CardDescription>Plano de treino personalizado e ajustado à tua medida?</CardDescription>
  <br />
  <Button variant="outlined" color="secondary" onClick={ () => navigate("/consulta")}>
            Saber Mais
          </Button>
  </Card2>
  <br />
  <br />
    <DetailText>Candidatos Masculinos</DetailText>
        <img
          src={img_masculino}
          alt="Primeira imagem"
        />
        <br />
        <DetailText>Candidatos Femininos</DetailText>
        <img
          src={img_feminino}
          alt="Segunda imagem"
        />
        <br />
        <br />
        <CardContainer>
        <CardLink href="https://www.youtube.com/watch?v=o-3G8UwjS6U" target="_blank" rel="noopener noreferrer">
            <Card>
            
              <CardTitle>Salto em comprimento sem corrida</CardTitle>
              <CardDescription>Exemplo visual. A especificidade dos exercicios pode não coincidir com a prova em questão.</CardDescription>
              <VideoIcon href="https://www.youtube.com/watch?v=o-3G8UwjS6U" target="_blank" rel="noopener noreferrer">
                <FaYoutube />
              </VideoIcon>
            </Card>
            </CardLink>

            <CardLink href="https://www.youtube.com/watch?v=u6emyuz76Uk" target="_blank" rel="noopener noreferrer">
            <Card>
              <CardTitle>Circuito de agilidade (Illinois)</CardTitle>
              <CardDescription>Exemplo visual. A especificidade dos exercicios pode não coincidir com a prova em questão.</CardDescription>
              <VideoIcon href="https://www.youtube.com/watch?v=u6emyuz76Uk" target="_blank" rel="noopener noreferrer">
                <FaYoutube />
              </VideoIcon>
            </Card>
            </CardLink>

            <CardLink href="https://www.youtube.com/watch?v=-yJrdegCGN0" target="_blank" rel="noopener noreferrer">
            <Card>
              <CardTitle>Extensões de braços no solo</CardTitle>
              <CardDescription>Exemplo visual. A especificidade dos exercicios pode não coincidir com a prova em questão.</CardDescription>
              <VideoIcon href="https://www.youtube.com/watch?v=-yJrdegCGN0" target="_blank" rel="noopener noreferrer">
                <FaYoutube />
              </VideoIcon>
            </Card>
            </CardLink>

            <CardLink href="https://www.youtube.com/watch?v=aH9-Pped-AI" target="_blank" rel="noopener noreferrer">
            <Card>
              <CardTitle>Flexões de tronco à frente</CardTitle>
              <CardDescription>Exemplo visual. A especificidade dos exercicios pode não coincidir com a prova em questão.</CardDescription>
              <VideoIcon href="https://www.youtube.com/watch?v=aH9-Pped-AI" target="_blank" rel="noopener noreferrer">
                <FaYoutube />
              </VideoIcon>
            </Card>
            </CardLink>

            <CardLink href="https://www.youtube.com/watch?v=C-n9j7doo2w" target="_blank" rel="noopener noreferrer">
            <Card>
              <CardTitle>Corrida 1000 m planos</CardTitle>
              <CardDescription>Exemplo visual. A especificidade dos exercicios pode não coincidir com a prova em questão.</CardDescription>
              <VideoIcon href="https://www.youtube.com/watch?v=C-n9j7doo2w" target="_blank" rel="noopener noreferrer">
                <FaYoutube />
              </VideoIcon>
            </Card>
            </CardLink>

            {/* Adicione mais cards conforme necessário */}
          </CardContainer>
        </>
      )}
        <br />
        {/* <h1 onClick={toggleTest2ThemePanel} style={{ cursor: 'pointer', textAlign: 'center' }}>
          Provas Psicotécnicas {isTest2ThemeExpanded ? '▲' : '▼'}
        </h1> */}
        <MenuTitle onClick={toggleTest2ThemePanel}>
        Provas Psicotécnicas {isTest2ThemeExpanded}
      </MenuTitle>
       
        <br />
        {isTest2ThemeExpanded && (
          <>
           <Panel>
           <DetailText>Matrizes Progressivas</DetailText>
           <SelectButtonContainer>
             {quizzes.matrizes_progressivas.map(({ title, icon, disabled }) => (
               <SelectButton
                 key={title}
                 active={quizTopic === title}
                 onClick={() => !disabled && goToQuizDetailsScreen(title)}
                 disabled={disabled}
               >
                 {icon}
                 <SelectButtonText>{title}</SelectButtonText>
               </SelectButton>
             ))}

             
           </SelectButtonContainer>
           </Panel>

           <Panel>
           <DetailText>Raciocínio Verbal</DetailText>
           <SelectButtonContainer>
             {quizzes.raciocinio_verbal.map(({ title, icon, disabled }) => (
               <SelectButton
                 key={title}
                 active={quizTopic === title}
                 onClick={() => !disabled && goToQuizDetailsScreen(title)}
                 disabled={disabled}
               >
                 {icon}
                 <SelectButtonText>{title}</SelectButtonText>
               </SelectButton>
             ))}

             
           </SelectButtonContainer>
           </Panel>

           <Panel>
           <DetailText>Raciocínio Abstrato</DetailText>
           <SelectButtonContainer>
             {quizzes.raciocinio_abstrato.map(({ title, icon, disabled }) => (
               <SelectButton
                 key={title}
                 active={quizTopic === title}
                 onClick={() => !disabled && goToQuizDetailsScreen(title)}
                 disabled={disabled}
               >
                 {icon}
                 
                 <SelectButtonText>{title}</SelectButtonText>
               </SelectButton>
               
             ))}
             
           </SelectButtonContainer>
           </Panel>

           <Panel>
           <DetailText>Mini Jogos - Treino de Memoria</DetailText>
           (em testes)
           <SelectButtonContainer>
             {/* <a href="/jogomemoria">Jogo de Memoria Simples</a>
             <a href="/jogomemoriamapa">Jogo de Memoria com Mapa</a> */}
             {quizzes.geral_cg_quiz[0] && (
              <SelectButton
                key={quizzes.geral_cg_quiz[0].title}
                active={quizTopic === "teste"}
                onClick={() =>  navigate("/jogomemoria")}
                disabled={quizzes.geral_cg_quiz[0].disabled}
              >
                {quizzes.geral_cg_quiz[0].icon}
                <SelectButtonText>Jogo de Memória (Simples)</SelectButtonText>
              </SelectButton>
            )}
            {quizzes.geral_cg_quiz[6] && (
              <SelectButton
                key={quizzes.geral_cg_quiz[6].title}
                active={quizTopic === "teste"}
                onClick={() => !quizzes.geral_cg_quiz[6].disabled && navigate("/jogomemoriamapa")}
                disabled={quizzes.geral_cg_quiz[6].disabled}
              >
                {quizzes.geral_cg_quiz[6].icon}
                <SelectButtonText>Jogo de Memória com Mapa</SelectButtonText>
              </SelectButton>
            )}
           </SelectButtonContainer>
           </Panel>

           {/* <Panel>
           <DetailText>Raciocínio Espacial</DetailText>
           <SelectButtonContainer>
             {quizzes.raciocinio_espacial.map(({ title, icon, disabled }) => (
               <SelectButton
                 key={title}
                 active={quizTopic === title}
                 onClick={() => !disabled && goToQuizDetailsScreen(title)}
                 disabled={disabled}
               >
                 {icon}
                 <SelectButtonText>{title}</SelectButtonText>
               </SelectButton>
             ))}
           </SelectButtonContainer>
           </Panel> */}
           

           </>
           
        )}

            <Button
              variant="contained"
              startIcon={<WhatsAppIcon />}
              href="https://chat.whatsapp.com/KGON9DIMRoG54Vjela7FS3"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                backgroundColor: '#25D366', // Verde do WhatsApp
                color: 'white',
                '&:hover': {
                  backgroundColor: '#22bb5c', // Cor mais escura no hover
                },
              }}
            >
              Grupo de apoio
            </Button>
        {/* Modal de compra */}
        {showPurchaseReminder && (
          <ModalOverlay>
          <ModalContent>
            <h2>Desbloqueia o Acesso Completo!</h2>
            <br />
            <p>
              Por apenas <strong> 3,99€</strong>, garante o acesso ilimitado a todas as provas e
              materiais. <br /><br />
              Prepara-te com o melhor conteúdo para as tuas provas!<br /><br />
              <strong>Método já disponível:</strong>
            </p>
            <a href="/purchase" rel="noopener noreferrer">
          <StyledImage src={mbway} alt="Método de pagamento MB Way" />
        </a>
            <div style={{ marginTop: "15px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePurchaseNavigation}
                style={{ marginRight: "10px" }}
              >
                Prosseguir por €3,99
              </Button>
            </div>
            <div style={{ marginTop: "15px" }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setShowPurchaseReminder(false)}
              >
                Talvez mais tarde
              </Button>
            </div>
          </ModalContent>
        </ModalOverlay>
        )}

        {/* Popup de curtida no Facebook */}
        {showLikePopup && (
          <LikePopup onClose={() => setShowLikePopup(false)} />
        )}

      </CenterCardContainer>
    </PageCenter>
  );
};

export default QuizTopicsScreen;

