import React from "react";
import styled, { createGlobalStyle } from "styled-components";

// Estilo Global (podes ajustar conforme necessidade)
const GlobalStyle = createGlobalStyle`
  body {
    
    margin: 0 !important;
    padding: 0 !important;
    font-family: 'Arial', sans-serif !important;
    background-color: #00509e !important;
    color: #333 !important;
  }
`;

const PageWrapper = styled.div`
  margin-top: 40px;
  width: 100% !important;
  min-height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
`;

const ContentContainer = styled.div`
  max-width: 800px !important;
  width: 90% !important;
  background-color: #fff !important;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  padding: 30px !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1) !important;

  h1 {
    font-size: 1.8rem !important;
    color: #00509e !important;
    margin-bottom: 20px !important;
    text-align: center !important;
  }

  h2 {
    font-size: 1.2rem !important;
    margin-top: 20px !important;
    color: #333 !important;
  }

  p {
    font-size: 1rem !important;
    line-height: 1.5 !important;
    color: #555 !important;
    margin-bottom: 15px !important;
  }
`;

const TermsPage = () => {
  return (
    <>
      <GlobalStyle />
      <PageWrapper>
        <ContentContainer>
          <h1>Termos de Uso</h1>
          
          <p>
            Bem-vindo(a) à nossa plataforma. Ao utilizá-la, assumes que estás de acordo 
            com os termos e condições descritos aqui. Caso não concordes, por favor, 
            não utilizes este serviço.
          </p>

          <h2>1. Utilização da Plataforma</h2>
          <p>
            Esta plataforma oferece conteúdos e recursos para estudo e preparação de concursos
            das forças de segurança. Qualquer utilização em desacordo com a finalidade 
            pode resultar no cancelamento de acesso.
          </p>

          <h2>2. Conta de Utilizador</h2>
          <p>
            Para aceder a determinados recursos, poderás precisar de criar uma conta. És responsável
            por manter os teus dados de acesso seguros e por todas as atividades realizadas na tua conta.
          </p>

          <h2>3. Pagamentos e Assinaturas</h2>
          <p>
            O acesso premium é disponibilizado mediante pagamento único ou recorrente, conforme anunciado.
            Não nos responsabilizamos por taxas adicionais cobradas por terceiros (ex.: bancos, PayPal, etc.).
          </p>

          <h2>4. Direitos de Autor</h2>
          <p>
            Todo o conteúdo, incluindo textos, imagens e materiais de áudio, é protegido por direitos de autor.
            Não podes copiar ou distribuir sem autorização prévia.
          </p>

          <h2>5. Modificações</h2>
          <p>
            Podemos atualizar estes termos a qualquer momento. Caso isso ocorra, iremos notificar os utilizadores
            de forma a garantir a transparência.
          </p>

          <p>
            Em caso de dúvidas sobre estes termos, entra em contacto connosco. A tua utilização contínua 
            após alterações indica que aceitas as atualizações.
          </p>

        </ContentContainer>
      </PageWrapper>
    </>
  );
};

export default TermsPage;
