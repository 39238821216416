import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Typography, Checkbox, FormControlLabel } from "@mui/material";


const symbols = [
  "🚓", // Polícia
  "🛡️", // Escudo
  "🚔", // Carro de polícia
  "🔫", // Arma
  "🪖", // Capacete
  "🚨", // Sirene
];

const QuizTitle = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 20px !important;
  color: #00509E !important;
`;

const MemoryGame = () => {
  const [cards, setCards] = useState([]);
  const [flippedCards, setFlippedCards] = useState([]);
  const [matchedCards, setMatchedCards] = useState([]);
  const [attempts, setAttempts] = useState(0);
  const [gameOver, setGameOver] = useState(false);

  useEffect(() => {
    initializeGame();
  }, []);

  const initializeGame = () => {
    const shuffledCards = shuffle([...symbols, ...symbols]);
    setCards(shuffledCards.map((symbol, index) => ({ id: index, symbol, flipped: false })));
    setFlippedCards([]);
    setMatchedCards([]);
    setAttempts(0);
    setGameOver(false);
  };

  const shuffle = (array) => array.sort(() => Math.random() - 0.5);

  const handleCardClick = (id) => {
    if (flippedCards.length === 2 || matchedCards.includes(id)) return;

    const updatedFlipped = [...flippedCards, id];
    setFlippedCards(updatedFlipped);

    if (updatedFlipped.length === 2) {
      setAttempts((prev) => prev + 1);
      const [first, second] = updatedFlipped;
      if (cards[first].symbol === cards[second].symbol) {
        setMatchedCards((prev) => [...prev, first, second]);
      }
      setTimeout(() => setFlippedCards([]), 1000);
    }

    if (matchedCards.length + 2 === cards.length) {
      setGameOver(true);
    }
  };

  return (
    <GameContainer>
      <QuizTitle variant="h5">Jogo de memória</QuizTitle>
      <Stats>
        <p>Tentativas: {attempts}</p>
        {gameOver && <p>Parabéns! Você completou o jogo!</p>}
      </Stats>
      <Grid>
        {cards.map((card) => (
          <Card
            key={card.id}
            onClick={() => handleCardClick(card.id)}
            flipped={flippedCards.includes(card.id) || matchedCards.includes(card.id)}
          >
            {flippedCards.includes(card.id) || matchedCards.includes(card.id) ? (
              card.symbol
            ) : (
              "❓"
            )}
          </Card>
        ))}
      </Grid>
      <Button onClick={initializeGame}>Reiniciar</Button>
    </GameContainer>
  );
};

export default MemoryGame;

const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background: #f7f7f7;
`;

const Stats = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;

  p {
    margin: 5px 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 15px;
  width: 90%;
  max-width: 600px;

  @media (max-width: 600px) {
    gap: 10px;
  }
`;

const Card = styled.div`
  width: 100px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  background: ${({ flipped }) => (flipped ? "#4caf50" : "#ccc")};
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 600px) {
    width: 80px;
    height: 100px;
    font-size: 24px;
  }
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;
