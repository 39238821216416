import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';
import { useNavigate, Link } from 'react-router-dom';
import { Box, Typography, Alert, TextField } from '@mui/material';
import { styled } from '@mui/system';

const PageTitle = styled(Typography)`
  font-weight: bold !important;
  margin-bottom: 30px !important;
  color: #00509E;
`;

const FormContainer = styled(Box)({
  position: 'relative',
  background: '#fff',
  maxWidth: '320px',
  width: '100%',
  borderTop: '5px solid #00509E',
  boxShadow: '0 0 3px rgba(0, 0, 0, 0.1)',
  margin: '0 auto',
  padding: '40px',
});

const Background = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  backgroundImage: 'url("https://t3.ftcdn.net/jpg/03/52/01/04/360_F_352010422_mgLhwVNjjgMJaywUWypvDIVPftNy75Lk.jpg")', // URL da imagem de fundo
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
});

const StyledButton = styled('button')({
  background: '#00509E',
  color: '#fff',
  width: '100%',
  padding: '10px 15px',
  border: 'none',
  marginTop: '20px',
  cursor: 'pointer',
  '&:hover': {
    background: '#1e88e5',
  },
});

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alert, setAlert] = useState({ visible: false, severity: '', message: '' });
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setAlert({ visible: true, severity: 'error', message: 'Falha no login!' });
      setTimeout(() => setAlert({ visible: false, severity: '', message: '' }), 3000);
    }
  };

  return (
    <Background>
      {alert.visible && (
        <Alert sx={{ position: 'absolute', top: 20 }} severity={alert.severity}>
          {alert.message}
        </Alert>
      )}
      <FormContainer>
          <PageTitle variant="h4" gutterBottom>
            Login
          </PageTitle>
        <form onSubmit={handleLogin}>
          <TextField
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ marginBottom: '20px' }}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ marginBottom: '20px' }}
          />
          <StyledButton type="submit">Login</StyledButton>
        </form>
        <Typography variant="body2" align="left" style={{ marginTop: '10px' }}>
          Não tem conta? <Link to="/register">Regista-te aqui</Link>
        </Typography>
        <Typography variant="body2" align="left" style={{ marginTop: '10px' }}>
          Esqueceu-se da senha? <Link to="/reset-password">Recuperar senha</Link>
        </Typography>
      </FormContainer>
    </Background>
  );
};

export default Login;
