import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Função para rastrear eventos personalizados
export const trackEvent = (category, action, label) => {
  if (window.gtag) {
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
    });
  } else {
    console.warn("Google Analytics não está configurado corretamente.");
  }
};

// Hook para rastrear visualizações de página
export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag("event", "page_view", {
        page_path: location.pathname,
        page_title: document.title || "Sem título",
      });
    } else {
      console.warn("Google Analytics não está configurado corretamente.");
    }
  }, [location]);
};
