import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Confetti from "react-confetti"; // Instale com `npm install react-confetti`
import mapa from "../assets/images/mapa.jpg";
import mapa2 from "../assets/images/mapa2.jpg";
import mapa3 from "../assets/images/mapa3.jpg";
import mapa4 from "../assets/images/mapa4.jpg";

// Define as localizações disponíveis
const locations = [
  { name: "Esquadra", icon: "🚓" },
  { name: "Bombeiros", icon: "🚒" },
  { name: "Cine-Teatro", icon: "🎭" },
  { name: "Igreja", icon: "⛪" },
  { name: "Escola", icon: "🏫" },
  { name: "Junta de Freguesia", icon: "🏢" },
  { name: "Hospital", icon: "🏥" },
  { name: "Supermercado", icon: "🛒" },
  { name: "Farmácia", icon: "💊" },
  { name: "Correios", icon: "📮" },
];

const gridSize = 6;
const gameTime = 60;

const MapMemory = () => {
  const [map, setMap] = useState([]);
  const [gameItems, setGameItems] = useState([]);
  const [visible, setVisible] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [matchedItems, setMatchedItems] = useState([]);
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(gameTime);
  const [countdown, setCountdown] = useState(10);
  const [showPopup, setShowPopup] = useState(true);
  const [numElements, setNumElements] = useState(10);
  const [gameCompleted, setGameCompleted] = useState(false);
  const [mapBackground, setMapBackground] = useState("");

  useEffect(() => {
    if (!showPopup) initializeGame();
  }, [showPopup]);

  useEffect(() => {
    if (visible && countdown > 0) {
      const timer = setInterval(() => setCountdown((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    } else if (countdown === 0) {
      setVisible(false);
    }
  }, [visible, countdown]);

  useEffect(() => {
    if (!visible && timeLeft > 0 && !gameCompleted) {
      const timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [timeLeft, visible, gameCompleted]);

  // Função para inicializar o jogo e escolher um mapa aleatório
  const initializeGame = () => {
    // Lista de imagens de mapa
    const maps = [mapa, mapa2, mapa3, mapa4];
    const randomMap = maps[Math.floor(Math.random() * maps.length)];
    setMapBackground(randomMap); // Define o mapa aleatório

    // Inicializa o mapa com células vazias
    const emptyMap = Array(gridSize * gridSize).fill(null);
    const shuffledLocations = shuffle([...locations]);
    const selectedLocations = shuffledLocations.slice(0, numElements);
    const randomizedMap = placeLocationsOnMap(emptyMap, selectedLocations);

    setMap(randomizedMap);
    setGameItems(selectedLocations);
    setVisible(true);
    setMatchedItems([]);
    setScore(0);
    setTimeLeft(gameTime);
    setCountdown(10);
    setGameCompleted(false); // Reinicia o estado do jogo
  };

  // Função para embaralhar as localizações
  const shuffle = (array) => array.sort(() => Math.random() - 0.5);

  // Função para posicionar as localizações no mapa
  const placeLocationsOnMap = (map, locations) => {
    const newMap = [...map];
    locations.forEach((location) => {
      let position;
      do {
        position = Math.floor(Math.random() * newMap.length);
      } while (newMap[position]);
      newMap[position] = location;
    });
    return newMap;
  };

  // Função de clique no item da lista de itens
  const handleItemClick = (item) => {
    setSelectedItem(item === selectedItem ? null : item);
  };

  // Função para clique nas células do mapa
  const handleGridClick = (index) => {
    if (!selectedItem || matchedItems.includes(index)) return;

    if (map[index]?.name === selectedItem.name) {
      setMatchedItems((prev) => {
        const updatedMatches = [...prev, index];
        if (updatedMatches.length === gameItems.length) {
          setGameCompleted(true); // Marca o jogo como concluído
        }
        return updatedMatches;
      });
      setScore((prev) => prev + 1);
    }
    setSelectedItem(null);
  };

  return (
    <GameContainer>
      {showPopup && (
        <Popup>
          <PopupContent>
            <h2>Bem-vindo ao MapMemory!</h2>
            <p>
              Este é um jogo de memória para testar sua atenção e habilidade de
              memorização!
            </p>
            <p>
              <strong>Como funciona:</strong>
            </p>
            <p>
              Durante os primeiros 10 segundos, verás um mapa com diversos
              serviços e locais destacados, como esquadras, hospitais e escolas.
              Memoriza as localizações o melhor que puderes!
            </p>
            <p>
              Após o tempo de memorização, os ícones e nomes desaparecerão. A tua
              tarefa é clicar nos itens disponíveis na lista abaixo e posicioná-los
              corretamente no mapa.
            </p>
            <p>Tens 1 minuto para completar o desafio. Posiciona todos os itens corretamente para vencer o jogo!</p>
            <p>Boa sorte e divirta-se!</p>
            <SelectContainer>
              <label htmlFor="numElements">Número de Elementos:</label>
              <br />
              <Select
                id="numElements"
                value={numElements}
                onChange={(e) => setNumElements(parseInt(e.target.value, 10))}
              >
                {[...Array(10).keys()].map((n) => (
                  <option key={n + 1} value={n + 1}>
                    {n + 1}
                  </option>
                ))}
              </Select>
            </SelectContainer>
            <Button onClick={() => setShowPopup(false)}>Começar</Button>
          </PopupContent>
        </Popup>
      )}
      {!showPopup && !gameCompleted && (
        <>
          <Header>
            <p>Memorize as posições e clique nos itens para colocá-los nas células corretas!</p>
            <p>Pontuação: {score}</p>
            <p>Tempo Restante: {timeLeft}s</p>
            <Button onClick={() => {
              setShowPopup(true); // Exibe o popup novamente
              initializeGame(); // Reinicia o jogo e carrega um novo mapa
            }}>Reiniciar</Button>
          </Header>
          {visible && <Countdown>{countdown > 0 ? countdown : "Comece!"}</Countdown>}
          <Map className="map-container" style={{ backgroundImage: `url(${mapBackground})` }}>
            {map.map((item, index) => (
              <GridCell
                key={index}
                onClick={() => handleGridClick(index)}
                matched={matchedItems.includes(index)}
                selectedItem={!!selectedItem}
              >
                {item && visible && (
                  <Item>
                    <Icon>{item.icon}</Icon>
                    <Label>{item.name}</Label>
                  </Item>
                )}
                {matchedItems.includes(index) && (
                  <Matched>
                    <Icon>{item?.icon}</Icon>
                  </Matched>
                )}
              </GridCell>
            ))}
          </Map>
          <DropZone id="drop-zone">
            {gameItems.map((item, index) => (
              <DropItem
                key={index}
                onClick={() => handleItemClick(item)}
                selected={selectedItem?.name === item.name}
              >
                <Icon>{item.icon}</Icon>
                <Label>{item.name}</Label>
              </DropItem>
            ))}
          </DropZone>
        </>
      )}
      {gameCompleted && (
        <>
          <Confetti />
          <Popup>
            <PopupContent>
              <h2>Parabéns!</h2>
              <p>Você completou o jogo com sucesso!</p>
              <Button onClick={() => {
                setGameCompleted(false);
                setShowPopup(true); // Exibe o popup novamente
                initializeGame(); // Reinicia o jogo e carrega um novo mapa
              }}>
                Jogar Novamente
              </Button>
            </PopupContent>
          </Popup>
        </>
      )}
    </GameContainer>
  );
};

export default MapMemory;


const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #f5f5f5;
  padding: 20px;
  margin-top: 10px;

  @media (max-width: 600px) {
    padding: 10px;
    margin-top: 5px;
  }
`;

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background: white;
  padding: 20px 40px;
  border-radius: 8px;
  text-align: left;

  h2 {
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  @media (max-width: 600px) {
    padding: 15px;
    h2 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 16px;
  color: #333;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  p {
    font-size: 16px;
    margin: 8px 0;
    font-weight: 500;
    color: #555;

    &:first-child {
      font-size: 20px;
      font-weight: 600;
      color: #2c3e50;
    }

    &:nth-child(2) {
      font-size: 18px;
      color: #3498db;
    }

    &:nth-child(3) {
      font-size: 18px;
      color: #e74c3c;
    }
  }

  @media (max-width: 600px) {
    p {
      font-size: 14px;
    }

    &:first-child {
      font-size: 16px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      font-size: 16px;
    }
  }
`;

const Countdown = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #ff5722;
  margin-bottom: 12px;

  @media (max-width: 600px) {
    font-size: 30px;
  }
`;

const Map = styled.div`
  display: grid;
  grid-template-columns: repeat(${gridSize}, 1fr);
  grid-template-rows: repeat(${gridSize}, 1fr);
  gap: 8px;
  width: 80vw;
  height: 50vh;  // Ajuste a altura para se ajustar melhor à tela
  max-width: 800px;
  aspect-ratio: 1;
  background: url(${props => props.mapBackground}) no-repeat center center;
  background-size: cover;
  border: 2px solid #333;
  border-radius: 8px;
  opacity: 0.9;
  filter: brightness(0.9) contrast(1.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 600px) {
    width: 90vw;
    height: 40vh;
  }
`;

const GridCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${(props) => (props.matched ? "#90ee90" : "transparent")};
  cursor: ${(props) => (props.selectedItem ? "pointer" : "default")};
  border: ${(props) =>
    props.selectedItem
      ? "2px dashed rgba(0, 123, 255, 0.8)"
      : "none"};
  &:hover {
    border-color: ${(props) => (props.selectedItem ? "#0056b3" : "none")};
  }
  @media (max-width: 600px) {
    border: ${(props) =>
      props.selectedItem ? "2px dashed #007bff" : "none"};
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  z-index: 3;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const Icon = styled.div`
  font-size: 48px;
  margin-bottom: 5px;

  @media (max-width: 600px) {
    font-size: 36px;
  }
`;

const Label = styled.div`
  background: white;
  color: black;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 12px;
    padding: 3px 8px;
  }
`;

const Matched = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(144, 238, 144, 0.5);
  border-radius: 8px;
`;

const DropZone = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  margin-top: 12px;
  width: 80vw;  // Ajustar para não ultrapassar a largura da tela

  @media (max-width: 600px) {
    gap: 8px;
    margin-top: 10px;
    width: 90vw;
  }
`;

const DropItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  background: ${(props) => (props.selected ? "#007bff" : "#f1f1f1")};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.selected ? "#0056b3" : "#d1d1d1")};
  }

  @media (max-width: 600px) {
    padding: 6px;
  }
`;

const SelectContainer = styled.div`
  margin: 10px 0;
  text-align: center;
`;

const Select = styled.select`
  padding: 5px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 12px;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 600px) {
    padding: 6px 12px;
    font-size: 12px;
  }
`;

