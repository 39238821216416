

import { Topic } from '..'

export const psp_estatutos_7: Topic = {
  topic: 'Estatutos - PSP (7)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    {
      "question": "A condição policial caracteriza-se:",
      "choices": [
        "Pela sujeição a um regulamento estipulado pelo Ministério da Administração Interna;",
        "Pela subordinação à hierarquia de comando no Ministério;",
        "Pela subordinação ao interesse público;",
        "Pela subordinação ao interesse do Presidente da República."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pela subordinação ao interesse público;"
      ],
      "score": 10
    },
    {
      "question": "Os polícias devem dedicar-se ao serviço com:",
      "choices": [
        "Dedicação, zelo, competência, integridade de carácter e espírito de bem servir, utilizando e desenvolvendo de forma permanente a sua aptidão, competência e formação profissional.",
        "Aprumo, zelo, competência, integridade de carácter e espírito de bem servir, utilizando e desenvolvendo de forma permanente a sua aptidão, competência e formação profissional.",
        "Convicção, zelo, competência, integridade de carácter e espírito de bem servir, utilizando e desenvolvendo de forma permanente a sua aptidão, competência e formação profissional.",
        "Lealdade, zelo, competência, integridade de carácter e espírito de bem servir, utilizando e desenvolvendo de forma permanente a sua aptidão, competência e formação profissional."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Lealdade, zelo, competência, integridade de carácter e espírito de bem servir, utilizando e desenvolvendo de forma permanente a sua aptidão, competência e formação profissional."
      ],
      "score": 10
    },
    {
      "question": "Os polícias devem ter residência habitual na localidade onde predominantemente prestam serviço ou:",
      "choices": [
        "Em local que diste até 40 km daquela.",
        "Em local que diste até 50 km daquela.",
        "Em local que diste até 60 km daquela.",
        "Em local que diste até 55 km daquela."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Em local que diste até 50 km daquela."
      ],
      "score": 10
    },
    {
      "question": "São deveres especiais dos polícias:",
      "choices": [
        "Atuar sem discriminação em razão de ascendência, sexo, raça, língua, território de origem, religião, convicções políticas ou ideológicas, instrução, situação económica, condição social ou orientação sexual;",
        "Exibir previamente prova da sua qualidade quando, uniformizados, aplicarem medidas de polícia ou emitirem qualquer ordem ou mandado legítimo;",
        "Atuar com discriminação em razão de ascendência, sexo, raça, língua, território de origem, religião, convicções políticas ou ideológicas, instrução, situação económica, condição social ou orientação sexual;",
        "Nenhuma das hipóteses está correta."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Atuar sem discriminação em razão de ascendência, sexo, raça, língua, território de origem, religião, convicções políticas ou ideológicas, instrução, situação económica, condição social ou orientação sexual;"
      ],
      "score": 10
    },
    {
      "question": "O período anual de férias tem a duração de:",
      "choices": [
        "21 dias úteis;",
        "20 dias úteis;",
        "18 dias úteis;",
        "22 dias úteis."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "22 dias úteis."
      ],
      "score": 10
    },
    {
      "question": "As modalidades de licenças sem remuneração compreendem:",
      "choices": [
        "Licença sem remuneração de média duração;",
        "Licença sem remuneração de longa duração;",
        "Licença sem remuneração para acompanhamento de cônjuge falecido;",
        "Licença sem remuneração para o exercício de funções na União Europeia."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Licença sem remuneração de longa duração;"
      ],
      "score": 10
    },
    {
      "question": "O serviço da PSP é:",
      "choices": [
        "De caráter exclusivo e de serviço ao cidadão;",
        "De caráter obrigatório e rotativo;",
        "De caráter permanente e obrigatório;",
        "De serviço ao público."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De caráter permanente e obrigatório;"
      ],
      "score": 10
    },
    {
      "question": "O regime de turnos é:",
      "choices": [
        "Obrigatório, quando o trabalho é prestado nos sete dias da semana;",
        "Semanal prolongado, quando é prestado em todos os cinco dias úteis e no sábado ou em feriados;",
        "Semanal, quando é prestado de segunda-feira a domingo;",
        "Nenhuma das hipóteses está correta."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Nenhuma das hipóteses está correta."
      ],
      "score": 10
    },
    {
      "question": "Qual das alíneas não se encontra correta? Os polícias estão integrados, por ordem decrescente de hierarquia, nas seguintes carreiras e categorias:",
      "choices": [
        "Oficial de polícia, que compreende as categorias de superintendente-chefe, superintendente, intendente, subintendente, comissário e subcomissário; ",
        "Chefe de polícia, que compreende as categorias chefe coordenador, chefe principal e chefe;",
        "Agente de polícia, que compreende as categorias de agente coordenador, agente principal e agente.",
        "Agente provisório, sendo admitido ao curso de formação de agentes."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Agente provisório, sendo admitido ao curso de formação de agentes."
      ],
      "score": 10
    },
    {
      "question": "Os polícias podem encontrar-se numa das seguintes situações:",
      "choices": [
        "De licença;",
        "Aposentados;",
        "Adido;",
        "Fora do Ativo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Aposentados;"
      ],
      "score": 10
    },
    {
      "question": "Qual a posição a nível remuneratório de um Agente provisório?",
      "choices": [
        "Nível 1;",
        "Nível 3;",
        "Nível 4;",
        "Nível 2. "
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Nível 3;"
      ],
      "score": 10
    },
    {
      "question": "As normas de execução e de operacionalização dos instrumentos de mobilidade interna são aprovadas por despacho do membro do Governo responsável pela área da administração interna.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "As modalidades de licenças sem remuneração compreendem:",
      "choices": [
        "Licença sem remuneração de média duração;",
        "Licença sem remuneração de longa duração;",
        "Licença sem remuneração para acompanhamento de cônjuge falecido;",
        "Licença sem remuneração para o exercício de funções na União Europeia."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Licença sem remuneração de longa duração;"
      ],
      "score": 10
    },
    {
      "question": "Os polícias não se regem por código deontológico nem por regulamento disciplinar próprios.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Falso"
      ],
      "score": 10
    },
    {
      "question": "PGNTA",
      "choices": [
        "R1",
        "R2",
        "R3",
        "R4"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "RC"
      ],
      "score": 10
    },
    {
      "question": "É dever especial dos polícias garantir a proteção das vítimas de crimes, dos detidos e das pessoas que se encontrem sob a sua custódia ou proteção, no respeito pela honra e dignidade da pessoa humana.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Os polícias devem manter sempre as necessárias competências técnicas e as condições físicas e psíquicas exigíveis ao cumprimento da missão.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    },
    {
      "question": "Qual a posição a nível remuneratório de um Agente provisório?",
      "choices": [
        "Nível 1;",
        "Nível 3;",
        "Nível 4;",
        "Nível 2."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "RPCT"
      ],
      "score": 10
    },
    {
      "question": "O direito a férias é irrenunciável e o seu gozo efetivo não pode ser substituído por qualquer compensação económica, ainda que com o acordo do interessado. No ano civil de ingresso, os polícias:",
      "choices": [
        "Têm direito a dois dias e meio de férias por cada um dos meses completos de serviço efetivo prestado até 31 de dezembro desse ano.",
        "Podem gozar as suas férias, após três meses completos de efetividade de serviço.",
        "Têm direito a dois dias úteis de férias por cada um dos meses completos de serviço efetivo prestado até 31 de dezembro desse ano.",
        "Podem gozar as suas férias, após doze meses completos de efetividade de serviço."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Têm direito a dois dias úteis de férias por cada um dos meses completos de serviço efetivo prestado até 31 de dezembro desse ano."
      ],
      "score": 10
    },
    {
      "question": "A promoção a agente principal é feita por antiguidade, na sequência de procedimento de verificação do preenchimento das condições e sujeito ao número de vagas fixado.",
      "choices": [
        "Verdadeiro",
        "Falso"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Verdadeiro"
      ],
      "score": 10
    }
  ],
}
