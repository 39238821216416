

import { Topic } from '..'

export const psp_leiOrganica_3: Topic = {
  topic: 'Lei Orgânica - PSP (3)',
  level: 'Advanced',
  totalQuestions: 20,
  totalScore: 200,
  totalTime: 600,
  questions: [
    //2017
    {
      "question": "Os comandos territoriais de polícia compreendem",
      "choices": [
        "Serviços e subunidades.",
        "O comando, serviços.",
        "O comando, serviços e subunidades.",
        "O comando, comandos territoriais e subunidades."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O comando, serviços e subunidades."
      ],
      "score": 10
    },
    {
      "question": "Comando de subunidades",
      "choices": [
        "O comando das subunidades é exercido por um Superintendente-Chefe.",
        "O comando das subunidades é exercido por um Superintendente-Chefe, coadjuvado por um adjunto.",
        "O comando das subunidades é exercido por um comandante.",
        "O comando das subunidades é exercido por um comandante, coadjuvado por um adjunto."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O comando das subunidades é exercido por um comandante, coadjuvado por um adjunto."
      ],
      "score": 10
    },
    {
      "question": "A UEP compreende a seguinte subunidade operacional:",
      "choices": [
        "O Corpo de Intervenção.",
        "O Corpo de Operações Especiais.",
        "O Corpo de Segurança Pessoal e intelectual.",
        "Todas as respostas anteriores estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Corpo de Intervenção."
      ],
      "score": 10
    },
    {
      "question": "Instituto Superior de Ciências Policiais e Segurança Interna",
      "choices": [
        "O Instituto Superior de Ciências Policiais e Segurança Interna (ISCPSI) é um instituto policial de ensino superior universitário que tem por missão formar Chefes de polícia.",
        "O Instituto Superior de Ciências Policiais e Segurança Interna (ISCPSI) é um instituto policial de ensino superior universitário que tem por missão formar oficiais de polícia.",
        "O Instituto Superior de Ciências Policiais e Segurança Interna (ISCPSI) é um instituto policial de ensino superior universitário que tem por missão formar agentes de polícia.",
        "Todas as respostas anteriores estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Instituto Superior de Ciências Policiais e Segurança Interna (ISCPSI) é um instituto policial de ensino superior universitário que tem por missão formar oficiais de polícia."
      ],
      "score": 10
    },
    {
      "question": "Diretor nacional",
      "choices": [
        "O recrutamento para o cargo de diretor nacional é feito, por escolha, de entre superintendentes-chefes, ou indivíduos licenciados de reconhecida idoneidade e experiência profissional, vinculados ou não à Administração Pública.",
        "O provimento do cargo é feito mediante despacho conjunto do Primeiro Ministro e Presidente da República.",
        "O cargo é provi do em comissão de serviço por um período de quatro anos, renovável por iguais períodos.",
        "A renovação da comissão de serviço deverá ser comunicada ao interessado até 35 dias antes do seu termo, cessando a mesma automaticamente no final do respetivo período se o ministro da tutela não tiver manifestado expressamente a intenção de a renovar, caso em que o dirigente se manterá no exercício de funções de gestão corrente até à nomeação do novo titular do cargo."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O recrutamento para o cargo de diretor nacional é feito, por escolha, de entre superintendentes-chefes, ou indivíduos licenciados de reconhecida idoneidade e experiência profissional, vinculados ou não à Administração Pública."
      ],
      "score": 10
    },
    {
      "question": "Outros cargos de comando",
      "choices": [
        "O provimento dos cargos de comandante do CI, GOE, CSP, CIEXSS e do GOC é feito por despacho do diretor nacional, sob proposta do comandante da UEP",
        "O provimento dos cargos de comandante de divisão é feito por despacho do diretor nacional, sob proposta do respetivo comandante de unidade.",
        "O provimento dos cargos de comandante de esquadra é feito por despacho do respetivo comandante regional, metropolitano ou distrital de polícia.",
        "Todas as respostas anteriores estão corretas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as respostas anteriores estão corretas."
      ],
      "score": 10
    },
    {
      "question": "Constituem receitas da PSP:",
      "choices": [
        "As dotações atribuídas pelo Orçamento do Estado.",
        "As dotações atribuídas pelo Orçamento do Estado; O produto da venda de publicações e as quantias cobradas por atividades ou serviços prestados; Os juros dos depósitos bancários; As receitas próprias consignadas à PSP; Os saldos das receitas consignadas; Quaisquer outras receitas que lhe sejam atribuídas por lei, contrato ou a outro título.",
        "As dotações atribuídas pelo Orçamento do Estado; O produto da venda de publicações e as quantias cobradas por atividades ou serviços prestados.",
        "As dotações atribuídas pelo Orçamento do Estado; O produto da venda de publicações e as quantias cobradas por atividades ou serviços prestados; Os juros dos depósitos bancários."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "As dotações atribuídas pelo Orçamento do Estado; O produto da venda de publicações e as quantias cobradas por atividades ou serviços prestados; Os juros dos depósitos bancários; As receitas próprias consignadas à PSP; Os saldos das receitas consignadas; Quaisquer outras receitas que lhe sejam atribuídas por lei, contrato ou a outro título."
      ],
      "score": 10
    },
    {
      "question": "Constituem despesas da PSP",
      "choices": [
        "As que resultem de seguradoras decorrentes do funcionamento dos seus comandos e serviços e da atividade operacional , na prossecução das atribuições que lhe estão cometidas.",
        "As que resultem de seguradoras decorrentes do funcionamento dos seus órgãos e serviços e da atividade operacional , na prossecução das atribuições que lhe estão cometidas.",
        "As que resultem de encargos decorrentes do funcionamento dos seus órgãos e serviços e da atividade operacional , na prossecução das atribuições que lhe estão cometidas.",
        "As que resultem de encargos decorrentes do funcionamento dos seus comandos e serviços e da atividade operacional , na prossecução das atribuições que lhe estão cometidas."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "As que resultem de encargos decorrentes do funcionamento dos seus órgãos e serviços e da atividade operacional , na prossecução das atribuições que lhe estão cometidas."
      ],
      "score": 10
    },
    {
      "question": "A atividade da PSP pode implicar",
      "choices": [
        "A cobrança de despesas, mas não a aplicação de taxas a cargo de entidades que especialmente beneficiem com aquela atividade, nos termos a regular em diploma próprio.",
        "A aplicação de taxas e a cobrança de despesas a cargo de entidades que especialmente beneficiem com aquela atividade, nos termos a regular em diploma próprio.",
        "A aplicação de taxas, mas não a cobrança de despesas a cargo de entidades que especialmente beneficiem com aquela atividade, nos termos a regular em diploma próprio.",
        "A aplicação de taxas e a cobrança de despesas a cargo de entidades que que beneficiem com a que a atividade como função publica, nos termos a regular em diploma próprio."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A aplicação de taxas e a cobrança de despesas a cargo de entidades que especialmente beneficiem com aquela atividade, nos termos a regular em diploma próprio."
      ],
      "score": 10
    },
    // Quiz Lei Orgânica 2
    {
      "question": "A Polícia de Segurança de Segurança Pública, adiante designada por PSP, é:",
      "choices": [
        "Uma força de segurança, militarizada e armada e com natureza de serviço público.",
        "Uma força de segurança, uniformizada e com natureza de serviço público.",
        "Uma força de segurança, uniformizada e armada e com natureza de serviço público.",
        "Uma força de segurança, uniformizada e armada e com natureza de serviço privado."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Uma força de segurança, uniformizada e armada e com natureza de serviço público."
      ],
      "score": 10
    },
    {
      "question": "A PSP depende:",
      "choices": [
        "Do membro do Governo responsável pela área da administração interna e a sua organização é única para todo o território nacional e internacional.",
        "Da Ministra da Administração Interna.",
        "Do membro do Governo responsável pela área da administração interna e a sua organização é única para todo o território nacional.",
        "Do Diretor Nacional da PSP."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Do membro do Governo responsável pela área da administração interna e a sua organização é única para todo o território nacional."
      ],
      "score": 10
    },
    {
      "question": "A Polícia de Segurança Pública é dotada de:",
      "choices": [
        "Autonomia Administrativa e Financeira.",
        "Autonomia Financeira.",
        "Autonomia Administrativa.",
        "Autonomia Financeira e as dotações atribuídas pelo Orçamento do Estado."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Autonomia Administrativa."
      ],
      "score": 10
    },
    {
      "question": "Qual das alíneas não se encontra correta? São consideradas autoridades de polícia:",
      "choices": [
        "O director nacional.",
        "Os directores nacionais-adjuntos.",
        "O inspector nacional.",
        "O inspetor da Unidade Especial de Polícia."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O inspetor da Unidade Especial de Polícia."
      ],
      "score": 10
    },
    {
      "question": "Qual das alíneas não se encontra correta? A PSP compreende:",
      "choices": [
        "A Direcção Nacional.",
        "As unidades de polícia.",
        "Os estabelecimentos de ensino policial.",
        "O Conselho superior de polícia."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Conselho superior de polícia."
      ],
      "score": 10
    },
    {
      "question": "São estabelecimentos de ensino policial:",
      "choices": [
        "O Instituto Superior de Ciências Policiais e Segurança Interna.",
        "O Instituto Superior de Ciências Policiais e Segurança Interna e a Escola Prática de Polícia.",
        "A Escola Prática de Polícia.",
        "Nenhuma das alíneas está correta."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Instituto Superior de Ciências Policiais e Segurança Interna e a Escola Prática de Polícia."
      ],
      "score": 10
    },
    {
      "question": "A JSS é constituída por:",
      "choices": [
        "Dois médicos nomeados pelo director nacional, que designa, de entre eles, o presidente.",
        "Quatro médicos nomeados pelo director nacional, que designa, de entre eles, o presidente.",
        "Seis médicos nomeados pelo director nacional, que designa, de entre eles, o presidente.",
        "Três médicos nomeados pelo director nacional, que designa, de entre eles, o presidente."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Três médicos nomeados pelo director nacional, que designa, de entre eles, o presidente."
      ],
      "score": 10
    },
    {
      "question": "A unidade orgânica de operações e segurança compreende:",
      "choices": [
        "As áreas de operações, informações policiais, investigação criminal, cinotécnia, segurança privada, sistemas de informação e comunicações.",
        "As áreas de operações, informações policiais, investigação criminal, armas e explosivos, segurança pública e privada, sistemas de informação e comunicações.",
        "As áreas de operações, informações policiais, investigação criminal, armas e explosivos, segurança privada, sistemas de informação e comunicações.",
        "As áreas de operações, informações policiais, investigação criminal, violência doméstica, segurança privada, sistemas de informação e comunicações."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "As áreas de operações, informações policiais, investigação criminal, armas e explosivos, segurança privada, sistemas de informação e comunicações."
      ],
      "score": 10
    },
    {
      "question": "As subunidades dos comandos territoriais de polícia são:",
      "choices": [
        "A divisão policial.",
        "As subunidades dos comandos regionais.",
        "A área operacional e administrativa.",
        "Nenhuma das alíneas está correta."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A divisão policial."
      ],
      "score": 10
    },
    {
      "question": "Qual das alíneas não se encontra correta? A UEP compreende as seguintes subunidades operacionais:",
      "choices": [
        "O Corpo de Intervenção.",
        "O Grupo de Operações Especiais e de Intervenção Rápida.",
        "O Grupo Operacional Cinotécnico.",
        "O Corpo de Segurança Pessoal."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Grupo de Operações Especiais e de Intervenção Rápida."
      ],
      "score": 10
    },
    {
      "question": "O Grupo Operacional Cinotécnico (GOC) é:",
      "choices": [
        "Uma unidade especialmente feita para a aplicação de canídeos no quadro de competências da PSP.",
        "Uma subunidade especialmente preparada e vocacionada para a aplicação de canídeos no quadro de competências da PSP.",
        "Uma subunidade especialmente feita para a aplicação de canídeos no quadro de competências da PSP.",
        "Uma unidade especialmente preparada e vocacionada para a aplicação de canídeos no quadro de competências da PSP."
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Uma subunidade especialmente preparada e vocacionada para a aplicação de canídeos no quadro de competências da PSP."
      ],
      "score": 10
    }
  ],
}
