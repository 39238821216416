import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Paper, CircularProgress, Box, Alert } from '@mui/material';
import { doc, setDoc } from 'firebase/firestore';
import Confetti from 'react-confetti';
import { styled } from '@mui/system';

const FormContainer = styled(Paper)({
  padding: '2rem',
  width: '100%',
  maxWidth: '500px',
  margin: '0 auto',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
});

const Background = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  backgroundImage: 'url("https://source.unsplash.com/1600x900/?abstract")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const StyledButton = styled(Button)({
  marginTop: '1rem',
  background: '#00509E',
  '&:hover': {
    background: '#1e88e5',
  },
});

const initializeUserDocument = async (userId, name, address, phone, age) => {
  try {
    const userRef = doc(db, 'users', userId);
    await setDoc(userRef, {
      hasPurchased: false,
      name,
      address,
      phone,
      age,
    });
    console.log('User document initialized');
  } catch (error) {
    console.error('Error initializing user document: ', error);
  }
};

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [age, setAge] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');
    if (password !== confirmPassword) {
      setError('As passwords não correspondem');
      return;
    }
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;
      await initializeUserDocument(userId, name, address, phone, age);
      setSuccess(true);
      setTimeout(() => navigate('/'), 3000);
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError('O email já está registado. Por favor, utilize outro email.');
      } else {
        setError('Ocorreu um erro ao registar. Tente novamente.');
      }
      console.error('Error registering: ', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Background>
      {success && <Confetti />}
      <FormContainer>
      <Typography variant="h4" align="center" gutterBottom sx={{ color: '#00509E' }}>
        Registar Conta
      </Typography>
        {error && (
          <Alert severity="error" style={{ marginBottom: '1rem' }}>
            {error}
          </Alert>
        )}
        <form onSubmit={handleRegister}>
          <TextField
            label="Nome Completo"
            type="text"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={loading}
          />
          <TextField
            label="Morada"
            type="text"
            fullWidth
            margin="normal"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            disabled={loading}
          />
          <TextField
            label="Telemóvel"
            type="text"
            fullWidth
            margin="normal"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            disabled={loading}
          />
          <TextField
            label="Idade"
            type="number"
            fullWidth
            margin="normal"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            disabled={loading}
          />
          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
          />
          <TextField
            label="Confirmar Password"
            type="password"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            disabled={loading}
          />
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={loading}
          >
            {loading && <CircularProgress size={24} style={{ color: 'white' }} />}
            {!loading && success && 'Registado com sucesso!'}
            {!loading && !success && 'Registar'}
          </StyledButton>
        </form>
      </FormContainer>
    </Background>
  );
};

export default Register;
