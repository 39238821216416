// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'

export const psp_testeGeral_3: Topic = {
  topic: 'Teste Geral (3) - PSP',
  level: 'Advanced',
  totalQuestions: 50,
  totalScore: 500,
  totalTime: 1500,
  questions: [
    {
      "question": "O que significa a palavra 'redundante'?",
      "choices": [
        "Supérfluo",
        "Redondo",
        "Importante",
        "Redutor"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Supérfluo"
      ],
      "score": 10
    },
    {
      "question": "O que significa a palavra 'lenidade'?",
      "choices": [
        "Bravura",
        "Arte de trabalhar a lã",
        "Rugosidade",
        "Suavidade"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Suavidade"
      ],
      "score": 10
    },
    {
      "question": "De entre as hipóteses apresentadas, indique o antônimo de 'conciso'?",
      "choices": [
        "Abreviado",
        "Lacônico",
        "Convincente",
        "Prolixo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Prolixo"
      ],
      "score": 10
    },
    {
      "question": "Qual das frases seguintes tem a forma verbal adequada?",
      "choices": [
        "Ontem a PSP efetuou cinco detenções na cidade de Torres Novas",
        "A comunicação social, no próximo sábado, acompanhou uma equipa de Investigação Criminal da PSP",
        "Na semana passada nós vamos depor à Esquadra",
        "Ontem, elementos da PSP, abrirão um inquérito crime"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ontem a PSP efetuou cinco detenções na cidade de Torres Novas"
      ],
      "score": 10
    },
    {
      "question": "Qual das seguintes palavras contém erro na acentuação?",
      "choices": [
        "Miúdo",
        "Estávamos",
        "Veem-se",
        "Libertássem"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Libertássem"
      ],
      "score": 10
    },
    {
      "question": "'Ninguém' é:",
      "choices": [
        "Pronome possessivo",
        "Pronome demonstrativo",
        "Pronome relativo",
        "Pronome indefinido"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Pronome indefinido"
      ],
      "score": 10
    },
    {
      "question": "Indique a frase em que o seu adjetivo se encontra no Grau Superlativo Absoluto Analítico",
      "choices": [
        "O João é um aluno muito estudioso",
        "O João é um aluno estudioso",
        "O João é um aluno estudiosíssimo",
        "João é um aluno tão estudioso como o Rui"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O João é um aluno muito estudioso"
      ],
      "score": 10
    },
    {
      "question": "As palavras 'concelho' e 'conselho' são:",
      "choices": [
        "Palavras da mesma família",
        "Palavras homônimas",
        "Palavras homófonas",
        "Palavras sinônimas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Palavras homófonas"
      ],
      "score": 10
    },
    {
      "question": "'Quando' é:",
      "choices": [
        "Advérbio de lugar",
        "Advérbio de tempo",
        "Advérbio de modo",
        "Uma preposição"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Advérbio de tempo"
      ],
      "score": 10
    },
    {
      "question": "Qual das frases complexas seguintes contém uma oração subordinada concessiva?",
      "choices": [
        "Desde que haja bilhetes suficientes, podemos levar todos os alunos à estreia do espetáculo",
        "Logo que haja bilhetes suficientes, podemos levar todos os alunos à estreia do espetáculo",
        "Visto que o preço dos bilhetes é elevado, não posso assistir à estreia desse espetáculo",
        "Ainda que o preço dos bilhetes seja elevado, não posso perder a estreia desse espetáculo"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ainda que o preço dos bilhetes seja elevado, não posso perder a estreia desse espetáculo"
      ],
      "score": 10
    },
    {
      "question": "O atentado terrorista de Christchurch, na Nova Zelândia, que ocorreu em março de 2019, foi perpetrado por um cidadão australiano conotado como:",
      "choices": [
        "Separatista",
        "Anarquista",
        "Supremacista-branco",
        "Jihadista"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Supremacista-branco"
      ],
      "score": 10
    },
    {
      "question": "A APAV, Associação Portuguesa de Apoio à Vítima, é uma instituição sem fins lucrativos com o intuito de, entre outras incumbências:",
      "choices": [
        "Promover a proteção dos autores de práticas criminais, desde que declarado o arrependimento em sede de julgamento",
        "Promover a proteção e apoio a vítimas de infrações criminais",
        "Promover a proteção e apoio das vítimas de violência doméstica, predominantemente",
        "Todas as opções estão corretas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Promover a proteção e apoio a vítimas de infrações criminais"
      ],
      "score": 10
    },
    {
      "question": "Na sequência da pandemia COVID-19, em julho de 2020, Donald Trump, presidente dos Estados Unidos da América, deu início ao processo de saída daquele país da:",
      "choices": [
        "Organização Mundial de saúde",
        "Organização do Tratado Atlântico Norte",
        "Fundo Monetário Internacional",
        "Organização das Nações Unidas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Organização Mundial de saúde"
      ],
      "score": 10
    },
    {
      "question": "Qual dos seguintes países faz fronteira com a Áustria?",
      "choices": [
        "França",
        "Polônia",
        "Itália",
        "Croácia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Itália"
      ],
      "score": 10
    },
    {
      "question": "Sergei Skripal, ex-oficial de inteligência militar russo, alegado agente duplo para os serviços de inteligência britânicos, foi, em 2018:",
      "choices": [
        "Detido pela Sluzhba Vneshney Razvedki (SVR) e condenado a 30 anos de prisão pelo Supremo Tribunal de justiça Russo",
        "Nomeado Cavaleiro-Grã-Cruz pela Rainha de Inglaterra",
        "Autor de um livro best seller, com repercussão internacional, intitulado de 'a Toupeira'",
        "Assassinado por envenenamento"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Assassinado por envenenamento"
      ],
      "score": 10
    },
    {
      "question": "O novo Coronavírus, designado SARS-CoV-2, identificado pela primeira vez em dezembro de 2019, na China, na cidade de Wuhan, tem como sintomas mais comuns:",
      "choices": [
        "Febre, tosse, dor de garganta, manchas dermatológicas, dores musculares, entre outras",
        "Febre, tosse, dor de garganta, cansaço, dores musculares, entre outros",
        "Febre, tosse, dor de garganta, queda capilar, dores musculares, entre outras",
        "Todas as opções anteriores estão incorretas. O SARS-CoV-2 é um vírus totalmente assintomático"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Febre, tosse, dor de garganta, cansaço, dores musculares, entre outros"
      ],
      "score": 10
    },
    {
      "question": "No âmbito da cibersegurança, o phishing é:",
      "choices": [
        "O pacote de informação enviado de um servidor web para um programa de navegação, e depois reenviado sempre que este aceda ao servidor",
        "O programa que é introduzido num sistema, geralmente de forma encoberta, com a intenção de comprometer a confidencialidade, a integridade ou a disponibilidade dos dados da vítima, de aplicações ou do sistema operativo, ou perturbando a vitima",
        "Quando é redirecionado para websites falsos que solicitam informação pessoal",
        "O envio aos internautas de mensagens de correio eletrónico, com a aparência de terem origem em organizações financeiras credíveis, mas com ligações para falsos sítios web que replicam os originais, e nos quais são feitos pedidos de atualização de dados privados dos clientes"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O envio aos internautas de mensagens de correio eletrónico, com a aparência de terem origem em organizações financeiras credíveis, mas com ligações para falsos sítios web que replicam os originais, e nos quais são feitos pedidos de atualização de dados privados dos clientes"
      ],
      "score": 10
    },
    {
      "question": "Qual das afirmações seguintes está errada:",
      "choices": [
        "O 'Ecstasy', também conhecido por MDMA, tem como efeitos imediatos, entre outros, a diminuição do apetite e a insônia",
        "A 'Cannabis', deriva de uma planta denominada 'Cannabis sativa', também conhecido por 'erva', 'marijuana', 'haxixe', entre outras designações, dependendo da forma de consumo, tem como efeitos imediatos, entre outros, a sonolência e a fragmentação dos pensamentos",
        "A 'Cocaína', deriva do ópio, enquanto produto natural proveniente da papoila 'Papaver Somniferum', atua no sistema nervoso central causando sonolência e analgesia",
        "A 'Heroína' tem como efeitos imediatos, no sistema nervoso central, entre outros, a analgesia e a contração da pupila"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A 'Cocaína', deriva do ópio, enquanto produto natural proveniente da papoila 'Papaver Somniferum', atua no sistema nervoso central causando sonolência e analgesia"
      ],
      "score": 10
    },
    {
      "question": "A cortiça é um dos produtos naturais mais característicos de Portugal. De que árvore é produzida a cortiça?",
      "choices": [
        "Oliveira",
        "Eucalipto",
        "Pinheiro",
        "Sobreiro"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Sobreiro"
      ],
      "score": 10
    },
    {
      "question": "São Órgãos de Soberania:",
      "choices": [
        "O Presidente da República, a Assembleia da República, o Primeiro-Ministro e os Tribunais",
        "O Presidente da República, o Presidente da Assembleia da República, o Governo e os Tribunais",
        "O Presidente da República, a Assembleia da República, o Governo e os Tribunais",
        "O Presidente da República, o Presidente da Assembleia da República, o Primeiro-Ministro e os Tribunais"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O Presidente da República, a Assembleia da República, o Governo e os Tribunais"
      ],
      "score": 10
    },
    {
      "question": "O mandato do Presidente da República tem a duração de:",
      "choices": [
        "4 Anos e termina com a eleição do novo Presidente",
        "4 Anos e termina com a posse do novo Presidente",
        "5 Anos e termina com a posse do novo Presidente",
        "5 Anos e termina com a eleição do novo Presidente"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "5 Anos e termina com a posse do novo Presidente"
      ],
      "score": 10
    },
    {
      "question": "O Governo é constituído pelo:",
      "choices": [
        "Primeiro-Ministro, pelos Ministros e pelos Secretários e Subsecretários de Estado",
        "Primeiro-Ministro, pelos Ministros, pelos Secretários de Estado e pelos Deputados",
        "Presidente da Assembleia da República, pelo Primeiro-Ministro, pelos Ministros e pelos Secretários de Estado",
        "Presidente da Assembleia da República, pelo Primeiro-Ministro, pelos Ministros, pelos Secretários de Estado e pelos Deputados"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Primeiro-Ministro, pelos Ministros e pelos Secretários e Subsecretários de Estado"
      ],
      "score": 10
    },
    {
      "question": "Além do Tribunal Constitucional, existem as seguintes categorias de tribunais:",
      "choices": [
        "O Supremo Tribunal de Justiça e os tribunais judiciais de primeira e de segunda instância",
        "O Supremo Tribunal Administrativo e os demais tribunais administrativos e fiscais",
        "O Tribunal de Contas",
        "Todas as opções estão corretas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as opções estão corretas"
      ],
      "score": 10
    },
    {
      "question": "As medidas de Polícia são:",
      "choices": [
        "As previstas na lei, devendo ser utilizadas para além do estritamente necessário",
        "As previstas nos bons costumes, não devendo ser utilizadas para além do estritamente necessário",
        "As previstas na Constituição, não devendo ser utilizadas para além do estritamente necessário",
        "As previstas na lei, não devendo ser utilizadas para além do estritamente necessário"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "As previstas na lei, não devendo ser utilizadas para além do estritamente necessário"
      ],
      "score": 10
    },
    {
      "question": "Todos os seres humanos nascem:",
      "choices": [
        "Livres e iguais nos seus deveres",
        "Livres e iguais em dignidade e em direitos",
        "Livres e iguais em dignidade e com alguns direitos",
        "Livres e iguais apenas em dignidade"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Livres e iguais em dignidade e em direitos"
      ],
      "score": 10
    },
    {
      "question": "Todos os indivíduos têm direito:",
      "choices": [
        "Ao reconhecimento junto da sua Comunidade da sua personalidade jurídica",
        "Ao reconhecimento por parte do Governo da sua personalidade jurídica",
        "Ao reconhecimento em todos os lugares da sua personalidade jurídica",
        "Ao reconhecimento perante os Tribunais da sua personalidade jurídica"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ao reconhecimento em todos os lugares da sua personalidade jurídica"
      ],
      "score": 10
    },
    {
      "question": "Ninguém pode ser arbitrariamente:",
      "choices": [
        "Preso",
        "Detido",
        "Exilado",
        "Todas as opções estão corretas"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Todas as opções estão corretas"
      ],
      "score": 10
    },
    {
      "question": "Toda a pessoa tem o direito de:",
      "choices": [
        "Livremente circular e escolher a sua residência no interior de um Estado",
        "Livremente circular e escolher a sua residência em qualquer local",
        "Circular com a condicionante imposta por cada Estado e aí escolher a sua residência",
        "Com exceção dos criminosos, livremente circular e escolher a sua residência no interior de um Estado"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Livremente circular e escolher a sua residência no interior de um Estado"
      ],
      "score": 10
    },
    {
      "question": "Toda a pessoa, individual ou coletivamente:",
      "choices": [
        "Tem direito ao respeito",
        "Tem direito a uma casa",
        "Tem direito à propriedade",
        "Tem direito de pronúncia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Tem direito à propriedade"
      ],
      "score": 10
    },
    {
      "question": "Nos termos do Artigo 5.º da Declaração Universal dos Direitos Humanos",
      "choices": [
        "Apenas em caso de terrorismo os cidadãos poderão ser submetidos a tortura e a penas ou tratamentos cruéis, desumanos ou degradantes",
        "Qualquer cidadão poderá ser submetido a tortura nos casos de criminalidade violenta ou altamente organizada com o único objetivo de obter prova",
        "Ninguém será submetido a tortura nem a penas ou tratamentos cruéis, desumanos ou degradantes",
        "Apenas em caso de terrorismo os cidadãos poderão ser submetidos a tortura mas não a penas ou tratamentos cruéis, desumanos ou degradantes"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Ninguém será submetido a tortura nem a penas ou tratamentos cruéis, desumanos ou degradantes"
      ],
      "score": 10
    },
    {
      "question": "A Declaração Universal dos Direitos Humanos prevê que 'toda pessoa vítima de perseguição tem o direito de procurar e de gozar asilo em outros países.' A exceção a esse direito, também descrita no documento, ocorre quando",
      "choices": [
        "A perseguição tiver caráter político ou partidário",
        "O indivíduo solicitar asilo em país em conflito como o de origem",
        "A pessoa tiver participado ou incitado manifestações públicas",
        "A perseguição for legitimamente motivada por crimes de direito comum"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A perseguição for legitimamente motivada por crimes de direito comum"
      ],
      "score": 10
    },
    {
      "question": "Segundo o artigo 30.º da DUDH:",
      "choices": [
        "Nenhuma disposição da presente Declaração pode ser interpretada de maneira a envolver para qualquer Estado, agrupamento ou indivíduo o direito de se entregar a alguma atividade ou de praticar algum ato destinado a destruir os direitos e liberdades aqui enunciados",
        "Com exceção das disposições inerentes ao direito à vida, à liberdade e à segurança pessoal, nenhuma outra disposição da Declaração pode ser interpretada de maneira a envolver para qualquer Estado, agrupamento ou indivíduo o direito de se entregar a alguma atividade ou de praticar algum ato destinado a destruir os direitos e liberdades aqui enunciados",
        "Qualquer disposição da presente Declaração deve ser interpretada de maneira a envolver para qualquer Estado, agrupamento ou indivíduo o direito de se entregar a alguma atividade ou de praticar algum ato destinado a destruir os direitos e liberdades aqui enunciados",
        "Apenas algumas disposições da presente Declaração podem ser interpretadas de maneira a envolver para qualquer Estado, agrupamento ou indivíduo o direito de se entregar a alguma atividade ou de praticar algum ato destinado a destruir os direitos e liberdades aqui enunciados"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Nenhuma disposição da presente Declaração pode ser interpretada de maneira a envolver para qualquer Estado, agrupamento ou indivíduo o direito de se entregar a alguma atividade ou de praticar algum ato destinado a destruir os direitos e liberdades aqui enunciados"
      ],
      "score": 10
    },
    {
      "question": "A Polícia de Segurança Pública (PSP) depende do membro do Governo responsável pela",
      "choices": [
        "Área da administração pública",
        "Área da justiça",
        "Área da administração interna",
        "Área da defesa nacional"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Área da administração interna"
      ],
      "score": 10
    },
    {
      "question": "Segundo a Lei n.º 53/2007, de 31 de agosto, a Polícia de Segurança de Segurança Pública (PSP) define-se como",
      "choices": [
        "Um corpo superior de polícia criminal organizado hierarquicamente, dotado de autonomia administrativa",
        "Um corpo superior de polícia criminal organizado hierarquicamente, não dotado de autonomia administrativa",
        "Uma força de segurança de natureza militar, constituída por militares organizados num corpo especial de tropas e dotada de autonomia administrativa",
        "Uma força de segurança, uniformizada e armada, com natureza de serviço público e dotada de autonomia administrativa"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Uma força de segurança, uniformizada e armada, com natureza de serviço público e dotada de autonomia administrativa"
      ],
      "score": 10
    },
    {
      "question": "De acordo com a Lei Orgânica em vigor, constituem atribuições da Polícia de Segurança Pública (PSP)",
      "choices": [
        "Garantir a ordem e a tranquilidade públicas e a segurança e a proteção das pessoas e dos bens, apenas quando for solicitada a sua intervenção",
        "Prevenir a criminalidade em geral e coordenar as demais forças e serviços de segurança",
        "Garantir a ordem e a tranquilidade públicas e a segurança e a proteção das pessoas e dos bens",
        "Dirimir conflitos de natureza privada"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Garantir a ordem e a tranquilidade públicas e a segurança e a proteção das pessoas e dos bens"
      ],
      "score": 10
    },
    {
      "question": "Nos termos do artigo 9.º da Lei Orgânica da PSP (Lei n.º 53/2017, de 31 de agosto), considera-se uma força pública",
      "choices": [
        "O efetivo mínimo de um agente em missão de serviço",
        "O efetivo mínimo de um agente, mesmo fora de serviço",
        "O efetivo mínimo de dois agentes em missão de serviço",
        "O efetivo mínimo de dois agentes, mesmo fora de serviço"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "O efetivo mínimo de dois agentes em missão de serviço"
      ],
      "score": 10
    },
    {
      "question": "Em situações de normalidade institucional a PSP tem por missão:",
      "choices": [
        "Assegurar a legalidade democrática, garantir a segurança interna e os direitos dos cidadãos, nos termos da Constituição e da lei",
        "Assegurar a legalidade democrática, garantir a segurança interna, garantir a segurança externa e os direitos dos cidadãos portugueses apenas, nos termos da Constituição e da lei",
        "Assegurar a legalidade democrática, garantir a segurança interna, garantir a segurança externa e os direitos dos cidadãos, nos termos da Constituição",
        "Assegurar a legalidade democrática, garantir a segurança interna, garantir a segurança externa, os direitos dos cidadãos portugueses, nos termos da Constituição e da lei"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Assegurar a legalidade democrática, garantir a segurança interna e os direitos dos cidadãos, nos termos da Constituição e da lei"
      ],
      "score": 10
    },
    {
      "question": "Atualmente a Polícia de Segurança Pública possui a seguinte estrutura:",
      "choices": [
        "Estado-Maior, unidades de polícia e estabelecimentos de ensino policial",
        "Direção Nacional, unidades de polícia e estabelecimentos de ensino policial",
        "Comando Geral, comandos metropolitanos e estabelecimentos de ensino policial",
        "Comando Geral, comandos distritais e estabelecimentos de ensino policial"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Direção Nacional, unidades de polícia e estabelecimentos de ensino policial"
      ],
      "score": 10
    },
    {
      "question": "As subunidades dos comandos territoriais de Polícia da PSP são:",
      "choices": [
        "O comando, a divisão policial e a esquadra",
        "A divisão policial e a esquadra",
        "O destacamento e a esquadra",
        "A esquadra e o posto"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "A divisão policial e a esquadra"
      ],
      "score": 10
    },
    {
      "question": "A PSP pode efetuar serviços especiais:",
      "choices": [
        "Mediante solicitação, que, após serem autorizados pela entidade competente, são remunerados pela PSP, nos termos que forem regulamentados",
        "Não podendo o pessoal da PSP ser nomeado em comissão de serviço para organismos internacionais ou países estrangeiros",
        "Podendo manter pessoal com funções policiais em organismos de interesse público, em condições definidas por portaria do ministro da tutela. O pessoal nestas condições deixa de cumprir, para efeitos de ordem pública, as diretivas do comando com jurisdição na respetiva área",
        "Mediante solicitação, que, após serem autorizados pela entidade competente, são remunerados pelos respetivos requisitantes nos termos que forem regulamentados"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Mediante solicitação, que, após serem autorizados pela entidade competente, são remunerados pelos respetivos requisitantes nos termos que forem regulamentados"
      ],
      "score": 10
    },
    {
      "question": "A Escola Prática de Polícia (EPP) é um estabelecimento de ensino policial, na dependência do diretor nacional, que tem por missão ministrar cursos e estágios de formação, aperfeiçoamento e atualização a:",
      "choices": [
        "A Oficiais, Agentes e Chefes, promovendo o seu aperfeiçoamento permanente e realizar, coordenar ou colaborar em projetos de investigação e desenvolvimento no domínio das ciências policiais",
        "Agentes e Chefes, e de especialização para todo o pessoal da PSP: C. A Oficiais de polícia, e de especialização para todo o pessoal da PSP no domínio das ciências policiais",
        "A Oficiais de polícia, e de especialização para todo o pessoal da PSP no domínio das ciências policiais",
        "A Agentes, e de especialização para todo o pessoal da PSP e Chefes, promovendo o seu aperfeiçoamento permanente e realizar, coordenar ou colaborar em projetos de investigação e desenvolvimento no domínio das ciências policiais"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Agentes e Chefes, e de especialização para todo o pessoal da PSP: C. A Oficiais de polícia, e de especialização para todo o pessoal da PSP no domínio das ciências policiais"
      ],
      "score": 10
    },
    {
      "question": "O artigo 7.º do Estatuto da PSP (Decreto-Lei n.º 243/2015, de 19 de outubro) prevê que os policiais estão sujeitos a um regime de continências e honras policiais próprio aprovado por portaria do ministro da tutela. É também definido neste artigo que as normas relativas a ordem unida, apresentação e aprumo são aprovadas por despacho",
      "choices": [
        "Do ministro da defesa",
        "Do ministro da administração interna",
        "Do diretor nacional",
        "Dos diretores nacionais-adjuntos"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Do diretor nacional"
      ],
      "score": 10
    },
    {
      "question": "Em conformidade com o Estatuto da PSP em vigência, recai sobre o pessoal com funções policiais da PSP o dever de disponibilidade, o que implica que",
      "choices": [
        "Os policiais devem manter permanente disponibilidade para o serviço, ainda que com sacrifício dos interesses pessoais",
        "Os policiais devem manter permanente disponibilidade para o serviço, desde que não implique sacrifício dos interesses pessoais",
        "Os policiais devem cumprir unicamente o horário de serviço para qual se encontram devidamente escalados",
        "Nenhuma afirmação anterior está correta"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Os policiais devem manter permanente disponibilidade para o serviço, ainda que com sacrifício dos interesses pessoais"
      ],
      "score": 10
    },
    {
      "question": "O Estatuto da PSP estabelece que os policiais têm direito ao uso e porte de arma e munições de qualquer tipo",
      "choices": [
        "Desde que distribuídas pelo Estado, e estão sujeitos a um plano de formação e de certificação constituído por prova teórica e prática de tiro",
        "Desde que distribuídas pelo Estado, ficando o treino de tiro à responsabilidade dos mesmos a efetuar em clubes de tiro certificados",
        "Desde que adquiridas pelos próprios, ficando o treino de tiro à responsabilidade dos mesmos a efetuar em clubes de tiro certificados",
        "Nenhuma das afirmações anteriores está correta"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Desde que distribuídas pelo Estado, e estão sujeitos a um plano de formação e de certificação constituído por prova teórica e prática de tiro"
      ],
      "score": 10
    },
    {
      "question": "Atualmente a carreira de agente de polícia da PSP compreende as categorias:",
      "choices": [
        "De agente principal e de agente",
        "De agente principal, de agente e de guarda",
        "De agente coordenador, de agente principal e de agente",
        "De agente ajudante e de agente"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "De agente coordenador, de agente principal e de agente"
      ],
      "score": 10
    },
    {
      "question": "Em termos de formação policial, os elementos com funções policiais são obrigados a frequentar, anualmente, no mínimo",
      "choices": [
        "1 hora de formação policial ou de outras formações não reconhecidas como de interesse para as competências da PSP",
        "15 horas de formação policial ou de outras formações reconhecidas como de interesse para as competências da PSP",
        "90 horas de formação policial ou de outras formações reconhecidas como de interesse para as competências da PSP",
        "Nenhuma das afirmações anteriores está correta"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "15 horas de formação policial ou de outras formações reconhecidas como de interesse para as competências da PSP"
      ],
      "score": 10
    },
    {
      "question": "Estatuto da PSP em vigor estabelece os limites máximos de idade para passagem à situação de pré-aposentação para os elementos com funções policiais, encontrando-se assim definidos",
      "choices": [
        "55 anos para todas as carreiras",
        "62 anos para a categoria de superintendente-chefe e 60 anos para as restantes categorias e carreiras",
        "65 anos para todas as carreiras",
        "70 anos para todas as carreiras"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "62 anos para a categoria de superintendente-chefe e 60 anos para as restantes categorias e carreiras"
      ],
      "score": 10
    },
    {
      "question": "No Parlamento Europeu, o número de deputados por país é, aproximadamente, proporcional",
      "choices": [
        "À área geográfica de cada país",
        "Ao PIB (produto interno bruto) de cada país",
        "À população de cada país",
        "À antiguidade de cada país na União Europeia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "À população de cada país"
      ],
      "score": 10
    },
    {
      "question": "Dos países assinalados, qual faz parte da União Europeia?",
      "choices": [
        "Suíça",
        "Malta",
        "Reino Unido",
        "Turquia"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Malta"
      ],
      "score": 10
    },
    {
      "question": "Os seis (6) países fundadores da atual União Europeia são",
      "choices": [
        "Alemanha, Bélgica, França, Itália, Luxemburgo e Países Baixos",
        "Alemanha, Bélgica, França, Luxemburgo, Países Baixos e Espanha",
        "Alemanha, Bélgica, Luxemburgo, Países Baixos, Espanha e Portugal",
        "Alemanha, Bélgica, Luxemburgo, Países Baixos, Itália"
      ],
      "type": "MCQs",
      "correctAnswers": [
        "Alemanha, Bélgica, França, Itália, Luxemburgo e Países Baixos"
      ],
      "score": 10
    }
  ],
}
