import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { auth } from '../firebase/firebaseConfig';
import updatePurchaseStatus from '../firebase/updatePurchaseStatus';
import { Container, Typography, Button, Collapse, TextField } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import axios from 'axios';
import mbway from '../assets/images/mbway.png';

// Estilo Global
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0 !important;
    padding: 0 !important;
    font-family: 'Roboto', sans-serif !important;
    background-color: #00509E !important;
    color: #333 !important;
    min-height: 100vh !important;
    display: flex !important;
    flex-direction: column !important;
  }
`;

// Envolve toda a página para centralizar o conteúdo
const PurchaseWrapper = styled.div`
  margin-top:60px;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  flex-grow: 1 !important;
  padding: 20px !important;
`;

// Container principal (cartão)
const StyledContainer = styled(Container)`
  background-color: #fff !important;
  border-radius: 12px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  max-width: 850px !important;
  width: 100% !important;
  padding: 30px !important;
  text-align: center !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
`;

// Título estilizado
const StyledTitle = styled(Typography)`
  && {
    font-size: 2rem !important;
    font-weight: bold !important;
    margin-bottom: 20px !important;
    color: #00509E !important;
  }
`;

// Subtítulo ou texto de introdução
const IntroText = styled(Typography)`
  && {
    margin-bottom: 20px !important;
    color: #555 !important;
  }
`;

// Tabela de recursos
const FeatureTable = styled.table`
  width: 100% !important;
  margin: 20px 0 !important;
  border-collapse: collapse !important;
  font-size: 0.95rem !important;

  thead {
    background-color: #EDEDED !important;
    th {
      padding: 12px !important;
      font-weight: bold !important;
      text-align: center !important;
      color: #333 !important;
    }
  }

  tbody {
    tr {
      &:nth-of-type(even) {
        background-color: #f9f9f9 !important;
      }
    }
    td {
      padding: 10px !important;
      text-align: center !important;
    }
  }

  /* Ajuste mobile-first */
  @media (max-width: 600px) {
    font-size: 0.85rem !important;
    th, td {
      padding: 8px !important;
    }
  }
`;

// Container para os métodos de pagamento
const PaymentMethodsWrapper = styled.div`
  margin-top: 40px !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;

  @media (min-width: 600px) {
    flex-direction: row !important;
    justify-content: center !important;
    gap: 40px !important;
  }
`;

// Botão de MBWAY
const MbwayButton = styled(Button)`
  background-color: transparent !important;
  color: #000 !important;
  border-radius: 6px !important;
  font-weight: bold !important;
  background-image: url(${mbway}) !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  width: 220px !important;
  height: 60px !important;
  margin-bottom: 20px !important;

  &:hover {
    opacity: 0.8 !important;
  }
`;

// Caixa de colapso do MBWAY
const MbwayCollapse = styled(Collapse)`
  width: 100% !important;
  margin-top: 10px !important;
  .MuiTextField-root {
    margin-bottom: 10px !important;
  }
  .MuiButton-root {
    margin-top: 10px !important;
  }

  @media (min-width: 600px) {
    width: 300px !important;
  }
`;

// Wrapper do botão PayPal
const PayPalButtonWrapper = styled.div`
  margin-top: 20px !important;
  width: 300px !important;
  @media (max-width: 600px) {
    width: 100% !important;
  }
`;

// Botão de suporte via WhatsApp
const WhatsAppButton = styled(Button)`
  && {
    background-color: #25D366 !important; /* Verde do WhatsApp */
    color: #fff !important;
    border-radius: 6px !important; 
    margin-bottom: 20px !important;
    &:hover {
      background-color: #22bb5c !important; /* Cor mais escura ao passar o mouse */
    }
  }
`;

const Purchase = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [paypalExpanded, setPaypalExpanded] = useState(false);
  const [mbwayExpanded, setMbwayExpanded] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const isPaymentSuccess = new URLSearchParams(location.search).get("success");
    if (isPaymentSuccess === "true") {
      activatePremium();
    }
  }, [location]);

  const activatePremium = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userId = user.uid;
        await updatePurchaseStatus(userId);
        alert("Compra realizada com sucesso! Status premium ativado.");
        navigate('/dashboard');
      } else {
        alert("Usuário não autenticado. Por favor, faça login novamente.");
        navigate('/login');
      }
    } catch (error) {
      console.error("Erro ao processar a compra: ", error);
      alert("Ocorreu um erro ao ativar o status premium.");
    }
  };

  const handleMbwayPayment = async () => {
    if (!phoneNumber) {
      alert('Por favor, insira um número de telefone válido.');
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      alert('Usuário não autenticado. Por favor, faça login.');
      navigate('/login');
      return;
    }

    const payload = {
      userId: user.uid,
      telemovel: phoneNumber,
      nome: user.name || 'Usuário',
      email: user.email,
      valor: 3.99,
    };

    setLoading(true);
    try {
      const response = await axios.post(
        'https://eqe-n33r864zg-picpintos-projects.vercel.app/api/mbway',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Resposta do Middleware:', response.data);
      alert('Pagamento MB WAY iniciado com sucesso. Verifique seu MB WAY.');
    } catch (error) {
      console.error('Erro ao processar o pagamento MB WAY:', error);
      alert('Erro ao iniciar o pagamento MB WAY. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const handlePayPalApprove = async (data, actions) => {
    try {
      await actions.order.capture();
      activatePremium();
    } catch (error) {
      console.error("Erro ao processar a compra:", error);
    }
  };

  return (
    <>
      <GlobalStyle />
      <PurchaseWrapper>
        <StyledContainer>
          <StyledTitle variant="h4" gutterBottom>
            Acesso Premium
          </StyledTitle>
          <IntroText variant="body1">
            Tem acesso completo a todos os conteúdos e funcionalidades exclusivas da plataforma.
          </IntroText>

          {/* Botão de suporte via WhatsApp */}
          

          {/* Tabela de Recursos */}
          <FeatureTable>
            <thead>
              <tr>
                <th>Recursos</th>
                <th>Gratuito</th>
                <th>Premium</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Custo (Pagamento único)</td>
                <td>-</td>
                <td>3,99 €</td>
              </tr>
              <tr>
                <td>Acesso ao conteúdo de áudio</td>
                <td>❌</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Acesso ao conteúdo básico</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Relatórios detalhados</td>
                <td>✔️</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Estatísticas por temas</td>
                <td>❌</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Apoio com Inteligência Artificial</td>
                <td>❌</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Ajuda à continuidade da plataforma</td>
                <td>❌</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Acesso ilimitado ao conteúdo Prova de Conhecimentos</td>
                <td>❌</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Acesso ao conteúdo Prova de Aptidão Física</td>
                <td>❌</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Acesso ao conteúdo Testes psicotécnicos</td>
                <td>❌</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Acesso à funcionalidade "Gerar Teste em Papel"</td>
                <td>❌</td>
                <td>✔️</td>
              </tr>
              <tr>
                <td>Suporte dedicado</td>
                <td>❌</td>
                <td>✔️</td>
              </tr>
            </tbody>
          </FeatureTable>

          <Typography variant="h6" gutterBottom style={{ marginTop: '30px' }}>
            Selecione o método de pagamento
          </Typography>

          {/* Métodos de Pagamento */}
          <PaymentMethodsWrapper>
            {/* MB WAY */}
            <div>
              <MbwayButton
                variant="contained"
                onClick={() => setMbwayExpanded(!mbwayExpanded)}
              />
              <MbwayCollapse in={mbwayExpanded}>
                <TextField
                  label="Número de Telefone"
                  variant="outlined"
                  fullWidth
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Insira o número de telefone"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleMbwayPayment}
                  disabled={loading}
                  fullWidth
                >
                  {loading ? 'Processando...' : 'Confirmar Pagamento'}
                </Button>
              </MbwayCollapse>
            </div>

            {/* PayPal */}
            <PayPalButtonWrapper>
              <PayPalScriptProvider
                options={{ 
                  "client-id": "AeIo__Xzy2mK7oJ4bCNfF4xHXYIxzSrE5xkZGiii0PXv0UnOiuw0_m7zUP8EZhRVKpxC8VnTjOkaZq3T", 
                  currency: "EUR" 
                }}
              >
                <PayPalButtons
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [{
                        amount: { value: "3.99" },
                      }],
                    });
                  }}
                  onApprove={handlePayPalApprove}
                  onError={(err) => {
                    console.error("Erro no pagamento:", err);
                    alert("Ocorreu um erro ao processar seu pagamento. Por favor, tente novamente.");
                  }}
                  style={{ layout: 'vertical', color: 'blue', shape: 'pill', label: 'paypal' }}
                />
              </PayPalScriptProvider>
            </PayPalButtonWrapper>
          </PaymentMethodsWrapper>
          <br/>
          <br/>
          <WhatsAppButton
            variant="contained"
            startIcon={<WhatsAppIcon />}
            href="https://wa.me/351913450846"
            target="_blank"
          >
            Suporte
          </WhatsAppButton>
          {/* Botão de Cancelar */}
          <Button 
            onClick={() => navigate('/')} 
            style={{ marginTop: '40px', borderRadius: '6px' }}
            variant="outlined"
          >
            Cancelar
          </Button>
        </StyledContainer>
      </PurchaseWrapper>
    </>
  );
};

export default Purchase;
